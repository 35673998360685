/* eslint-disable @typescript-eslint/no-explicit-any */
import { Content, ContentHref, ContentWith$$Relations } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import {
  selectDocumentRootType,
  selectGenericDocumentRootConfig,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import {
  selectIsDocumentAndExternalResourcesLoaded,
  selectShouldUpdateDocumentViewmodalBeCalled,
} from '@newStore/refactor/refactorSelectors';
import { selectIsValidationFinishedLoading } from '@newStore/validation/asyncValidationLoadDataSelectors';
import { loadAllValidation } from '@newStore/validation/validationState';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  calculateSuggestionsToReviewAction,
  calculateSuggestionstoSubmitAction,
  updateDocumentViewModelAction,
} from '@store/actions/documentActions';
import { addNotificationAction } from '@store/actions/notificationActions';
import { patchNewsletterSettingsApprovalDateCmd } from '@store/commands/documentCommands';
import { getPossibleDuplicateWebConfigs } from '@store/commands/websitesCommands';
import {
  DIRTY_NODE,
  DOCUMENT_SAVED,
  INIT_DOCUMENT,
  LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS,
  PATCH_NODE,
  SAVE_DOCUMENT,
  SET_EXPANDED_PROPOSALS_CREATORS,
  TRIGGER_PRELOAD_ACTIONS,
  UPDATE_DOCUMENT_VIEW_MODEL,
} from '@store/constants/actionTypes';
import { linkTeaserToNewsItem } from '@store/helpers/documentHelpers';
import { dispatchWhenSelectorChanges, waitFor } from '@store/helpers/sagaUtils';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { getNodeTypeConfig } from '../../config/nodeTypeConfigurations';
import { Mode, setSelectedDocument } from '../documentUI/documentUIState';
import {
  getAllByRoot,
  getAllWebConfigurationsForRoot,
  getDocumentByRoot,
  getNewsletterSettings,
  getProposalsByRoot,
  getWebPagesForContent,
} from './documentApiDataAccess';
import { extractContentAndRelations, getNotFoundHrefs } from './documentApiHelpers';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesOldSlice,
  selectAreContentAndProposalsLoaded,
  selectIncludedNodesThatNeedWebConfigs,
  selectInternalContentToLoad,
} from './documentApiSelectors';
import {
  addExtraInternalData,
  addInternalNotFoundHrefs,
  addInternalResourcesToLoad,
  contentAndRelationsFetched,
  createNewsitemFromTeaser,
  createTeaserFromNewsitem,
  includedWebPagesFetched,
  loadIncludedWebPages,
  loadNewsletterSettings,
  loadWebConfigurations,
  newsletterSettingsFetched,
  proposalsFetched,
  refetchDocument,
  updateLastReadNewsletter,
  webConfigurationsFetched,
} from './documentApiState';
import { LoadIncludedWebPagesAction } from './documentApiTypes';

function* fetchContentAndRelations() {
  const currentDocumentHref: string = yield select((state) => state.documentUI.currentDocument);
  const documentKey = currentDocumentHref.split('/').pop();
  if (documentKey) {
    try {
      const contentApiResults = yield call(getDocumentByRoot, documentKey);
      const { content, relations } = extractContentAndRelations(contentApiResults);
      yield put(contentAndRelationsFetched({ content, relations }));
    } catch (error) {
      console.error('error when fetching content and relations', error);
      yield put(
        addNotificationAction({
          type: 'ERROR',
          message:
            'Er is een onverwachte fout opgetreden bij het ophalen van de content voor dit document.',
        })
      );
    }
  }
}

function* fetchProposals() {
  const currentDocumentHref: string = yield select((state) => state.documentUI.currentDocument);
  const documentKey = currentDocumentHref.split('/').pop();
  if (documentKey) {
    try {
      const proposals = yield call(getProposalsByRoot, documentKey);
      yield put(proposalsFetched(proposals));
    } catch (error) {
      console.error('error when fetching proposals', error);
      yield put(
        addNotificationAction({
          type: 'ERROR',
          message: 'Er is een onverwachte fout opgetreden bij het ophalen van de proposals.',
        })
      );
    }
  }
}

function* fetchWebConfigurations() {
  try {
    // external data slice will make sure that websites and templates are only fetched once with refresh=false
    yield put(getAllOfResource({ resource: pathMap.webTemplates, refresh: false }));
    yield put(getAllOfResource({ resource: pathMap.webSites, refresh: false }));
    const currentDocumentHref = yield select((state) => state.documentUI.currentDocument);
    const webConfigurations = yield call(getAllWebConfigurationsForRoot, currentDocumentHref);
    yield put(webConfigurationsFetched(webConfigurations));
  } catch (error) {
    console.error('error when fetching webConfigurations', error);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'Er is een onverwachte fout opgetreden bij het ophalen van de web configuraties.',
      })
    );
  }
}

function* fetchNewsletterSettings() {
  try {
    const currentDocumentHref: string = yield select((state) => state.documentUI.currentDocument);
    const newsletterSettings = yield call(getNewsletterSettings, currentDocumentHref);
    yield put(newsletterSettingsFetched({ newsletterSettings }));
  } catch (error) {
    console.error('error when fetching newsletter settings', error);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message:
          'Er is een onverwachte fout opgetreden bij het ophalen van de newsletter settings.',
      })
    );
  }
}

function* loadDocumentSpecificData() {
  yield call(waitFor, selectAreContentAndProposalsLoaded);
  const rootTypeConfig: ReturnType<typeof selectGenericDocumentRootConfig> = yield select(
    selectGenericDocumentRootConfig
  );

  if (!rootTypeConfig) {
    console.warn('[document api]: unknown document type for root');
  } else if (rootTypeConfig.onLoadActions) {
    yield all(rootTypeConfig.onLoadActions.map((action) => put(action)));
  }
}

function* onDocumentSaved() {
  yield put(refetchDocument());
  const rootType: NodeType = yield select(selectDocumentRootType);
  if (rootType === NodeType.PRONEWSLETTER) {
    yield put(updateLastReadNewsletter());
  }
}

function* checkForInternalDataToLoadSaga() {
  const resourcesToLoad: string[] = yield select(selectInternalContentToLoad);
  if (resourcesToLoad.length > 0) {
    yield put(addInternalResourcesToLoad({ resources: resourcesToLoad }));
  }

  const contentThatNeedsAWebpage: ContentHref[] = yield select(
    selectIncludedNodesThatNeedWebConfigs
  );
  if (contentThatNeedsAWebpage.length > 0) {
    yield put(loadIncludedWebPages({ hrefs: contentThatNeedsAWebpage }));
  }
}

function* loadIncludedWebPagesSaga({ payload }: LoadIncludedWebPagesAction) {
  try {
    const { hrefs } = payload;
    const webConfigurations = yield call(getWebPagesForContent, hrefs);
    yield put(includedWebPagesFetched({ hrefs, results: webConfigurations }));
  } catch (error) {
    console.error('error when fetching webConfigurations', error);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'Er is een onverwachte fout opgetreden bij het ophalen van de web configuraties.',
      })
    );
  }
}

function* loadIncludedResourcesSaga({ payload }: PayloadAction<{ resources: string[] }>) {
  const { resources } = payload;

  const results: ContentWith$$Relations[] = yield call(getAllByRoot, resources);

  const { content, relations } = extractContentAndRelations(results);

  if (results.length > 0) {
    yield put(addExtraInternalData({ content, relations }));
  }

  const missingResults = getNotFoundHrefs(results, resources);
  if (missingResults.length > 0) {
    yield put(addInternalNotFoundHrefs({ hrefs: missingResults }));
  }
}

function* onProposalsFetched() {
  // temporary function for all the old actions that need to be dispatched when content, relations and proposals are fetched
  try {
    yield call(waitFor, selectAreContentAndProposalsLoaded);
    yield take(UPDATE_DOCUMENT_VIEW_MODEL);
    // we have a race condition on refresh, where we call the suggestion mode before the proposals are done in the old reducer.
    // this results in errors finding the expanded creators, which will soon be irrelevant.
    yield race([
      take(SET_EXPANDED_PROPOSALS_CREATORS),
      delay(500), // wait for 1 second
    ]);

    const proposals = yield select((state: RootState) => state.documentApi.proposals);
    if (Object.keys(proposals).length > 0) {
      // when we have proposals, update the VM with forceHashUpdateToAll
      yield put(updateDocumentViewModelAction(true));
    }

    const mode: Mode | null = yield select((state: RootState) => state.documentUI.mode);
    if (mode === 'SUGGEST') {
      yield put(calculateSuggestionstoSubmitAction());
    } else if (mode === 'REVIEW') {
      yield put(calculateSuggestionsToReviewAction());
    }

    // moved from SET_DOCUMENT_NODES
    const rootDocumentConfig = yield select(selectGenericDocumentRootConfig);
    if (rootDocumentConfig && rootDocumentConfig.preloadActions) {
      yield put({
        type: TRIGGER_PRELOAD_ACTIONS,
        payload: { preloadActions: rootDocumentConfig.preloadActions },
      });
    }
  } catch (err) {
    console.error('error while firing old document reducer actions from saga', err);
  }
}

function* saveNewsletterSettings() {
  try {
    const newsletterSettings = yield select((state) => state.documentApi.newsletterSettings);
    yield call(patchNewsletterSettingsApprovalDateCmd, newsletterSettings);
    yield put({ type: UPDATE_DOCUMENT_VIEW_MODEL });
    yield put(addNotificationAction({ type: 'SUCCESS', message: 'lastRead.markUpdated' }));
  } catch (error) {
    console.error('error when saving newsletter settings', error);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message:
          'Er is een onverwachte fout opgetreden bij het opslaan van de newsletter settings.',
      })
    );
  }
}
// function to test that the updateVM function also runs the redux loop commands afterwards.
// export function* updateVmTestSaga() {
//   while (true) {
//     yield delay(2000);
//     yield put({ type: UPDATE_DOCUMENT_VIEW_MODEL });
//   }
// }

/**
 * in this function we are replacing the loop actions of SAVE_DOCUMENT and LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS
 * we do this because we want to also run the async validation at the same time, so we can validate both.
 *
 */
function* doAsyncSaveDocumentPreCheckThings() {
  try {
    yield put(loadAllValidation());

    const webpages = yield select((state) => [
      ...selectApiWithPendingChangesOldSlice(state).webpages.values(),
    ]);

    const possibleDuplicateWebConfigs = yield getPossibleDuplicateWebConfigs(webpages);

    yield call(waitFor, selectIsValidationFinishedLoading);

    yield put({
      type: LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS,
      payload: { possibleDuplicateWebConfigs },
    });
  } catch (e) {
    console.log(e);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message:
          'Er is een onverwachte fout opgetreden bij het ophalen van data ter validatie van het document.',
      })
    );
  }
}

function* onPatchNodeSaga() {
  yield call(waitFor, selectIsDocumentAndExternalResourcesLoaded);
  yield put(updateDocumentViewModelAction(true));
}

function* createTeaserFromNewsitemSaga({ payload }: PayloadAction<{ newsItemHref: string }>) {
  const { newsItemHref } = payload;
  try {
    const newsItem: Content = yield select(
      (state) => selectApiWithPendingChanges(state).content[newsItemHref]
    );
    const teaserTypeConfig = getNodeTypeConfig(NodeType.TEASER);
    const teaserKey = uuidv4();
    const command = linkTeaserToNewsItem(
      teaserKey,
      teaserKey,
      newsItem.key,
      newsItem.title,
      newsItem.creators,
      newsItem.attachments,
      teaserTypeConfig
    );
    yield call(command.name, ...command.args);
    yield getAngularService('$state').go('edit', { key: teaserKey });
  } catch (e) {
    console.log('ERROR creating content:', e);
  }
}

function* createNewsitemFromTeaserSaga({ payload }: PayloadAction<{ teaserHref: string }>) {
  const { teaserHref } = payload;
  try {
    const teaser: Content = yield select(
      (state) => selectApiWithPendingChanges(state).content[teaserHref]
    );
    const proNewsItemTypeConfig = getNodeTypeConfig(NodeType.PRONEWSITEM);
    const newsitemKey = uuidv4();
    const command = linkTeaserToNewsItem(
      newsitemKey,
      teaser.key,
      newsitemKey,
      teaser.title,
      teaser.creators,
      teaser.attachments,
      proNewsItemTypeConfig
    );
    yield call(command.name, ...command.args);
    yield getAngularService('$state').go('edit', { key: newsitemKey });
  } catch (e) {
    console.log('ERROR creating content:', e);
  }
}

function* listenToSelectorChanges() {
  // wait for data to be loaded before we start listening to selector changes
  yield call(waitFor, selectIsDocumentAndExternalResourcesLoaded);
  yield put(updateDocumentViewModelAction(true)); // force the update once all our data is loaded. needed on save to refresh the UI.
  yield dispatchWhenSelectorChanges(
    selectShouldUpdateDocumentViewmodalBeCalled,
    updateDocumentViewModelAction(false)
  );
}

export function* watchSelectorChanges() {
  // start the saga when the INIT_DOCUMENT action is dispatched
  yield takeLatest([INIT_DOCUMENT, DOCUMENT_SAVED], listenToSelectorChanges);
}

export function* documentApiSaga() {
  yield takeEvery(DOCUMENT_SAVED, onDocumentSaved);
  yield takeLatest([PATCH_NODE, DIRTY_NODE], onPatchNodeSaga);
  // yield takeEvery(refetchDocument, onDocumentRefetched);
  yield takeLatest([setSelectedDocument.match, refetchDocument.match], fetchContentAndRelations);
  yield takeLatest([setSelectedDocument.match, refetchDocument.match], fetchProposals);
  yield takeEvery(proposalsFetched.match, onProposalsFetched);
  yield takeLatest(contentAndRelationsFetched.match, loadDocumentSpecificData);
  yield takeLatest(loadWebConfigurations.match, fetchWebConfigurations);
  yield takeLatest(loadNewsletterSettings.match, fetchNewsletterSettings);
  yield throttle(100, '*', checkForInternalDataToLoadSaga);
  yield takeEvery(addInternalResourcesToLoad.match, loadIncludedResourcesSaga);
  yield takeLatest(updateLastReadNewsletter.match, saveNewsletterSettings);
  yield takeEvery(SAVE_DOCUMENT, doAsyncSaveDocumentPreCheckThings);
  yield takeEvery(createTeaserFromNewsitem.match, createTeaserFromNewsitemSaga);
  yield takeEvery(createNewsitemFromTeaser.match, createNewsitemFromTeaserSaga);
  yield takeEvery(loadIncludedWebPages.match, loadIncludedWebPagesSaga);
}
