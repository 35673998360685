import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { llinkidDefaultBuildingBlocks } from './llinkidGenericConfigProps';

const LLINKID_BASIC_MATERIAL_REQUIREMENTS: BuildingBlockNodeConfig = {
  information: {
    single: 'Basisuitrusting',
    plural: 'Basisuitrusting',
  },
  node: {
    type: ContentType.LLINKID_BASIC_MATERIAL_REQUIREMENTS,
  },
  buildingBlocks: llinkidDefaultBuildingBlocks,
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,
  createInModal: false,
  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_BASIC_MATERIAL_REQUIREMENTS;
