import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';
import { identifierComponent } from '../helpers/genericComponents';

const LEGAL: BuildingBlockNodeConfig = {
  information: {
    single: 'Wettekst',
    plural: 'Wettekst',
    ribonIcon: require('../../../../img/buildingBlocks/LEGAL.svg'),
  },
  node: {
    type: ContentType.LEGAL,
  },
  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 3,
    },
    {
      component: 'title',
      label: 'Titel',
      width: 9,
    },
    {
      component: 'attachedContent',
      property: '$$html',
      label: ' ',
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default LEGAL;
