import { BuildingBlockNodeConfig, RequiredType, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const THEME: BuildingBlockNodeConfig = {
  information: {
    single: 'Item',
    plural: 'Items',
  },
  node: {
    type: ContentType.THEME,
  },
  buildingBlocks: [
    { type: NodeType.THEME },
    { type: NodeType.CURRICULUM_CLUSTER },
    { type: NodeType.CURRICULUM_THEME },
  ],
  edit: [
    {
      component: 'title',
      label: 'Titel',
      width: '8',
      required: RequiredType.ERROR,
    },
    {
      component: 'color',
      label: 'Kleur',
      width: '4',
    },
    {
      component: 'image',
      width: '6',
      label: 'Icon',
      options: {
        type: 'ICON',
      },
    },
    {
      component: 'subjects',
      label: 'Aliases',
      options: {
        rawLabel: true,
      },
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  isCollapsible: true,
  isCollapsedByDefault: false,
};

export default THEME;
