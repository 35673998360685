import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { getOdetIdentifier, getSelectedAccessRights } from '../viewmodels/viewModelHelpers';

const commonUtils = require('@kathondvla/sri-client/common-utils');

export const getNodeStateFromTypeRelations = (nodeKey, state) => {
  const stateFromTypeRelations = state.apiWithPendingChanges.contentRelations.from;
  const nodeFromTypeRelations = stateFromTypeRelations[`/content/${nodeKey}`];
  const versionOfTypeRelations =
    nodeFromTypeRelations &&
    nodeFromTypeRelations.filter((r) => r.relationtype === 'IS_VERSION_OF');

  return versionOfTypeRelations;
};

export const get$$prefixForDocumentVmNode = (node) => {
  function findParentWithPrefix(childNode) {
    if (childNode.$$parent) {
      const parentPrefix = get$$prefixForDocumentVmNode(childNode.$$parent);
      if (parentPrefix) {
        return parentPrefix;
      }
      return findParentWithPrefix(childNode.$$parent);
    }
    return '';
  }

  const identifier =
    node.identifiers && node.identifiers.length > 0 ? node.identifiers.join(' ') : '';
  if (node.$$typeConfig.documentViewIdentifier === 'i') {
    return `${identifier}`;
  }
  if (node.$$typeConfig.documentViewIdentifier === 'pi' && node.$$parent) {
    const parentPrefix = get$$prefixForDocumentVmNode(node.$$parent);
    return parentPrefix && identifier ? parentPrefix + identifier : identifier;
  }
  if (node.$$typeConfig.documentViewIdentifier === 'p i' && node.$$parent) {
    const parentPrefix = get$$prefixForDocumentVmNode(node.$$parent);
    return parentPrefix && identifier ? `${parentPrefix} ${identifier}` : identifier;
  }
  if (node.$$typeConfig.documentViewIdentifier === 'p.i') {
    const parentPrefix = get$$prefixForDocumentVmNode(node.$$parent);
    return parentPrefix && identifier ? `${parentPrefix}.${identifier}` : identifier;
  }
  if (node.$$typeConfig.documentViewIdentifier === '(p)i') {
    return findParentWithPrefix(node) + identifier;
  }
  if (node.$$typeConfig.documentViewIdentifier === '(p).i') {
    return `${findParentWithPrefix(node)}.${identifier}`;
  }
  if (node.$$typeConfig.documentViewIdentifier === '(p).i.') {
    return `${findParentWithPrefix(node)}.${identifier}.`;
  }
  if (node.$$typeConfig.documentViewIdentifier === '(p)i.i') {
    if (node.$$parent && node.$$parent.$$type === node.$$type) {
      const parentPrefix = get$$prefixForDocumentVmNode(node.$$parent);
      return `${parentPrefix}.${identifier}`;
    }
    return findParentWithPrefix(node) + identifier;
  }
  if (node.$$typeConfig.documentViewIdentifier === 'o(p*)(.)i') {
    return getOdetIdentifier(node);
  }

  if (identifier) {
    return identifier;
  }
  return '';
};

export const getConcordantiesFromDocument = (documentToGetReferencesFrom, relations = []) => {
  const documentToGetReferencesFromEvaluated = typeof documentToGetReferencesFrom !== 'undefined';

  if (documentToGetReferencesFromEvaluated && documentToGetReferencesFrom) {
    return relations.map((relation) => {
      let expandedRelation;

      const relationToRootDocument =
        commonUtils.getKeyFromPermalink(relation.to.href) === documentToGetReferencesFrom.key;
      if (relationToRootDocument) {
        // when we open the aside panel on the root element the root element relation is not
        // inside the $$fulltree array, so we get the information for the ODET document itself
        expandedRelation = documentToGetReferencesFrom;
      } else {
        // If we are searching for "children relations" we search
        // for it in the ODET $$fulltree array
        expandedRelation = documentToGetReferencesFrom.$$fullTree.find((x) => {
          return commonUtils.getKeyFromPermalink(relation.to.href) === x.key;
        });
      }

      let concordantie = { key: relation.key };

      // when we don't have `expandedRelation` it means, with high
      // probability, that the user changed the "frame document" (ODET document)
      // having some relations in place for the old "frame document"
      // HINT: This will show a message in the template telling that information
      // to the user
      if (expandedRelation) {
        concordantie = {
          ...concordantie,
          title: expandedRelation.title,
          relationIdentifier: get$$prefixForDocumentVmNode(expandedRelation),
        };
      }
      return concordantie;
    });
  }
  return [];
};

export const getDocumentAndChildrenAccessRights = (documentSubState) => {
  // Here we get the permissions of the main node (general document),
  // the opened node and all the children
  const { viewModel } = documentSubState;
  if (!viewModel) {
    return [];
  }

  const permissions = [];
  const mainNode = viewModel.document;
  const asideNode = viewModel.aside && viewModel.aside.editDocument;

  if (asideNode) {
    permissions.push({
      key: asideNode.key,
      permissions: asideNode.selectedAccessRights,
    });
  }

  if (mainNode) {
    const mainNodePermissions = {
      key: mainNode.key,
      permissions: mainNode.selectedAccessRights,
    };

    permissions.push(mainNodePermissions);

    mainNode.attachmentResources.forEach((x) => {
      permissions.push({
        key: x.key,
        permissions: getSelectedAccessRights(x),
      });
    });
  }

  return permissions;
};

export const openGoalRelationsModal = async ({
  asideOptions,
  rootKey,
  relations,
  item,
  defaultCurriculumKey,
}) => {
  const modalWindowService = getAngularService('ModalWindowService');
  const relationOrigin = !asideOptions.revertedRelationDirection ? 'from' : 'to';
  const relationEnd = !asideOptions.revertedRelationDirection ? 'to' : 'from';

  const { modal, ...modalDataOptions } = asideOptions;

  const modalData = {
    component: modal,
    dataForModal: {
      editDocumentRootKey: rootKey,
      defaultCurriculumKey,
      selectedValues: relations.map((rel) => rel[relationEnd]?.$$expanded?.key),
      relationOrigin,
      relationEnd,
      edit: item ? { relation: item } : {},
      ...modalDataOptions,
    },
  };

  return modalWindowService.open(modalData);
};

export const pathToRootTemplate = (item) => {
  const rootTitle =
    item && item.$$pathToRoot && item.$$pathToRoot.length > 1
      ? `<span class="pathToRoot">(${item.$$pathToRoot.slice(1).join(' > ')})</span>`
      : '';
  return item ? `${item.title} ${rootTitle}` : '';
};
