import { ContentType } from '@generalTypes/apiTypes';
import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';

const PAGE_ATTACHMENTS_GROUP: HiddenContentItemConfig = {
  information: {
    single: 'Pagina-bijlagen',
    plural: 'Pagina-bijlagen',
    description:
      'These are downloads which can not be added as building blocks but managed from the aside. In Pro they will not be shown inline but at the bottom of the page in the "download section" of the page.',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  node: {
    type: ContentType.ATTACHMENTS_GROUP,
    tags: ['PAGE_ATTACHMENTS_GROUP'], // redactie uses this tag to distinguish them
    importance: 'HIGH', // pro matches on importance == high to distinguish them
  },
  // edit: [], // there is no real edit, this node is hidden in the document and is managed from the aside
  // createInModal: true, // not sure
  hideInDocument: true,
  hideChildrenInDocument: true,
};

export default PAGE_ATTACHMENTS_GROUP;
