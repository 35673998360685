import { RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import {
  publishComponent,
  titleComponent,
  identifierComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ODET: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Decretal leerplan',
    plural: 'Decretale leerplannen',
    icon: require('../../../../img/icons/odet.svg'),
    category: 'LEERPLAN',
  },
  node: {
    type: ContentType.CURRICULUM_ODET,
    tags: ['CURRICULUM'],
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ODET_LEVEL }],
  tocTypes: [
    NodeType.CURRICULUM_ODET_LEVEL,
    NodeType.CURRICULUM_ODET_LEERGEBIED,
    NodeType.CURRICULUM_ODET_KEY_COMPETENCE,
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
  ],
  isSearchable: true,
  allowSuggestions: false,
};

export default CURRICULUM_ODET;
