import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { llinkidDefaultBuildingBlocks } from './llinkidGenericConfigProps';

const LLINKID_CORRELATION_ENDTERMS: BuildingBlockNodeConfig = {
  information: {
    single: 'Concordantielijst',
    plural: 'Concordantielijst',
  },
  node: {
    type: ContentType.LLINKID_CORRELATION_ENDTERMS,
  },
  buildingBlocks: llinkidDefaultBuildingBlocks,
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,
  createInModal: false,
  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_CORRELATION_ENDTERMS;
