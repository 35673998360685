import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { WWW } from './WWW';
import { wwwDefaultBuildingBlocks } from './wwwGenericConfigProps';
// Extends WWW => a WWW with WebConfig WWW_HARD_CODED_PAGE becomes a WWW_STATIC_PAGE
export const WWW_STATIC_PAGE: ExtendedRootNodeConfig = {
  information: {
    description:
      'WWW Static Page (webTemplate code WWW_HARD_CODED_PAGE) can only be a root root (single page pro theme)',
    single: 'WWW: Statische pagina',
    plural: "Statische pagina's",
    icon: require('../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
  },
  extends: NodeType.WWW,
  webTemplateHref: '/web/templates/18fac7d8-a1c3-4a11-bfaa-125bbc44ed7a' as WebTemplateHref,
  buildingBlocks: wwwDefaultBuildingBlocks,
  tocTypes: [],
  edit: WWW.edit,
};
