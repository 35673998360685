import {
  loadPracticalExampleForZillIllustrations,
  loadZillGoalsForZillIllustrations,
} from '@newStore/zill/zillSagas';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const ZILL_ILLUSTRATION: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Zill-illustratie',
    plural: 'Zill-illustraties',
    icon: require('../../../../img/icons/zillIllustration.svg'),
    category: 'ZILL',
  },
  node: {
    type: ContentType.ZILL_ILLUSTRATION,
    tags: ['ZILL_ILLUSTRATION'],
  },
  onLoadActions: [loadZillGoalsForZillIllustrations(), loadPracticalExampleForZillIllustrations()],
  createModal: {
    authors: {
      addUser: true,
    },
  },
  tocTypes: [],
  buildingBlocks: [],
  edit: [
    titleComponent,
    authorsComponent,
    publishComponent,
    { component: 'practicalExampleReferences', label: 'Praktijkvoorbeeld' },
    { component: 'goalsByZillIllustration', label: 'Doel selecteren' },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
};

export default ZILL_ILLUSTRATION;
