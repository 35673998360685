import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideStaticHtml {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  blur(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(
        scope.ctrl.sDocument.key,
        { html: data.value },
        true,
        null,
        false
      )
    );
  }
}

export default {
  template: require('./asideStaticHtml.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideStaticHtml,
};
