import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const VIDEO_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Videogroep',
    plural: 'Videogroepen',
    ribonIcon: require('../../../../img/buildingBlocks/VIDEO_GROUP.svg'),
  },
  node: {
    type: ContentType.VIDEO_GROUP,
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'videoGroup',
      label: "Video's",
      hiddenChildren: {
        containerType: ContentType.VIDEO_GROUP,
        items: [
          {
            type: ContentType.VIDEO,
            label: 'video',
          },
        ],
      },
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCollapsible: false,
  hideChildrenInDocument: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default VIDEO_GROUP;
