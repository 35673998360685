import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent, identifierComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ODET_LEVEL: BuildingBlockNodeConfig = {
  information: {
    single: 'Soort',
    plural: 'Soorten',
  },
  node: {
    type: ContentType.CURRICULUM_ODET_LEVEL,
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ODET_KEY_COMPETENCE }],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_LEVEL;
