import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const FAQ_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Faq-groep',
    plural: 'Faq-groepen',
    ribonIcon: require('../../../../img/buildingBlocks/FAQ_GROUP.svg'),
  },
  node: {
    type: ContentType.FAQ_GROUP,
  },
  buildingBlocks: [{ type: NodeType.FAQ }],
  edit: [
    titleComponent,
    {
      component: 'themes',
      label: 'Categorie FAQ-groep',
      options: {
        referenceFrameKey: constants.faqCategoriesKey,
        types: [ContentType.THEME],
      },
      valueToString: themeHrefsToString,
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default FAQ_GROUP;
