import { SriContentList } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { getGenericErrorMessage } from '@newStore/genericHelpers';
import { selectHasUserReviewAbility, selectUser } from '@newStore/user/userSelectors';
import nodeTypeSelectorsMap from '@nodeTypeConfig/nodeTypeSelectorsMap';
import { addNotificationAction } from '@store/actions/notificationActions';
import { loadHrefsCmd } from '@store/commands/documentCommands';
import {
  createContentCmd,
  createContentWithAttachmentCmd,
  createContentWithDocxCmd,
  createNewsLetterCmd,
  deleteDocumentsCmd,
  loadMoreCmd,
  loadProposalsToReviewCmd,
  searchCmd,
  validateCmd,
} from '@store/commands/documentListCommands';
import { getVersionErrorMsg } from '@store/helpers/copyHelpers';
import { getWebConfigurationBatch } from '@store/helpers/documentHelpers';
import { waitFor } from '@store/helpers/sagaUtils';
import { createQuery } from '@store/helpers/searchHelpers';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { ApiError, DisplayError } from '@newStore/documentApi/documentApiTypes';
import {
  getGenericNodeType,
  getNodeTypeConfig,
  nodeTypeConfigurations,
} from '../../config/nodeTypeConfigurations';
import { setExternalData } from '../externalData/externalDataState';
import {
  copyContent,
  newVersion,
  sendPublishContent,
  updateNewsHash,
} from './documentListDataAccess';
import {
  AuthorHrefsToLoad,
  selectAuthorHrefsToLoad,
  selectSelectedResults,
  selectUserVmForDocumentList,
} from './newDocumentListSelectors';
import {
  closePublishModal,
  copyOfContentFinished,
  createContent,
  createContentFinished,
  createCopy,
  createNewVersion,
  deleteSelectedFinished,
  loadMore,
  newVersionOfContentFinished,
  publishContent,
  refreshList,
  removeSelectedFromResults,
  search,
  setFilterProperty,
  setProposalsToReview,
  setSearchResults,
  showValidationErrors,
  sortByColumn,
  updateNews,
  validateAndDeleteSelected,
  validateCreateContent,
} from './newDocumentListState';

// TODO: add error handling: show a notification message (bottom left) with the error
function* documentListSearch() {
  try {
    yield call(
      waitFor,
      (state: RootState) => selectUserVmForDocumentList(state).searchables.length > 0
    );
    const searchParams = yield select((state: RootState) => ({
      ...state.newDocumentList.searchParams,
      searchables: selectUserVmForDocumentList(state).searchables,
    }));
    const query = createQuery(searchParams, nodeTypeConfigurations);
    const pageResults: SriContentList = yield call(searchCmd, query);

    yield put(setSearchResults({ pageResults }));
  } catch (ex) {
    console.error(ex);
    // yield put(initFailed(ex.message));
  }
}

function* getAuthorsSaga() {
  try {
    const creatorsHrefs: AuthorHrefsToLoad = yield select(selectAuthorHrefsToLoad);
    if (creatorsHrefs.length === 0) {
      return;
    }
    const authorsResponse = yield call(loadHrefsCmd, creatorsHrefs);
    // yield put(setAuthors({ authors: authorsResponse.results }));
    yield put(setExternalData({ data: authorsResponse.results }));
  } catch (ex) {
    console.error(ex);
  }
}

function* getProposalsForListSaga({ payload }) {
  try {
    const { pageResults } = payload;
    const allowedToReview = yield select(selectHasUserReviewAbility);

    if (!allowedToReview) {
      return;
    }

    const hrefsToLoadProposals = pageResults
      .filter((r) => {
        const typeConfig = getNodeTypeConfig(getGenericNodeType(r)) as RootNodeConfig;
        return typeConfig.allowSuggestions;
      })
      .map((r) => r.$$meta.permalink);

    const proposalsToReview: { contentHref: string; count: number }[] = yield call(
      loadProposalsToReviewCmd,
      hrefsToLoadProposals
    );
    yield put(
      setProposalsToReview({
        proposalsToReview,
      })
    );
  } catch (ex) {
    console.error(ex);
  }
}

function* loadMoreSaga({ payload }) {
  try {
    const { href } = payload;
    const pageResults = yield call(loadMoreCmd, href);
    yield put(setSearchResults({ pageResults }));
  } catch (ex) {
    console.error(ex);
  }
}

function* notifyErrors(errors: DisplayError[]) {
  for (const err of errors) {
    yield put(
      addNotificationAction({
        type: 'ERROR',
        removeAfter: 10,
        message: err.code,
        params: err.params,
      })
    );
  }
}

function* notifyApiErrors(apiError: ApiError) {
  const errors = apiError?.body?.errors || [getGenericErrorMessage(apiError)];
  yield notifyErrors(errors);
}

function* showValidationErrorsSaga({ payload }) {
  const errors = Array.isArray(payload) ? payload : [payload];
  yield notifyErrors(errors);
}

function* createCopySaga({ payload }) {
  try {
    yield copyContent(payload.map((item) => item.key));
    yield put(refreshList());
  } catch (apiError) {
    yield notifyApiErrors(getVersionErrorMsg(apiError, payload));
  } finally {
    yield put(copyOfContentFinished());
  }
}

function* createNewVersionSaga({ payload }) {
  try {
    yield newVersion(payload.map((item) => item.key));
    yield put(refreshList());
  } catch (apiError) {
    yield notifyApiErrors(getVersionErrorMsg(apiError, payload));
  } finally {
    yield put(newVersionOfContentFinished());
  }
}

function* validateAndDeleteSelectedSaga() {
  let hasErrors = false;
  const selectedResults = yield select((state: RootState) => selectSelectedResults(state));

  for (const selected of selectedResults) {
    const nodeType = getGenericNodeType(selected);
    const selectorsForNodeType = nodeTypeSelectorsMap[nodeType];
    if (selectorsForNodeType.deleteFromListValidations) {
      for (const validation of selectorsForNodeType.deleteFromListValidations) {
        try {
          yield validation(selected.key);
        } catch (error) {
          hasErrors = true;
          yield showValidationErrors(error);
        }
      }
    }
  }
  if (!hasErrors) {
    try {
      yield call(
        deleteDocumentsCmd,
        selectedResults.map((s) => s.key)
      );
      yield put(removeSelectedFromResults());
    } catch (error) {
      yield notifyApiErrors(error);
    }
  }
  yield put(deleteSelectedFinished());
}

function* updateNewsSaga() {
  try {
    yield updateNewsHash();
    yield put(addNotificationAction({ type: 'SUCCESS', message: 'updateHashSuccessMessage' }));
  } catch (error) {
    yield notifyApiErrors(error);
  }
}

function* validateCreateContentSaga({ payload }) {
  const validations = payload.createValidations || [];
  const validationCmds = validations.map((validation) => ({
    cmd: validation.cmd,
    documentKey: payload.key,
    params: [payload.title, validation.failAction],
  }));
  try {
    yield call(validateCmd, validationCmds);
    yield put(createContent(payload));
  } catch (error) {
    yield put(showValidationErrors(error));
  }
}

function* createContentSaga({ payload }) {
  const apiPending = yield select((state: RootState) => state.newDocumentList.apiPending);
  const { key } = apiPending[0].body;
  // create default webconfiguration for the root node if defined
  const webConfigurationBatch = getWebConfigurationBatch(
    payload.webconfiguration,
    key,
    payload.title
  );
  try {
    if (payload.importDocx && payload.importDocx.name) {
      yield call(createContentWithDocxCmd, apiPending, payload.importDocx, webConfigurationBatch);
    } else if (payload.uploadAttachment) {
      yield call(createContentWithAttachmentCmd, apiPending, payload.uploadAttachment);
    } else if (payload.newsLetterTemplate) {
      yield call(createNewsLetterCmd, apiPending, {
        href: `/content/${key}`,
        templateKey: payload.newsLetterTemplate.key,
        dateToSend: payload.dateToSend,
      });
    } else {
      yield call(createContentCmd, apiPending, webConfigurationBatch);
    }
    yield put(createContentFinished({ key }));
  } catch (error) {
    console.log('ERROR creating content:', error);
  }
}

function* createContentFinishedSaga({ payload }) {
  yield getAngularService('$state').go('edit', { key: payload.key });
}

function* publishContentSaga({ payload }) {
  const { resources, publishDate, message, locationUrl } = payload;
  const creator = yield select(selectUser);
  try {
    yield call(sendPublishContent, resources, publishDate, message, locationUrl, creator);
    yield put(addNotificationAction({ type: 'SUCCESS', message: 'saveSuccessMessage' }));
    yield put(refreshList());
  } catch (error) {
    yield notifyApiErrors(error);
  }
  yield put(closePublishModal());
}

export function* watchNewDocumentListSaga() {
  yield takeLatest([search, sortByColumn, setFilterProperty, refreshList], documentListSearch);
  yield takeLatest(setSearchResults, getAuthorsSaga);
  yield takeLatest(setSearchResults, getProposalsForListSaga);
  yield takeLatest(loadMore, loadMoreSaga);
  yield takeLatest(createCopy, createCopySaga);
  yield takeLatest(createNewVersion, createNewVersionSaga);
  yield takeLatest(validateAndDeleteSelected, validateAndDeleteSelectedSaga);
  yield takeLatest(updateNews, updateNewsSaga);
  yield takeLatest(validateCreateContent, validateCreateContentSaga);
  yield takeLatest(createContent, createContentSaga);
  yield takeLatest(createContentFinished, createContentFinishedSaga);
  yield takeLatest(publishContent, publishContentSaga);
  yield takeLatest(showValidationErrors, showValidationErrorsSaga);
}

// export function* watchAndLog() {
//   while (true) {
//     const action = yield take('*');
//     console.log('saga action', action);
//   }
// }
