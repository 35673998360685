import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { publishComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { proAliasesComponent } from '../proGenericConfigProps';
import { extendsSection } from '../../helpers/genericConfigHelpers';
// Extends SECTION => A SECTION inside a Pro Theme and WebConfig FALLBACK_MENU_LEVEL_2 becomes a PRO_MENU_LEVEL_2
export const PRO_MENU_LEVEL_2: ExtendedBuildingBlockNodeConfig = {
  information: {
    description: 'Pro Channel: 2nd level in the navigation/menu structure of the Pro Home Page',
    single: 'Kanaal',
    plural: 'Kanalen',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  webTemplateHref: '/web/templates/974f629a-7905-4d56-af5f-31a4f003d6f5' as WebTemplateHref,
  isCollapsedByDefault: true,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    publishComponent,
    { component: 'color', label: 'Kleur' },
    {
      component: 'image',
      property: 'attachments',
      label: 'Ikoon',
      width: '6',
      options: { type: 'ICON', fields: [] },
    },
    proAliasesComponent,
    {
      component: 'themes',
      label: "Thema's",
      valueToString: themeHrefsToString,
    },
  ],
};
