import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { titleComponent } from '../../helpers/genericComponents';
// Extends SECTION => A SECTION inside the PRO_HOME_AGE becomes a PRO_MENU_SECTION
const PRO_MENU_SECTION: ExtendedBuildingBlockNodeConfig = {
  information: {
    single: 'Menu Item',
    plural: 'Menu Items',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  documentTypes: [NodeType.PRO_HOME_PAGE],
  isCollapsedByDefault: false,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
    {
      component: 'menuItemReference',
      label: 'Navigeert naar',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [NodeType.WEBPAGE2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
    },
  ],
};

export default PRO_MENU_SECTION;
