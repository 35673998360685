import { Content, ContentHref } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectPathToRoot,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
import { accessRights } from '@store/helpers/accessRights';
import { createError, getNodeTypeLabel } from '../validationHelpers';
import { ValidationResult } from '../validationTypes';

const getAccessRightsForAttachmentsGroup = (pathToRoot: Array<Content>): string[] => {
  const ancestorThatDefinesValue = pathToRoot.find(
    (ancestor) => ancestor.descendantsAccessRights?.length
  );
  return ancestorThatDefinesValue && ancestorThatDefinesValue.descendantsAccessRights
    ? ancestorThatDefinesValue.descendantsAccessRights
    : [];
};

const isThisNodeOnlyAccessibleForBestuurders = (pathToRoot: Array<Content>): boolean => {
  const accessRightsForThisNode = getAccessRightsForAttachmentsGroup(pathToRoot);
  return accessRightsForThisNode.includes(accessRights.informatie_voor_bestuurders.namedSetHref);
};

/**
 * Information given by Bianca (untranslated):
 * Informatie voor besturen is een speciaal geval :  zelfs medewerkers mogen dat niet zien, enkel ict / webmasters en een handvol mensen,
 * daarom is het niet aangewezen om die aan te maken als gedeeld document omdat iedereen die in redactie kan,
 * dat dan kan zien en daarom is er die boodschap dat 'informatie voor besturen' geen gedeelde documenten mogen bevatten.
 */
export const selectGlobalDocumentSecurityInconsistentError = createTypedSelector(
  [
    (state, href: ContentHref) => selectPathToRoot(state, href),
    (state, href: ContentHref) => selectApiWithPendingChangesRelationsToAndFromMap(state).to[href],
    (state) => selectApiWithPendingChanges(state).content,
  ],
  (pathToRoot, relationsTo, contentMap): ValidationResult => {
    if (!isThisNodeOnlyAccessibleForBestuurders(pathToRoot)) {
      return true;
    }
    const attachments = relationsTo
      .filter(parentChildRelationFilter)
      .map((rel) => contentMap[rel.from.href]);

    if (attachments.some((att) => att.type === 'UNSTRUCTURED_DOCUMENT')) {
      return createError(
        'globalDocumentNotAllowedInAttachmentsGroupSecuredOnlyForBestuurders',
        'selectInvalidReferenceError',
        `Een ${getNodeTypeLabel(
          'bijlagegroep'
        )} die is afgeschermd als "Informatie voor besturen" mag <strong>geen gedeelde documenten</strong> bevatten.`,
        'attachments'
      );
    }
    return true;
  }
);
export const validateGlobalDocumentAllowedInAttachmentsGroup = {
  rule: selectGlobalDocumentSecurityInconsistentError,
};
