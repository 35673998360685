import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ZILL_CONTENT_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Inhouds groep',
    plural: 'Inhouds groepen',
  },
  node: {
    type: ContentType.CURRICULUM_ZILL_CONTENT_GROUP,
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT }],
  edit: [],
  showPlaceholder: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default CURRICULUM_ZILL_CONTENT_GROUP;
