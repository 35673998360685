import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { mapRelationsToRelationsToAndFrom, createTypedSelector } from '@newStore/genericHelpers';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { Content, ContentHref, ContentRelation } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';

export const selectIsReferenceFrameLoaded = (state: RootState, referenceFrame: ContentHref) => {
  return Boolean(state.referenceFrames[referenceFrame]?.isLoaded);
};

export const selectProReferenceFrame = (state: RootState) => {
  return state.referenceFrames[referenceFrames.pro];
};

export const selectAllReferenceFrameData = createTypedSelector(
  [(state) => state.referenceFrames],
  (referenceFramesData) => {
    const allData: { content: Record<ContentHref, Content>; relations: ContentRelation[] } = {
      content: {},
      relations: [],
    };
    Object.values(referenceFramesData).forEach((referenceFrame) => {
      allData.content = { ...allData.content, ...referenceFrame.content };
      allData.relations = [...allData.relations, ...Object.values(referenceFrame.relations)];
    });
    return allData;
  }
);

export const selectProReferenceFrameRelationsMap = createTypedSelector(
  [(state) => state.referenceFrames[referenceFrames.pro]?.relations],
  (relations) => {
    if (!relations) {
      return null;
    }
    return mapRelationsToRelationsToAndFrom(relations);
  }
);

export const selectProReferenceFramePathToRoot = createTypedSelector(
  [
    (state) => selectProReferenceFrameRelationsMap(state),
    (state) => selectProReferenceFrame(state)?.content,
    (state, href) => href,
  ],
  (relationsMap, content, href) => {
    if (!relationsMap || !content) {
      return null;
    }

    return getPathToRoot(relationsMap, content, href);
  }
);
