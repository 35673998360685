import * as ACTION_TYPES from '@store/constants/actionTypes';
import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { loadNewsletterSettings } from '@newStore/documentApi/documentApiState';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { RequiredType, RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { settings } from '../../../settings';
import constants from '../../../../reduxLoop/constants/constants';
import { proNewsletterAudienceTab } from '../proGenericConfigProps';
import { titleComponent } from '../../helpers/genericComponents';

export const PRONEWSLETTER: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbrief',
    plural: 'PRO.-nieuwsbrieven',
    icon: require('../../../../../img/icons/document.svg'),
    category: 'PRO',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['PRONEWSLETTER'],
  },
  onLoadActions: [loadNewsletterSettings()],
  createDefaults: { newsletterSender: { href: constants.dienstCommunicatieSender } },
  createModal: {
    authors: { addUser: true },
    newsLetterTemplate: { required: true },
    dateToSend: { required: true },
  },
  preloadActions: [
    { type: ACTION_TYPES.GET_NEWSLETTER_SETTINGS },
    getAllOfResource({ resource: '/newsletter/senders', refresh: false }),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
  ],
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.REFERENCE_GROUP },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION, NodeType.PRO_NEWSLETTER_SECTION],
  referenceGroupTypes: [constants.referenceGroupTypes.TRAINING],
  edit: [
    titleComponent,
    { component: 'dateToSend', label: 'Verwachte verzenddatum' },
    {
      component: 'newsletterSender',
      label: 'Afzender',
      required: RequiredType.ERROR,
    },
    {
      component: 'tableOfContents',
      label: 'Inhoudstafel',
      type: 'checkbox',
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'namedSet',
      label: 'Functies (doelgroep)',
      tag: 'doelgroepen',
      property: 'positions',
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  audienceTab: proNewsletterAudienceTab,
  customEditorOptions: {
    showMarkerButton: false,
    hideTermButton: true,
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
  websiteEdition: [{ domain: settings.domains.www }, { domain: settings.domains.pro }],
};
