import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideIdentifier {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        identifiers: {
          ...state.document.viewModel.aside.editDocument.identifiers,
        },
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);
  }

  $onInit() {
    // this.identifier = this.identifiers ? this.identifiers[0] : null;
  }

  change() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(
        this.editKey,
        { $$identifier: this.identifiers.value },
        true,
        null,
        false
      )
    );
  }
}

export default {
  template: require('./asideIdentifier.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideIdentifier,
};
