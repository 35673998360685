import screenComponents from '../screen';
import redactieComponents from '../components';
import redactieFilters from '../filters';
import redactieServices from '../services';
import * as NOTIFICATION_ACTIONS from '../reduxLoop/actions/notificationActions';

const { settings } = require('../config/settings.js');

// Angular Modules
require('angular-sanitize');

// Router
require('@uirouter/angularjs');

// Translations
require('angular-translate');
require('angular-translate-loader-static-files');

// Extra UI components
require('angular-ui-bootstrap');
require('angular-minicolors');
require('angular-aside');
require('../module/bootstrap');
require('../module/jquery-minicolors/jquery.minicolors.min'); // TODO move to a custom npm module which includes this jquery plugin and angular-minicolors
require('../module/jquery-minicolors/jquery.minicolors.css'); // TODO move to a custom npm module which includes this jquery plugin and angular-minicolors

// Time
require('angular-moment');
require('moment/locale/nl-be');

// One time binding
require('angular-bind-notifier');

// Notifications
require('ng-toast');
require('url-search-params-polyfill');

// KathOndVla modules
require('vsko-resource-picker');
require('vsko-notifications');
require('vsko-api-utils');
require('../module/bootstrap');
require('../module/vsko-loading-bar');
require('@kathondvla/angular-shared-utils');

// Redux
require('ng-redux');

// TODO Remove and change to CKeditor
require('../module/angular-summernote/dist/angular-summernote');
require('../module/angular-summernote/lib/summernote');
require('../module/angular-summernote/lib/summernote-nl-NL');
require('../module/angular-summernote/lib/summernote.css');

// Statistics of angular (for dev)
require('../module/ng-stats/ng-stats');

// CSS
require('../../css/main.scss');
require('../../css/custom.scss');
require('../../css/angular-aside.css');
require('../../css/font-awesome/css/font-awesome.min.css');
require('../../css/ngToast/ngToast.scss');
require('../../css/document.scss');

// Redactie Components
require('../directives/utils');

const basicConfig = {
  name: 'api',
  baseUrl: settings.apisAndUrls.contentApi,
};

const modules = [
  'ng',
  'ngSanitize',
  'ui.router',
  'pascalprecht.translate',
  'ngAside',
  'minicolors',
  'summernote',
  'ui.bootstrap',
  'ui.bootstrap.popover',
  'ngToast',
  'VskoApiUtils',
  'resourcePicker',
  'angularStats',
  'angular.bind.notifier',
  'angularMoment',
  'RedactieUtils',
  'vskoLoadingBar',
  'onEventModule',
  'ngRedux',
  'resourcePicker',
  'ng-sri-client',
  require('@kathondvla/angular-shared-components/kovHeaderBar'),
  require('@kathondvla/angular-shared-components/kovDatepicker'),
  require('@kathondvla/angular-shared-components/kovResourcePicker/redactie'),
  require('@kathondvla/angular-shared-components/kovButton'),
  require('@kathondvla/angular-shared-components/kovNotifications'),
  require('@kathondvla/angular-shared-components/kovImageEditor'),
  require('@kathondvla/sri-client/ng-sri-client')([basicConfig]),
  screenComponents.name,
  redactieComponents.name,
  redactieFilters.name,
  redactieServices.name,
];

const app = angular.module('redactie', modules);

app.directive('hasNewVersionLabel', require('../directives/hasNewVersionLabel'));
app.directive('onEventLegacy', require('../directives/onEventLegacy.js'));
app.directive('fileToUploadChange', require('../directives/fileToUploadChange'));
app.directive('compile', require('../directives/compile'));
app.directive('compileInline', require('../directives/compileInline'));

app.factory('params', [
  () => {
    return {};
  },
]);

app.service('$notification', [
  '$ngRedux',
  ($ngRedux) => {
    const that = {};

    that.error = (message, params) => {
      $ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'ERROR',
          message,
          params,
        })
      );
      console.warn(message);
    };

    that.warning = (message) => {
      $ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'WARNING',
          message,
        })
      );
      console.warn(message);
    };

    that.success = (message) => {
      $ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'SUCCESS',
          message,
        })
      );
      console.log(message);
    };

    that.info = (message) => {
      $ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'INFO',
          message,
        })
      );
      console.log(message);
    };

    return that;
  },
]);

export default app;
