import { selectExpiryDateValidation } from '@newStore/validation/validationRules/llinkidCurriculumExpiryDate';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideExpiryDate {
  constructor($scope, $ngRedux, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$translate = $translate;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        expiryDate: state.document.viewModel.aside.editDocument.expiryDate
          ? state.document.viewModel.aside.editDocument.expiryDate
          : undefined,
        expiryDateValidation: selectExpiryDateValidation(
          state,
          state.document.viewModel.aside.editDocument.$$meta.permalink
        ).filter((expiryDateValidationError) => expiryDateValidationError !== true),
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  updateExpiryDate(event, expiryDate) {
    if (expiryDate) {
      expiryDate = new Date(expiryDate).toISOString();
    }

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchExpiryDate(this.editKey, expiryDate));
  }
}

export default {
  template: require('./asideExpiryDate.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideExpiryDate,
};
