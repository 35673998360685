import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { publishComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { proAliasesComponent } from '../proGenericConfigProps';
import { extendsSection } from '../../helpers/genericConfigHelpers';
// Extends SECTION => A SECTION inside a Pro Theme and WebConfig FALLBACK_MENU_LEVEL_3 becomes a PRO_MENU_LEVEL_3
export const PRO_MENU_LEVEL_3: ExtendedBuildingBlockNodeConfig = {
  information: {
    description: 'Pro Cluster: 3rd level in the navigation/menu structure of the Pro Home Page',
    single: 'Cluster',
    plural: 'Clusters',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  webTemplateHref: '/web/templates/3df7d5e7-2188-4c11-88bb-55d67811c0ea' as WebTemplateHref,
  isCollapsedByDefault: true,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    publishComponent,
    proAliasesComponent,
    {
      component: 'themes',
      label: "Thema's",
      valueToString: themeHrefsToString,
    },
  ],
};
