import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { authorsComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const QUOTE: BuildingBlockNodeConfig = {
  information: {
    single: 'Citaat',
    plural: 'Citaten',
    ribonIcon: require('../../../../img/buildingBlocks/quote_xl.svg'),
  },
  node: {
    type: ContentType.QUOTE,
  },
  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  edit: [
    {
      component: 'attachedContent',
      property: '$$html',
      label: 'Citaat',
      definiteArticle: true,
      required: RequiredType.ERROR,
    },
    {
      ...authorsComponent,
      // for QUOTE this is free text ("alia iacta est" - Julius Caesar) and not an href to /persons
      valueToString: (c) => (c.creators ? c.creators.join(', ') : ''),
      options: {
        freeText: true,
      },
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'html',
  },
};

export default QUOTE;
