import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const MUSIC: BuildingBlockNodeConfig = {
  information: {
    single: 'Muziek',
    plural: 'Muziek',
    ribonIcon: require('../../../../img/buildingBlocks/MUSIC.svg'),
  },
  node: {
    type: ContentType.MUSIC,
  },
  edit: [
    {
      component: 'music',
      label: 'Muziek',
      property: 'attachments',
      required: RequiredType.ERROR,
      options: {
        type: ContentType.ATTACHMENT,
        fields: ['description', 'rightsHolder'],
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default MUSIC;
