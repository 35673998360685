import { ContentTag, ContentType, WebTemplateHref } from '@generalTypes/apiTypes';
import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import {
  proAliasesComponent,
  proDefaultBuildingBlocks,
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proThemeAudienceTab,
  proThemeTocTypes,
} from '../proGenericConfigProps';

const proThemeHome = {
  information: {
    definiteArticle: true,
    single: 'PRO.-thema: Home',
    plural: "PRO.-thema's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['WEBPAGE2' as ContentTag],
  },
  extends: NodeType.WEBPAGE2,
  buildingBlocks: proDefaultBuildingBlocks,
  tocTypes: proThemeTocTypes,
  edit: [...proDocumentStandardComponents, proAliasesComponent, ...proLinksAndMenuReferences],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};

// Extends WEBPAGE2 => When adding THEME_HOME_FULL WebConfig to a WEBPAGE2 it becomes a PRO_THEME_HOME_FULL
export const PRO_THEME_HOME_FULL: ExtendedRootNodeConfig = {
  ...proThemeHome,
  information: {
    ...proThemeHome.information,
    single: 'PRO.-thema: Home (titel, foto en korte omschrijving)',
  },
  webTemplateHref: '/web/templates/1a909cc2-b861-4081-b8bf-bbf7da48778e' as WebTemplateHref,
};

// Extends WEBPAGE2 => When adding THEME_HOME_PICTURE WebConfig to a WEBPAGE2 it becomes a PRO_THEME_HOME_PICTURE
export const PRO_THEME_HOME_PICTURE: ExtendedRootNodeConfig = {
  ...proThemeHome,
  information: {
    ...proThemeHome.information,
    single: 'PRO.-thema: Home (titel en foto)',
  },
  webTemplateHref: '/web/templates/dc53d1ff-d84e-461a-8aea-932c5dd9c69b' as WebTemplateHref,
};

// Extends WEBPAGE2 => When adding THEME_HOME_TEXT WebConfig to a WEBPAGE2 it becomes a PRO_THEME_HOME_TEXT
export const PRO_THEME_HOME_TEXT: ExtendedRootNodeConfig = {
  ...proThemeHome,
  information: {
    ...proThemeHome.information,
    single: 'PRO.-thema: Home (titel en korte omschrijving)',
  },
  webTemplateHref: '/web/templates/1d45237b-6484-4119-a485-ef0015c702a1' as WebTemplateHref,
};
