import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { llinkidDefaultBuildingBlocks } from './llinkidGenericConfigProps';

const LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT: BuildingBlockNodeConfig = {
  information: {
    single: 'Pedagogisch didactische duiding',
    plural: 'Pedagogisch didactische duiding',
  },
  node: {
    type: ContentType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
  },
  buildingBlocks: llinkidDefaultBuildingBlocks,
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,
  createInModal: false,
  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT;
