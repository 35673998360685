import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { RequiredType, RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { settings } from '../../settings';
import constants from '../../../reduxLoop/constants/constants';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import {
  proDefaultBuildingBlocks,
  proThemeAudienceTab,
  proAliasesComponent,
} from './proGenericConfigProps';
// Generic Pro Theme in the list screen or onLoading before it has the full document context and becomes something more specific
// Also WEBPAGE2 with invalid WebConfig will not match a specific Pro Theme and will be a WEBPAGE2
export const WEBPAGE2: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'PRO.-thema',
    plural: "PRO.-thema's",
    icon: require('../../../../img/icons/webpage.svg'),
    category: 'PRO',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['WEBPAGE2'],
  },
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
  ],
  createDefaults: {
    accessRights: accessRights.publiek.namedsets,
    descendantsAccessRights: accessRights.publiek.namedsets,
  },
  buildingBlocks: proDefaultBuildingBlocks,
  tocTypes: [NodeType.PRO_THEME_SECTION, NodeType.FAQ_GROUP],
  edit: [
    {
      component: 'title',
      label: 'Titel',
      required: RequiredType.ERROR,
    },
    {
      component: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      whitelist: [
        {
          webconfiguration: {
            self: [
              'THEME_HOME_TEXT',
              'THEME_HOME_PICTURE',
              'THEME_HOME_FULL',
              'THEME_PAGE',
              'TEMPORARY_PAGE',
            ],
          },
        },
      ],
    },
    {
      component: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      blacklist: [
        {
          webconfiguration: {
            self: [
              'THEME_HOME_TEXT',
              'THEME_HOME_PICTURE',
              'THEME_HOME_FULL',
              'THEME_PAGE',
              'TEMPORARY_PAGE',
            ],
          },
        },
      ],
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      component: 'authors',
      label: 'Themaverantwoordelijke',
    },
    {
      component: 'issued',
      label: 'Publicatiedatum',
    },
    {
      ...proAliasesComponent,
      whitelist: [
        {
          webconfiguration: {
            self: [
              'BLOG',
              'MINI_DATABASE',
              'THEME_PAGE',
              'THEME_HOME_FULL',
              'THEME_HOME_PICTURE',
              'THEME_HOME_TEXT',
            ],
          },
        },
      ],
    },
    {
      component: 'themes',
      label: "Thema's",
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
    },
    {
      component: 'curriculumSelector',
      label: "Leerplanthema's",
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== ContentType.CURRICULUM_THEME ||
            !theme.$$children.some((c) => c.type === ContentType.CURRICULUM_THEME)
          );
        },
      },
    },
    {
      component: 'contacts',
      label: 'Contactpersonen',
      width: '12',
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights',
      },
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'themes',
      label: 'Databanktype',
      options: {
        referenceFrameKey: constants.contentTypesKey,
        types: [ContentType.THEME],
      },
      whitelist: [
        {
          webconfiguration: {
            self: ['MINI_DATABASE'],
          },
        },
      ],
    },
    {
      component: 'menuItemReference',
      label: 'Navigeert naar',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [NodeType.WEBPAGE2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    {
      component: 'linkGroup',
      width: '12',
      label: 'Links',
      options: {
        type: 'group',
      },
    },
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  isCloneable: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  allowSuggestions: true,
  globalAttachmentsGroupContainer: true,
};
