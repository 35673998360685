import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { titleComponent, identifierComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ODET_DEVELOPMENT_GOAL: BuildingBlockNodeConfig = {
  information: {
    single: 'Ontwikkelingsdoel',
    plural: 'Ontwikkelingsdoelen',
  },
  node: {
    type: ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL,
  },
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving / Afbakening',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: false,
  documentViewIdentifier: 'o(p*)(.)i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_DEVELOPMENT_GOAL;
