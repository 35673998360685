import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proContentTypeForDatabasePicker,
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proTileStandardComponents,
  proThemeAudienceTab,
  proThemeTocTypes,
  proAliasesComponent,
} from '../proGenericConfigProps';

const proDatabase = {
  information: {
    description:
      'Pro Database (webTemplate code MINI_DATABASE) can be a SECTION (a tile) in a home or can be the root (single page pro theme)',
    single: 'PRO.-tegel: Databank',
    plural: 'Databanken',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  webTemplateHref: '/web/templates/34371fa8-c06b-4ace-83ad-180e5cd0b50b' as WebTemplateHref,
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig MINI_DATABASE becomes a PRO_DATABASE_TILE
export const PRO_DATABASE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proDatabase,
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents,
    proAliasesComponent,
    proContentTypeForDatabasePicker,
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding MINI_DATABASE WebConfig to a WEBPAGE2 it becomes a PRO_DATABASE_STANDALONE
export const PRO_DATABASE_STANDALONE: ExtendedRootNodeConfig = {
  ...proDatabase,
  information: {
    ...proDatabase.information,
    definiteArticle: true,
    single: 'PRO.-thema: Database',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [
    ...proDocumentStandardComponents,
    proContentTypeForDatabasePicker,
    ...proLinksAndMenuReferences,
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
