import { ContentType } from '@generalTypes/apiTypes';
import { NodeType, BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '../helpers/genericComponents';

export const LLINKID_GOAL: BuildingBlockNodeConfig = {
  information: {
    single: 'Leerplandoel',
    plural: 'Leerplandoelen',
  },
  node: {
    type: ContentType.LLINKID_GOAL,
  },
  createDefaults: {
    llinkidGoalType: 'REGULAR',
  },
  edit: [
    {
      component: 'description',
      label: 'Omschrijving',
      required: RequiredType.WARNING,
      options: {
        showDemarcationButton: true,
        showMarkerButton: true,
      },
    },
    {
      ...identifierComponent,
      required: RequiredType.ERROR,
    },
    {
      component: 'goalReplaces',
      type: 'llinkidReplaces',
      label: 'components.llinkid.goalReplaces.title',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidGoalRelationsModal',
        modalTitle: 'components.llinkid.goalReplaces.modalTitle',
      },
    },
    {
      component: 'goalType',
      type: 'llinkidGoalType',
      label: 'components.llinkid.goalType.title',
    },
    {
      component: 'attitudinal',
      label: 'Attitude',
      type: 'checkbox',
    },
    { component: 'demarcations', label: 'Stefan, why do I not see this?' },
    { component: 'goalIdentifier', label: 'Stefan, why do I not see this?' },
    {
      component: 'goalRelations',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalRelations.title',
      options: {
        relationTypes: ['RELATION'],
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
      },
    },
    {
      component: 'goalOdetEndTermReferences',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalOdetEndTermReferences.title',
      options: {
        relationTypes: ['REFERENCES'],
        toType: 'CURRICULUM_ODET_DEVELOPMENT_GOAL',
        modal: 'llinkidGoalOdetEndTermReferencesModal',
        listColumn: 'title',
      },
    },
    {
      component: 'llinkidFoundationalSuggestions',
      label: 'components.llinkid.goalSuggestions.title',
      options: {
        relationTypes: ['SUGGESTED_EDUCATIONAL_ACTIVITY'],
        listColumn: 'description',
      },
      showInAncestorTypes: [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
      whitelist: [
        {
          root: {
            foundational: true,
          },
        },
      ],
    },
    {
      component: 'goalImplements',
      type: 'llinkidRelations',
      label: 'Wordt (geheel of gedeeltelijk) gerealiseerd door',
      options: {
        relationTypes: ['IMPLEMENTS', 'PARTIALLY_IMPLEMENTS'],
        selectType: true,
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
        revertedRelationDirection: true,
      },
      showInAncestorTypes: [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
      whitelist: [
        {
          root: {
            foundational: true,
          },
        },
      ],
    },
    {
      component: 'goalImplements',
      type: 'llinkidRelations',
      label: 'Realiseert (geheel of gedeeltelijk)',
      options: {
        relationTypes: ['IMPLEMENTS', 'PARTIALLY_IMPLEMENTS'],
        selectType: true,
        readOnly: true,
        listColumn: 'description',
      },
      showInAncestorTypes: [NodeType.LLINKID_CURRICULUM],
      whitelist: [
        {
          root: {
            type: ContentType.LLINKID_CURRICULUM,
          },
        },
      ],
      blacklist: [
        {
          root: {
            foundational: true,
          },
        },
      ],
    },
  ],
  buildingBlocks: [
    { type: NodeType.LLINKID_GOAL_DEMARCATION },
    { type: NodeType.LLINKID_PEDAGOGICAL_TIP },
    { type: NodeType.LLINKID_EXTRA_GOAL_INFORMATION },
    { type: NodeType.LLINKID_GOAL_EXPLANATION },
    { type: NodeType.LLINKID_GOAL },
    { type: NodeType.LLINKID_INITIAL_SITUATION },
  ],
  documentViewIdentifier: '(p)i.i',
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_GOAL;
