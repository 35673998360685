import { EditComponent, RequiredType } from '@nodeTypeConfig/configTypes';
import { formatDate } from '@newStore/genericHelpers';
import { Content } from '@generalTypes/apiTypes';
import { entityHrefsArrayToString } from './editComponentHelpers';

export const identifierComponent: EditComponent = {
  component: 'identifier',
  property: 'identifiers',
  label: 'Prefix',
};

export const titleComponent: EditComponent = {
  component: 'title',
  property: 'title',
  label: 'Titel',
  required: RequiredType.ERROR,
};

export const authorsComponent: EditComponent = {
  component: 'authors',
  property: 'creators',
  label: 'Auteurs',
  valueToString: (c, a) => entityHrefsArrayToString(c.creators, a),
};

export const publishComponent: EditComponent = {
  component: 'issued',
  property: 'issued',
  label: 'Publicatiedatum',
  valueToString: (c: Content) => formatDate(c.issued, false),
};

const importance = {
  VERY_LOW: 'heel laag',
  LOW: 'laag',
  MEDIUM: 'normaal',
  HIGH: 'hoog',
  VERY_HIGH: 'heel hoog',
};
export const importanceComponent: EditComponent = {
  component: 'importance',
  property: 'importance',
  label: 'Belangrijkheid',
  valueToString: (c: Content) => importance[c.importance] || '',
};
