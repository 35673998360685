import { BuildingBlockNodeConfig, RequiredType, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_CLUSTER: BuildingBlockNodeConfig = {
  information: {
    single: 'Leerplancluster',
    plural: 'Leerplanclusters',
  },
  node: {
    type: ContentType.CURRICULUM_CLUSTER,
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_THEME }],
  edit: [
    {
      component: 'title',
      label: 'Titel',
      required: RequiredType.ERROR,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  themesDisplaySuffix: '(cluster)',
  isCollapsible: false,
};

export default CURRICULUM_CLUSTER;
