export const createQuery = (searchParams, types) => {
  const moment = require('moment');
  const userSearchableTypes = searchParams.searchables;
  let result = '';

  if (searchParams.q && searchParams.q.trim().length > 0) {
    result += `&titleContains=${encodeURIComponent(searchParams.q.trim())}`;
  }

  const values = [];
  const tags = [];
  const tagsNot = [];
  const valuesNot = [];

  if (
    searchParams.types &&
    Object.keys(searchParams.types).filter((o) => searchParams.types[o]).length > 0
  ) {
    Object.keys(searchParams.types).forEach((k) => {
      if (searchParams.types[k]) {
        if (types[k].node && types[k].node.type) {
          values.push(types[k].node.type);
        }
        if (types[k].node && types[k].node.tags) {
          types[k].node.tags.forEach((tag) => {
            tags.push(tag);
          });
        }
      }
    });
  } else {
    // no search type checkbox selected for a non-full user
    // then search for all available types for that user
    // (it's the same as if all checkboxes were checked)

    userSearchableTypes.forEach((userSearchableType) => {
      const k = userSearchableType.type;

      if (types[k].node && types[k].node.type) {
        values.push(types[k].node.type);
      }
      if (types[k].node && types[k].node.tags) {
        types[k].node.tags.forEach((tag) => {
          tags.push(tag);
        });
      }
    });
  }

  if (values.length > 0) {
    result += `&typeIn=${values.join(',')}`;
  }

  if (valuesNot.length > 0) {
    result += `&typeNotIn=${valuesNot.join(',')}`;
  }

  if (tags.length > 0) {
    result += `&tagsOverlaps=${tags.join(',')}`;
  }

  if (tagsNot.length > 0) {
    result += `&tagsNotIn=${tagsNot.join(',')}`;
  }

  if (searchParams.issued) {
    let nextDay = moment(searchParams.issued).add(1, 'day').utc().format(); //eslint-disable-line
    result += `&issuedAfter=${moment(searchParams.issued).utc().format()}&issuedBefore=${nextDay}`;
  }

  if (searchParams.modifiedFrom) {
    result += `&$$meta.modifiedAfter=${moment(searchParams.modifiedFrom).utc().format()}`;
  }
  if (searchParams.modifiedTo) {
    let nextDay = moment(searchParams.modifiedTo).add(1, 'day').utc().format(); //eslint-disable-line
    result += `&$$meta.modifiedBefore=${nextDay}`;
  }

  if (searchParams.author) {
    result += `&creatorsContains=${searchParams.author.$$meta.permalink}`;
  }

  if (searchParams.theme) {
    result += `&themesContains=${searchParams.theme.$$meta.permalink}`;
  }

  if (searchParams.columnSort) {
    result += `&orderBy=${searchParams.columnSort.name}${
      !searchParams.columnSort.isAscending ? '&descending=true' : ''
    }`;
  } else {
    // TODO change by issued , but talk to EZE to add like issuedIsNone=true or so
    result += '&orderBy=$$meta.modified,key&descending=true';
  }

  if (result[0] === '&') {
    result = result.slice(1, result.length);
  }

  return result;
};
