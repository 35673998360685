import isMatch from 'lodash/isMatch';
import { WebPage } from '@generalTypes/apiTypes';
import { SHARED_ATTACHMENTS_GROUP } from './PRO/proLeerplansites/SHARED_ATTACHMENTS_GROUP';
import { SHARED_FAQ } from './PRO/proLeerplansites/SHARED_FAQ';
import { SHARED_MINI_DATABASE_ITEM } from './PRO/proLeerplansites/SHARED_MINI_DATABASE_ITEM';
import REFERENCE_FRAME from './THEME/REFERENCE_FRAME';
import THEME from './THEME/THEME';
import CURRICULUM_CLUSTER from './THEME/CURRICULUM_CLUSTER';
import CURRICULUM_THEME from './THEME/CURRICULUM_THEME';
import TEASER from './PRO/proNews/TEASER';
import PRONEWSITEM from './PRO/proNews/PRONEWSITEM';
import { PRONEWSLETTER } from './PRO/proNews/PRONEWSLETTER';
import PRONEWSLETTERTEMPLATE from './PRO/proNews/PRONEWSLETTERTEMPLATE';
import { WEBPAGE2 } from './PRO/WEBPAGE2';
import { JOB_OFFER } from './WWW/JOB_OFFER';
import { PRESS } from './WWW/PRESS';
import { WWW } from './WWW/WWW';
import { WWWNEWSITEM } from './WWW/WWWNEWSITEM';
import CURRICULUM_ODET from './ODET/CURRICULUM_ODET';
import CURRICULUM_ODET_LEVEL from './ODET/CURRICULUM_ODET_LEVEL';
import CURRICULUM_ODET_LEERGEBIED from './ODET/CURRICULUM_ODET_LEERGEBIED';
import CURRICULUM_ODET_KEY_COMPETENCE from './ODET/CURRICULUM_ODET_KEY_COMPETENCE';
import CURRICULUM_ODET_SUBSECTION from './ODET/CURRICULUM_ODET_SUBSECTION';
import CURRICULUM_ODET_DEVELOPMENT_GOAL from './ODET/CURRICULUM_ODET_DEVELOPMENT_GOAL';
import CURRICULUM_ODET_ENDTERM from './ODET/CURRICULUM_ODET_ENDTERM';
import CURRICULUM_ZILL from './ZILL/CURRICULUM_ZILL';
import CURRICULUM_ZILL_CLUSTER from './ZILL/CURRICULUM_ZILL_CLUSTER';
import CURRICULUM_ZILL_DEVELOPMENT_FIELD from './ZILL/CURRICULUM_ZILL_DEVELOPMENT_FIELD';
import CURRICULUM_ZILL_DEVELOPMENT_THEME from './ZILL/CURRICULUM_ZILL_DEVELOPMENT_THEME';
import CURRICULUM_ZILL_GENERIC_GOAL from './ZILL/CURRICULUM_ZILL_GENERIC_GOAL';
import CURRICULUM_ZILL_CONTENT_GROUP from './ZILL/CURRICULUM_ZILL_CONTENT_GROUP';
import CURRICULUM_ZILL_DEVELOPMENT_CONTENT from './ZILL/CURRICULUM_ZILL_DEVELOPMENT_CONTENT';
import {
  CURRICULUM_ZILL_LEERLIJN,
  CURRICULUM_ZILL_LEERLIJN_AN,
} from './ZILL/CURRICULUM_ZILL_LEERLIJN';
import CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE from './ZILL/CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE';
import CURRICULUM_ZILL_LEERLIJN_CLUSTER from './ZILL/CURRICULUM_ZILL_LEERLIJN_CLUSTER';
import CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE from './ZILL/CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE';
import ZILL_ILLUSTRATION from './ZILL/ZILL_ILLUSTRATION';
import PRACTICAL_EXAMPLE from './ZILL/PRACTICAL_EXAMPLE';
import WEBPAGE from './ZILL/WEBPAGE';
import { LLINKID_CURRICULUM, LLINKID_FOUNDATIONAL_CURRICULUM } from './LLINKID/LLINKID_CURRICULUM';
import LLINKID_GOAL_LIST from './LLINKID/LLINKID_GOAL_LIST';
import LLINKID_GOAL_SECTION from './LLINKID/LLINKID_GOAL_SECTION';
import LLINKID_GOAL from './LLINKID/LLINKID_GOAL';
import LLINKID_GOAL_DEMARCATION from './LLINKID/LLINKID_GOAL_DEMARCATION';
import LLINKID_PEDAGOGICAL_TIP from './LLINKID/LLINKID_PEDAGOGICAL_TIP';
import LLINKID_EXTRA_GOAL_INFORMATION from './LLINKID/LLINKID_EXTRA_GOAL_INFORMATION';
import LLINKID_GOAL_EXPLANATION from './LLINKID/LLINKID_GOAL_EXPLANATION';
import LLINKID_INITIAL_SITUATION from './LLINKID/LLINKID_INITIAL_SITUATION';
import LLINKID_GUIDING_PRINCIPLE_GROUP from './LLINKID/LLINKID_GUIDING_PRINCIPLE_GROUP';
import LLINKID_GUIDING_PRINCIPLE from './LLINKID/LLINKID_GUIDING_PRINCIPLE';
import LLINKID_STATIC_PART from './LLINKID/LLINKID_STATIC_PART';
import LLINKID_CONTEXT from './LLINKID/LLINKID_CONTEXT';
import LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT from './LLINKID/LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT';
import LLINKID_BASIC_MATERIAL_REQUIREMENTS from './LLINKID/LLINKID_BASIC_MATERIAL_REQUIREMENTS';
import LLINKID_CORRELATION_ENDTERMS from './LLINKID/LLINKID_CORRELATION_ENDTERMS';
import LLINKID_CONCORDANCE from './LLINKID/LLINKID_CONCORDANCE';
import VISION_TEXT from './GENERIC_TYPES/VISION_TEXT';
import VISION_TEXT_SECTION from './GENERIC_TYPES/VISION_TEXT_SECTION';
import TERM from './GENERIC_TYPES/TERM';
import MARK_EXPLANATION from './GENERIC_TYPES/MARK_EXPLANATION';
import GLOBAL_DOCUMENT from './GENERIC_TYPES/GLOBAL_DOCUMENT';
import { NodeType, NodeTypeConfig } from './configTypes';
import {
  PRO_THEME_HOME_FULL,
  PRO_THEME_HOME_PICTURE,
  PRO_THEME_HOME_TEXT,
} from './PRO/proThemes/PRO_THEME_HOME';
import { PRO_TEXT_PAGE_TILE, PRO_TEXT_PAGE_STANDALONE } from './PRO/proThemes/PRO_TEXT_PAGE';
import { PRO_DATABASE_TILE, PRO_DATABASE_STANDALONE } from './PRO/proThemes/PRO_DATABASE';
import { PRO_BLOG_TILE, PRO_BLOG_STANDALONE } from './PRO/proThemes/PRO_BLOG';
import {
  PRO_DOWNLOAD_PAGE_TILE,
  PRO_DOWNLOAD_PAGE_STANDALONE,
} from './PRO/proThemes/PRO_DOWNLOAD_PAGE';
import { PRO_FAQ_PAGE_TILE } from './PRO/proThemes/PRO_FAQ_PAGE';
import PRO_BLOG_ITEM from './PRO/proThemes/PRO_BLOG_ITEM';
import { PRO_HOME_PAGE } from './PRO/proMenu/PRO_HOME_PAGE';
import { PRO_MENU_LEVEL_3 } from './PRO/proMenu/PRO_MENU_LEVEL_3';
import { PRO_MENU_LEVEL_2 } from './PRO/proMenu/PRO_MENU_LEVEL_2';
import PRO_DATABASE_ITEM from './PRO/proThemes/PRO_DATABASE_ITEM';
import { PRO_GLOBAL_DATABASE } from './PRO/proThemes/PRO_GLOBAL_DATABASE';
import PRO_MENU_SECTION from './PRO/proMenu/PRO_MENU_SECTION';
import {
  PRO_TEMPORARY_PAGE_TILE,
  PRO_TEMPORARY_PAGE_STANDALONE,
} from './PRO/proThemes/PRO_TEMPORARY_PAGE';
import PRO_NEWSLETTER_SECTION from './PRO/proNews/PRO_NEWSLETTER_SECTION';
import PRO_GLOBAL_DATABASE_SECTION from './PRO/proThemes/PRO_GLOBAL_DATABASE_SECTION';
import { PRO_CURRICULUM_PAGE_TILE } from './PRO/proThemes/PRO_CURRICULUM_PAGE';
import { PRO_STATIC_PAGE } from './PRO/proThemes/PRO_STATIC_PAGE';
import { WWW_STATIC_PAGE } from './WWW/WWW_STATIC_PAGE';
import PRO_THEME_SECTION from './PRO/proThemes/PRO_THEME_SECTION';
import SECTION_NEW from './BUILDING_BLOCKS/SECTION_NEW';
import SECTION from './BUILDING_BLOCKS/SECTION';
import PARAGRAPH from './BUILDING_BLOCKS/PARAGRAPH';
import ATTACHMENTS_GROUP from './BUILDING_BLOCKS/ATTACHMENTS_GROUP';
import ATTACHMENT from './BUILDING_BLOCKS/ATTACHMENT';
import IMAGE_GROUP from './BUILDING_BLOCKS/IMAGE_GROUP';
import IMAGE from './BUILDING_BLOCKS/IMAGE';
import VIDEO_GROUP from './BUILDING_BLOCKS/VIDEO_GROUP';
import VIDEO from './BUILDING_BLOCKS/VIDEO';
import MUSIC from './BUILDING_BLOCKS/MUSIC';
import REFERENCE_GROUP from './BUILDING_BLOCKS/REFERENCE_GROUP';
import { REFERENCE } from './BUILDING_BLOCKS/REFERENCE';
import FAQ_GROUP from './BUILDING_BLOCKS/FAQ_GROUP';
import FAQ from './BUILDING_BLOCKS/FAQ';
import EXAMPLE from './BUILDING_BLOCKS/EXAMPLE';
import LEGAL from './BUILDING_BLOCKS/LEGAL';
import QUOTE from './BUILDING_BLOCKS/QUOTE';
import SUMMARY from './BUILDING_BLOCKS/SUMMARY';
import SOURCE from './BUILDING_BLOCKS/SOURCE';
import LINK_GROUP from './BUILDING_BLOCKS/LINK_GROUP';
import PAGE_ATTACHMENTS_GROUP from './BUILDING_BLOCKS/PAGE_ATTACHMENTS_GROUP';
import WORD_IMPORT from './BUILDING_BLOCKS/WORD_IMPORT';

export const nodeTypeConfigurations = {
  // If type not defined explicitly, will take this config
  GENERIC: require('./GENERIC'),

  // PRO Menu (technically also a Pro Theme because it is tag WEBPAGE2, but actually the Pro Menu which is not a Pro Theme Page)
  PRO_HOME_PAGE,
  PRO_MENU_LEVEL_2,
  PRO_MENU_LEVEL_3,
  PRO_MENU_SECTION,
  // PRO Theme
  PRO_THEME_HOME_FULL,
  PRO_THEME_HOME_TEXT,
  PRO_THEME_HOME_PICTURE,
  PRO_TEXT_PAGE_TILE,
  PRO_TEXT_PAGE_STANDALONE,
  PRO_DATABASE_TILE,
  PRO_DATABASE_STANDALONE,
  PRO_DATABASE_ITEM,
  PRO_BLOG_TILE,
  PRO_BLOG_STANDALONE,
  PRO_BLOG_ITEM,
  PRO_DOWNLOAD_PAGE_TILE,
  PRO_DOWNLOAD_PAGE_STANDALONE,
  PRO_FAQ_PAGE_TILE,
  PRO_CURRICULUM_PAGE_TILE,
  PRO_TEMPORARY_PAGE_TILE,
  PRO_TEMPORARY_PAGE_STANDALONE,
  PRO_GLOBAL_DATABASE,
  PRO_GLOBAL_DATABASE_SECTION,
  PRO_STATIC_PAGE,
  PRO_THEME_SECTION,
  WEBPAGE2, // generic Pro Theme
  // Pro leerplansites so
  SHARED_MINI_DATABASE_ITEM,
  SHARED_FAQ,
  SHARED_ATTACHMENTS_GROUP,
  // Pro Newsletter
  TEASER,
  PRONEWSITEM,
  PRONEWSLETTER,
  PRONEWSLETTERTEMPLATE,
  PRO_NEWSLETTER_SECTION,

  // WWW
  WWW_STATIC_PAGE,
  WWW,
  WWWNEWSITEM,
  JOB_OFFER,
  PRESS,

  // ZILL
  CURRICULUM_ZILL,
  CURRICULUM_ZILL_CLUSTER,
  CURRICULUM_ZILL_DEVELOPMENT_FIELD,
  CURRICULUM_ZILL_DEVELOPMENT_THEME,
  CURRICULUM_ZILL_GENERIC_GOAL,
  CURRICULUM_ZILL_CONTENT_GROUP,
  CURRICULUM_ZILL_DEVELOPMENT_CONTENT,
  CURRICULUM_ZILL_LEERLIJN,
  CURRICULUM_ZILL_LEERLIJN_AN,
  CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE,
  CURRICULUM_ZILL_LEERLIJN_CLUSTER,
  CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE,
  ZILL_ILLUSTRATION,
  PRACTICAL_EXAMPLE,
  WEBPAGE,

  // ODET
  CURRICULUM_ODET,
  CURRICULUM_ODET_LEVEL,
  CURRICULUM_ODET_LEERGEBIED,
  CURRICULUM_ODET_KEY_COMPETENCE,
  CURRICULUM_ODET_SUBSECTION,
  CURRICULUM_ODET_DEVELOPMENT_GOAL,
  CURRICULUM_ODET_ENDTERM,

  // LLINKID
  LLINKID_CURRICULUM,
  LLINKID_FOUNDATIONAL_CURRICULUM,
  LLINKID_GOAL_LIST,
  LLINKID_GOAL_SECTION,
  LLINKID_GOAL,
  LLINKID_GOAL_DEMARCATION,
  LLINKID_PEDAGOGICAL_TIP,
  LLINKID_EXTRA_GOAL_INFORMATION,
  LLINKID_GOAL_EXPLANATION,
  LLINKID_INITIAL_SITUATION,
  LLINKID_GUIDING_PRINCIPLE_GROUP,
  LLINKID_GUIDING_PRINCIPLE,
  LLINKID_STATIC_PART,
  LLINKID_CONTEXT,
  LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
  LLINKID_BASIC_MATERIAL_REQUIREMENTS,
  LLINKID_CORRELATION_ENDTERMS,
  LLINKID_CONCORDANCE,

  // Other generic root documents
  VISION_TEXT,
  VISION_TEXT_SECTION,
  TERM,
  MARK_EXPLANATION,
  GLOBAL_DOCUMENT,

  // "Themes": Reference frames (ordeningskaders)
  REFERENCE_FRAME,
  THEME,
  CURRICULUM_CLUSTER,
  CURRICULUM_THEME,

  // BUILDING BLOCKS (need to be at the bottom, buildingBlocks can be overwritten with a more specific definition)
  REFERENCE_GROUP,
  REFERENCE,
  SECTION,
  SECTION_NEW,
  PARAGRAPH,
  IMAGE,
  IMAGE_GROUP,
  VIDEO,
  VIDEO_GROUP,
  EXAMPLE,
  ATTACHMENTS_GROUP,
  ATTACHMENT,
  MUSIC,
  QUOTE,
  FAQ,
  FAQ_GROUP,
  SUMMARY,
  LEGAL,
  WORD_IMPORT, // Not really a node type but only a building block
  // nodes only managable in aside
  SOURCE,
  LINK_GROUP,
  PAGE_ATTACHMENTS_GROUP,
};

function getTypeEditConfig(typeEditConfig) {
  return typeEditConfig.map((section) => {
    if (typeof section === 'string') {
      section = {
        component: section,
      };
    }
    return section;
  });
}

function getTypeBuildingBlocks(typeBuildingBlocks) {
  return typeBuildingBlocks.map((block) => {
    if (typeof block === 'string') {
      block = {
        type: block,
      };
    }
    return block;
  });
}

/**
 * some weird sh*t that was done in the securityCommands.
 * apparently the type is expected to be set in each of the configs like this.
 */
function setallTypesOfTypes(typesObj) {
  // this mutation was done in 'getNodeTypeConfig'
  Object.keys(typesObj).forEach((type) => {
    const typeConfig = typesObj[type];

    // adding a property type to every type that includes the "key" of the type
    typeConfig.type = type;

    // this mutation was done in contentRow/index.js!
    typeConfig.customRender = Boolean(typeConfig.customRender);

    // these mutations were done in 'getNodeTypeConfig'
    typeConfig.edit = typeConfig.edit ? getTypeEditConfig(typeConfig.edit) : [];

    typeConfig.buildingBlocks = typeConfig.buildingBlocks
      ? getTypeBuildingBlocks(typeConfig.buildingBlocks)
      : [];
    // endOf 'getNodeTypeConfig' mutations

    // setting default values to all nodes types if the type doesn't has it
    if (typeof typeConfig.isCollapsible === 'undefined') {
      typeConfig.isCollapsible = true;
    }
  });
}

setallTypesOfTypes(nodeTypeConfigurations);

export const documentTypesAllowingSuggestions = Object.values(nodeTypeConfigurations).filter(
  (type) => type.allowSuggestions
);

export const getNodeTypeConfig = (nodeType: NodeType) => {
  const config = nodeTypeConfigurations[nodeType] as NodeTypeConfig;
  if (!config) {
    throw new Error(`Type not defined: ${nodeType}`);
  }
  return config;
};

/**
 * For the old vm and the aside which is still used
 * We will make sure we keep returning the same old types
 */

// TODO: getNodeType is called like 100k times in a document like Leeftocht.
// once the refactor is close to done and the old VM is not used, check if we can improve performance by using a selector selectTypeForContent for the new VM
export const getOldNodeType = (node): NodeType => {
  for (const [nodeType, nodeTypeConfig] of Object.entries(nodeTypeConfigurations)) {
    if (
      nodeTypeConfig.node &&
      !nodeTypeConfig.webTemplateHref &&
      !nodeTypeConfig.documentTypes &&
      isMatch(node, nodeTypeConfig.node)
    ) {
      if (nodeType === NodeType.PAGE_ATTACHMENTS_GROUP) {
        // introduced in refactor but to be sure use the old one in old code
        return NodeType.ATTACHMENTS_GROUP;
      }
      if (nodeType === NodeType.LLINKID_FOUNDATIONAL_CURRICULUM) {
        // introduced in refactor but to be sure use the old one in old code
        return NodeType.LLINKID_CURRICULUM;
      }
      return nodeType as NodeType;
    }
  }
  console.log('[getOldNodeType] NodeType could not be found for ', node);
  throw new Error(`[getOldNodeType] Type not found for: ${node.$$meta.permalink}`);
};

/* const getExtendingType = (basicNodeType: NodeType, documentType: NodeType | null) => {
  // @ts-expect-error not used yet
  for (const [nodeType, nodeTypeConfig] of Object.entries(nodeTypeConfigurations)) {
    if (
      nodeTypeConfig.extends === basicNodeType &&
      (nodeTypeConfig.documentTypes?.includes(documentType) ||
        (!nodeTypeConfig.documentTypes && documentType === null)) &&
      !nodeTypeConfig.webTemplateHref
    ) {
      return nodeType as NodeType;
    }
  }
  return null;
}; */

export const getNodeType = (
  node, // can be Content but also external data so we can not type it unless we type what external data is
  webPageThatMightBeDeleted: WebPage | undefined,
  documentType: NodeType | null
): NodeType => {
  const webPage =
    !node.$$meta.deleted && webPageThatMightBeDeleted?.$$meta.deleted
      ? undefined
      : webPageThatMightBeDeleted;
  for (const [nodeType, nodeTypeConfig] of Object.entries(nodeTypeConfigurations)) {
    const isWebPageMatch =
      (!webPage && !nodeTypeConfig.webTemplateHref) || // if no webPage is given the configType should not specify a web template
      (webPage && nodeTypeConfig.webTemplateHref === webPage.template.href);
    const isDocumentTypeMatch =
      !nodeTypeConfig.documentTypes || nodeTypeConfig.documentTypes.includes(documentType);

    if (
      isWebPageMatch &&
      isDocumentTypeMatch &&
      nodeTypeConfig.node &&
      isMatch(node, nodeTypeConfig.node)
    ) {
      if (documentType && nodeType === NodeType.SECTION) {
        return NodeType.SECTION_NEW;
      }

      return nodeType as NodeType;
    }
  }
  if (webPage) {
    // if match could not be made with webPage try without for more generic type (for example CURRICULA_NAVIGATION can just be a WEBPAGE2)
    return getNodeType(node, undefined, documentType);
  }
  console.log('[getNodeType] NodeType could not be found for ', node);
  throw new Error(`[getNodeType] Type not found for: ${node.$$meta.permalink}`);
};

// call this if you explecitely know that you don't want a specific NodeType
// from list screen or for the onLoadActions
export const getGenericNodeType = (node) => getNodeType(node, undefined, null);

export const getNodeTypeConfigByNode = (
  node,
  webPage: WebPage | undefined,
  documentType: NodeType | null
) => {
  return getNodeTypeConfig(getNodeType(node, webPage, documentType));
};

export const getIcon = (nodeType: NodeType) => {
  const nodeTypeInformation =
    nodeTypeConfigurations[nodeType] && nodeTypeConfigurations[nodeType].information;
  if ('icon' in nodeTypeInformation && nodeTypeInformation.icon) {
    return nodeTypeInformation.icon;
  }
  return require('../../../img/icons/document.svg');
};
