import { ContentHref } from '@generalTypes/apiTypes';
import { BuildingBlock, EditComponent, NodeType } from '@nodeTypeConfig/configTypes';

export const ancestorTypeFilter = (
  editComponent: EditComponent | BuildingBlock,
  pathToRoot: ContentHref[],
  allNodeTypesMap: Record<ContentHref, NodeType>
) => {
  const { showInAncestorTypes, hideInAncestorTypes } = editComponent;
  if (!showInAncestorTypes && !hideInAncestorTypes) {
    return true;
  }
  const ancestorTypes = pathToRoot.slice(1).map((href) => allNodeTypesMap[href]);
  if (showInAncestorTypes) {
    const typesInWhichToShow = Object.fromEntries(showInAncestorTypes.map((t) => [t, true]));
    if (!ancestorTypes.some((t) => typesInWhichToShow[t])) {
      return false;
    }
  }
  if (hideInAncestorTypes) {
    const typesInWhichToHide = Object.fromEntries(hideInAncestorTypes.map((t) => [t, true]));
    if (ancestorTypes.some((t) => typesInWhichToHide[t])) {
      return false;
    }
  }
  return true;
};
