import { loadIllustrationsReferencingPracticalExamples } from '@newStore/zill/zillSagas';
import { RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';
import { settings } from '../../settings';

const PRACTICAL_EXAMPLE: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Zill-praktijkvoorbeeld',
    plural: 'Zill-praktijkvoorbeelden',
    icon: require('../../../../img/icons/practicalExample.svg'),
    category: 'ZILL',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['PRACTICAL_EXAMPLE'],
  },
  onLoadActions: [loadIllustrationsReferencingPracticalExamples()],
  relationsToIgnore: ['IS_VERSION_OF', 'IS_INCLUDED_IN', 'REPLACES', 'REFERENCES'],
  createModal: {
    authors: {
      addUser: true,
    },
  },
  tocTypes: [NodeType.SECTION, NodeType.SECTION_NEW],
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.MUSIC },
    { type: NodeType.VIDEO },
    { type: NodeType.VIDEO_GROUP },
    { type: NodeType.QUOTE },
    { type: NodeType.WORD_IMPORT },
  ],
  edit: [
    titleComponent,
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    {
      component: 'ageRange',
      label: 'leeftijd',
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Miniatuur',
      options: {
        type: 'THUMBNAIL',
      },
    },
    {
      component: 'links',
      // component does not work? Should this be the LinkGroup component same as pro?
      label: 'Links',
    },
    {
      component: 'zillIllustrations',
      label: 'Illustraties',
    },
    {
      component: 'sources',
      label: 'Bronnen',
      hiddenChildren: {
        containerType: ContentType.PRACTICAL_EXAMPLE,
        items: [
          {
            type: ContentType.SOURCE,
            label: 'bron',
          },
        ],
      },
    },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  previewModes: [
    {
      type: 'ZILL',
      name: 'Zill Leerplansite',
      location: `${settings.apisAndUrls.zill}/#!/bib/{%key}?preview&version={%version}`,
    },
  ],
};

export default PRACTICAL_EXAMPLE;
