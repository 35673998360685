import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getReplacesRelationsHref } from '../../../reduxLoop/helpers/documentHelpers';
import { getTitle } from '../../../services/types';

class asidePreviousVersion {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
        previousVersionTitle:
          state.document.viewModel.aside && state.document.viewModel.aside.previousVersion
            ? getTitle(state.document.viewModel.aside.previousVersion, { addIdentifier: true })
            : '',
      };
    })(this);

    const replacesRelations = getReplacesRelationsHref(this.editDocument);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadPreviousVersionAction(this.editDocument.key, replacesRelations)
    );
  }

  $onInit() {}
}

export default {
  template: require('./asidePreviousVersion.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asidePreviousVersion,
};
