import { BuildingBlock, EditComponent, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { wwwReferenceFrameKey } from '@store/constants/constants';
import { ContentType } from '@generalTypes/apiTypes';

export const wwwDefaultBuildingBlocks: Array<BuildingBlock> = [
  { type: NodeType.SECTION },
  { type: NodeType.PARAGRAPH },
  { type: NodeType.IMAGE },
  { type: NodeType.IMAGE_GROUP },
  { type: NodeType.ATTACHMENTS_GROUP },
  { type: NodeType.VIDEO },
  { type: NodeType.QUOTE },
  { type: NodeType.WORD_IMPORT },
];

export const wwwTitleAndDescriptionComps: Array<EditComponent> = [
  titleComponent,
  {
    component: 'description',
    label: 'Inleiding',
  },
];

// Question @Greet/Isabelle WWW and WWW_SECTION has cropOptions, is there meant to be a difference or just sloppiness?
export const wwwOmslagAndTegelFotoComps: Array<EditComponent> = [
  {
    component: 'image',
    property: 'attachments',
    width: '6',
    label: 'Omslagfoto',
    options: {
      type: 'COVER_IMAGE',
      fields: [],
    },
  },
  {
    component: 'image',
    property: 'attachments',
    width: '6',
    label: 'Tegelfoto',
    options: {
      type: 'THUMBNAIL',
      fields: ['alt'],
    },
  },
];

export const wwwThemeComp: EditComponent = {
  component: 'themes',
  label: "Thema's",
  valueToString: themeHrefsToString,
  options: { referenceFrameKey: wwwReferenceFrameKey, types: [ContentType.THEME] },
};
