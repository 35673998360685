import { EditComponent } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { dienstverleningKovKey } from '@store/constants/constants';
import { ContentType } from '@generalTypes/apiTypes';
// can also probably be merged with shared db item, probably again slopiness to only do this for db items and not for the other two
export const leerplanSitesThemeComponents: Array<EditComponent> = [
  {
    component: 'themes',
    label: "Leerplanthema's",
    options: {
      referenceFrameKey: dienstverleningKovKey,
      types: [
        ContentType.THEME,
        ContentType.CURRICULUM_THEME,
        'COMMONS_STUDIEGEBIED',
        'COMMONS_SUBJECT',
      ],
      highlightOldCurrItems: true,
    },
    valueToString: themeHrefsToString,
  },
  { component: 'curriculumSelector', label: "Leerplanthema's" },
];
