import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

require('./asideTeaserImportance.scss');

class asideTeaserImportance {
  constructor($scope, $ngRedux, constants) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.importances = constants.teaserImportances;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
      };
    })(this);
  }

  $onInit() {}

  change() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.editDocument.key, {
        importance: this.editDocument.importance.value,
      })
    );
  }
}

export default {
  template: require('./asideTeaserImportance.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideTeaserImportance,
};
