import {
  api,
  cachedApi,
  contentApi,
  namedSetsApi,
  newsletterApi,
  personsApi,
  privateStateApi,
  proposalApi,
  trainingApi,
  websitesApi,
} from '@store/api/apiConfig';

export const getApiForResource = (resource: string) => {
  switch (true) {
    case resource.startsWith('/content'):
      return contentApi;
    case resource.startsWith('/sam'):
    case resource.startsWith('/vakken'):
      return cachedApi;
    case resource.startsWith('/newsletter'):
      return newsletterApi;
    case resource.startsWith('/persons'):
      return personsApi;
    case resource.startsWith('/privatestate'):
      return privateStateApi;
    case resource.startsWith('/namedsets'):
      return namedSetsApi;
    case resource.startsWith('/events'):
    case resource.startsWith('/training'):
      return trainingApi;
    case resource.startsWith('/web'):
      return websitesApi;
    case resource.startsWith('/proposals'):
      return proposalApi;
    default:
      console.warn('No api found for resource', resource, 'using default api');
      return api;
  }
};

export const loadResourceTypeFully = (resource: string) => {
  switch (resource) {
    case '/sam/commons/subjects':
    case '/sam/commons/studyprogrammegroups':
    case '/sam/commons/studiegebieden':
    case '/web/templates':
    case '/web/sites':
      return true;
    default:
      return false;
  }
};

const getParametersForResource = (resource: string) => {
  switch (true) {
    case resource.startsWith('/content'):
      return { expand: 'summary' }; // exclude relations from external content
    default:
      return {};
  }
};

export const doesResourceSupportBatch = (resource: string) => {
  switch (true) {
    case resource.startsWith('/events'):
    case resource.startsWith('/content'):
      return true;
    default:
      return false;
  }
};

export const getAllOfResource = async (resource: string) => {
  const apiForResource = getApiForResource(resource);
  return apiForResource.getAll(resource);
};

// based on loadHrefsCmd
export const getAllHrefsForResource = async (resource: string, hrefs: string[]) => {
  const apiForResource = getApiForResource(resource);
  const options: Partial<{ inBatch: string | undefined }> = {};
  if (doesResourceSupportBatch(resource) && hrefs.length > 50) {
    // the href.length > 50 will complicate mocking api responses, if we have two ways of doing it.
    options.inBatch = `${resource}/batch`;
  }
  const parameters = getParametersForResource(resource);

  return apiForResource.getAllHrefs(hrefs, parameters, options);
};
