import * as documentApiSagas from './documentApi/documentApiSagas';
import * as newDocumentListSagas from './documentList/newDocumentListStateSagas';
import * as externalDataSagas from './externalData/externalDataSagas';
import * as previewSagas from './preview/previewSagas';
import * as referenceFramesSagas from './referenceFrames/referenceFramesSagas';
import * as userSagas from './user/userSagas';
import { zillSaga } from './zill/zillSagas';
import { documentUISaga } from './documentUI/documentUISagas';
import * as validationSaga from './validation/validationSagas';
import { pendingActionsSaga } from './pendingActions/pendingActionsSagas';

export function initSagas(sagaMiddleware) {
  Object.values(newDocumentListSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(documentApiSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(userSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(externalDataSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(referenceFramesSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(previewSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  sagaMiddleware.run(zillSaga);
  sagaMiddleware.run(documentUISaga);
  sagaMiddleware.run(pendingActionsSaga);
  Object.values(validationSaga).forEach(sagaMiddleware.run.bind(sagaMiddleware));
}

export default initSagas;
