import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent, identifierComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ODET_SUBSECTION: BuildingBlockNodeConfig = {
  information: {
    single: 'Subsectie',
    plural: "Subsectie's",
  },
  node: {
    type: ContentType.CURRICULUM_ODET_SUBSECTION,
  },
  buildingBlocks: [
    { type: NodeType.CURRICULUM_ODET_SUBSECTION },
    { type: NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL },
    { type: NodeType.CURRICULUM_ODET_ENDTERM },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
  ],
  isCollapsible: true,
  documentViewIdentifier: 'i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_SUBSECTION;
