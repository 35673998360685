import { extractContentAndRelations } from '@newStore/documentApi/documentApiHelpers';
import { arrayToObjectMap, getResourcePathFromHref } from '@newStore/genericHelpers';
import { IApiResouce } from '@generalTypes/sriTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOAD_PREVIOUS_VERSION } from '@store/constants/actionTypes';
import { ContentWith$$Relations } from '@generalTypes/apiTypes';
import { ExternalDataState, pathMap } from './externalDataTypes';

const initialState: ExternalDataState = {
  resourcesToLoad: [],
  failedResources: [],
  data: {
    [pathMap.persons]: { items: {} },
    [pathMap.ous]: { items: {} },
    [pathMap.vosOus]: { items: {} },
    [pathMap.webSites]: { items: {}, isLoading: false },
    [pathMap.webTemplates]: { items: {}, isLoading: false },
    [pathMap.newsletterSenders]: { items: {}, isLoading: false },
    [pathMap.subjects]: { items: {} },
    [pathMap.studyProgrammes]: { items: {} },
    [pathMap.studyProgrammeGroups]: { items: {} },
    [pathMap.studiegebieden]: { items: {} },
    [pathMap.vakken]: { items: {} },
    [pathMap.events]: { items: {} },
    [pathMap.content]: { items: {} },
    [pathMap.contentRelations]: { items: {} },
    [pathMap.trainingModules]: { items: {} },
    [pathMap.educationalActivityTypes]: { items: {} },
    [pathMap.others]: { items: {} },
  },
  resourceAncestorsToLoad: [],
  failedResourceAncestors: [],
  loadedAncestors: [],
} as const;

export type GetAllOfResourceAction = PayloadAction<{ resource: string; refresh: boolean }>;

const externalDataState = createSlice({
  name: 'externalDataState',
  initialState,
  reducers: {
    getAllOfResource: (state, action: GetAllOfResourceAction) => {
      const { resource, refresh } = action.payload;
      if (!state.data[resource]) {
        console.error('Unknown resource for externalData', resource);
        return;
      }

      if (Object.keys(state.data[resource].items).length === 0) {
        state.data[resource].isLoading = true;
      }

      if (refresh) {
        state.data[resource] = { items: {}, isLoading: true };
      }
    },
    setPersons: (state, action) => {
      const { persons } = action.payload;
      persons.forEach((person) => {
        state.data[pathMap.persons].items[person.$$meta.permalink] = person;
      });
    },
    setOUs: (state, action) => {
      const { ous } = action.payload;
      ous.forEach((ou) => {
        state.data[pathMap.ous].items[ou.$$meta.permalink] = ou;
      });
    },
    setAuthors: (state, action) => {
      const { authors } = action.payload;
      authors.forEach((author) => {
        if (author.$$meta.permalink.startsWith('/persons/')) {
          state.data[pathMap.persons].items[author.$$meta.permalink] = author;
        } else {
          state.data[pathMap.ous].items[author.$$meta.permalink] = author;
        }
      });
    },
    setAllOfResource: (state, action: PayloadAction<{ resource: string; data: any[] }>) => {
      const { resource, data } = action.payload;
      if (state.data[resource]) {
        state.data[resource].items = arrayToObjectMap(data);
        state.data[resource].isLoading = false;
      } else {
        console.error('Unknown resource for externalData', resource);
      }
    },
    setExternalData: (state, action: PayloadAction<{ data: IApiResouce[] }>) => {
      const { data } = action.payload;
      data.forEach((item) => {
        const path = getResourcePathFromHref(item.$$meta.permalink);
        if (path && state.data[path]) {
          state.data[path].items[item.$$meta.permalink] = item;
        } else if (path) {
          state.data.others.items[item.$$meta.permalink] = item;
          console.warn('Unknown resource for externalData', path);
        } else {
          console.error('Unknown path', path, 'for item', item.$$meta.permalink);
        }
      });
    },
    addResourcesToLoad: (state, action: PayloadAction<{ resources: string[] }>) => {
      const { resources } = action.payload;
      state.resourcesToLoad = [...state.resourcesToLoad, ...resources];
    },
    setNotFoundHrefs: (state, action: PayloadAction<{ hrefs: string[] }>) => {
      const { hrefs } = action.payload;
      state.failedResources = [...state.failedResources, ...hrefs];
    },
    addResourceAncestorsToLoad: (state, action: PayloadAction<{ resources: string[] }>) => {
      const { resources } = action.payload;
      state.resourceAncestorsToLoad = [...state.resourceAncestorsToLoad, ...resources];
    },
    setFailedResourceAncestors: (state, action: PayloadAction<{ hrefs: string[] }>) => {
      const { hrefs } = action.payload;
      state.failedResourceAncestors = [...state.failedResourceAncestors, ...hrefs];
      state.resourceAncestorsToLoad = state.resourceAncestorsToLoad.filter(
        (r) => !hrefs.includes(r)
      );
    },
    addResourceAncestors: (
      state,
      action: PayloadAction<{ data: Record<string, ContentWith$$Relations[]> }>
    ) => {
      const { data } = action.payload;

      let allContentMap = {};
      let allRelationsMap = {};

      Object.values(data).forEach((values) => {
        const { content: contentWithoutRelations, relations } = extractContentAndRelations(values);

        const contentMap = arrayToObjectMap(contentWithoutRelations);
        const ancestorRelationsMap = arrayToObjectMap(
          relations.filter(
            (rel) => rel.relationtype === 'IS_PART_OF' || rel.relationtype === 'IS_INCLUDED_IN'
          )
        );

        allContentMap = { ...allContentMap, ...contentMap };
        allRelationsMap = { ...allRelationsMap, ...ancestorRelationsMap };
      });

      state.data[pathMap.content].items = {
        ...state.data[pathMap.content].items,
        ...allContentMap,
      };
      state.data[pathMap.contentRelations].items = {
        ...state.data[pathMap.contentRelations].items,
        ...allRelationsMap,
      };
      const loadedAncestors = new Set(Object.keys(data));
      state.resourceAncestorsToLoad = state.resourceAncestorsToLoad.filter(
        (r) => !loadedAncestors.has(r)
      );
      state.loadedAncestors = [...state.loadedAncestors, ...loadedAncestors];
    },
    expandLlinkidGoalRelations: (state, action: PayloadAction<{ goalKey: string }>) => {},
    loadIncomingReferences: () => {},
  },
  extraReducers: (builder) => {
    // @ts-expect-error old actions aren't liked by TS
    builder.addCase(
      LOAD_PREVIOUS_VERSION,
      (state, action: PayloadAction<{ key: string; hrefs: string[] }>) => {
        const { hrefs } = action.payload;
        state.resourcesToLoad = [...state.resourcesToLoad, ...hrefs];
      }
    );
  },
});

export const {
  setExternalData,
  setPersons,
  setOUs,
  setAuthors,
  getAllOfResource,
  setAllOfResource,
  addResourcesToLoad,
  setNotFoundHrefs,
  addResourceAncestorsToLoad,
  setFailedResourceAncestors,
  addResourceAncestors,
  expandLlinkidGoalRelations,
  loadIncomingReferences,
} = externalDataState.actions;

export default externalDataState;
