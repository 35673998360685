import { publishComponent, titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { loadIncomingReferences } from '@newStore/externalData/externalDataState';
import { ContentType } from '@generalTypes/apiTypes';
import { NodeType, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { settings } from '../../settings';

const TERM: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Begrip',
    plural: 'Begrippen',
    icon: require('../../../../img/icons/term.svg'),
  },
  node: {
    type: ContentType.TERM,
    tags: ['TERM'],
  },
  onLoadActions: [loadIncomingReferences()],
  createModal: {
    authors: {
      addUser: true,
    },
  },
  edit: [
    titleComponent,
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.ERROR,
    },
    publishComponent,
    {
      component: 'image',
      label: 'edit.THUMBNAIL',
      options: {
        type: 'THUMBNAIL',
      },
    },
  ],
  buildingBlocks: [{ type: NodeType.PARAGRAPH }, { type: NodeType.IMAGE }],
  tocTypes: [],
  isCreatable: true,
  isSearchable: true,
  allowSuggestions: false,
  customEditorOptions: {
    hideTermButton: true,
  },
  previewModes: [
    {
      type: 'ZILL',
      name: 'Zill Leerplansite',
      location: `${settings.apisAndUrls.zill}/#!/bib/begrippen/{%key}?preview&version={%version}`,
    },
  ],
};

export default TERM;
