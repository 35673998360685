import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import constants from '../../../reduxLoop/constants/constants';

const isEqual = require('lodash/isEqual');

class asideAuthors {
  constructor($scope, $ngRedux, searchApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.resourcePickerAuthorTemplate = require('../../../screen/optionTemplates/authorOption.html');
    this.authors = { selected: [] };
    this.initialAuthors = [];
    this.searchApi = searchApi;
  }

  $onInit() {
    const component = this;
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const { authors } = state.document.viewModel.aside;
      const differences =
        authors &&
        !isEqual(
          authors.map((s) => s.$$meta.permalink),
          component.initialAuthors.map((s) => s.$$meta.permalink)
        );

      if (differences) {
        component.authors.selected = authors;
        component.initialAuthors = [...authors];
      }

      return {
        creators: {
          ...state.document.viewModel.aside.editDocument.creators,
          key: 'creators',
        },
        authors: component.authors,
        searchParameters:
          this.sConfig.options && this.sConfig.options.queryParams
            ? this.sConfig.options.queryParams
            : constants.defaultAuthorsParameters,
        creatorsFreeText: state.document.viewModel.aside.editDocument.creators
          ? state.document.viewModel.aside.editDocument.creators.value.join('')
          : undefined,
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadDocumentAuthorsAction(this.editKey, this.creators.value)
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(model) {
    const differences = !isEqual(
      this.$parent.ctrl.authors.selected.map((s) => s.$$meta.permalink),
      this.$parent.ctrl.initialAuthors.map((s) => s.$$meta.permalink)
    );

    if (differences) {
      const authors = model.selected.map((s) => s.$$meta.permalink);
      this.$parent.ctrl.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchNodeAction(this.$parent.ctrl.editKey, { creators: authors })
      );
    }
  }

  updateFreeTextAuthor() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.editKey, { creators: [this.creatorsFreeText] })
    );
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return `${resource.firstName} ${resource.lastName}`;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }

      if (resource.$$displayName) {
        return resource.$$displayName;
      }
    }
    return '<NONE>';
  }
}

export default {
  template: require('./asideAuthors.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideAuthors,
};
