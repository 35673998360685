/* eslint-disable max-len */
import { selectExternalGoalOrItem } from '@newStore/llinkid/llinkidSelectors';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class LlinkidGoalRelationsModal {
  constructor($notification, $translate, $ngRedux, $scope, Utils) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.utils = Utils;

    this.realisations = [
      {
        name: $translate.instant('components.llinkid.goalImplements.partially'),
        value: 'PARTIALLY_IMPLEMENTS',
      },
      {
        name: $translate.instant('components.llinkid.goalImplements.completely'),
        value: 'IMPLEMENTS',
      },
    ];
    this.realisation = this.realisations[1];
  }

  $onInit() {
    this.relationOrigin = this.resolve.modalData.relationOrigin ?? 'from';
    this.relationEnd = this.resolve.modalData.relationEnd ?? 'to';
    this.modalTitle =
      this.resolve.modalData.modalTitle || 'components.llinkid.goalRelations.modalTitle';
    this.relation = this.resolve.modalData.edit?.relation;
    this.defaultCurriculumKey = this.resolve.modalData.defaultCurriculumKey;
    this.selectedGoals = this.resolve.modalData.selectedValues;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const curriculums = state.document.llinkidCurriculums.filter(
        (c) => c.key !== this.resolve.modalData.editDocumentRootKey
      );

      curriculums.forEach((c) => {
        c.displayTitle = this.utils.formatCurrDisplayTitle(c);
      });

      let goals = [];
      if (this.selectedCurriculum) {
        goals = curriculums.find((c) => c.key === this.selectedCurriculum.key).$$goals;
        if (this.selectedGoals && goals) {
          goals = goals.filter((goal) => !this.selectedGoals.includes(goal.key));
        }
      }

      if (!this.selectedCurriculum && this.relation?.[this.relationEnd]) {
        const goal = selectExternalGoalOrItem(state, this.relation[this.relationEnd].href);
        const { root } = goal;
        this.selectedCurriculum = curriculums.find((c) => c.key === root.key);
        if (this.selectedCurriculum && !this.selectedCurriculum.$$loadingGoals) {
          this.$ngRedux.dispatch(
            DOCUMENT_ACTIONS.loadLlinkidCurriculumGoalsAction(this.selectedCurriculum.key)
          );
        }
      }
      if (!this.selectedGoal && this.relation) {
        this.selectedGoal = this.relation[this.relationEnd].$$expanded;
      }

      if (curriculums.length && this.defaultCurriculumKey && !this.selectedCurriculum) {
        this.selectedCurriculum = curriculums.find((c) => c.key === this.defaultCurriculumKey);
        this.changedCurriculum();
      }

      return {
        curriculums,
        goals: goals || [],
        loadingGoals: this.selectedCurriculum && this.selectedCurriculum.$$loadingGoals,
        loadingCurr: this.defaultCurriculumKey && curriculums.length === 0,
        selectType: this.resolve.modalData.selectType,
      };
    })(this);

    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.getAllOfTypeAction(
        {
          type: 'LLINKID_CURRICULUM',
          orderBy: 'title',
          limit: 6000,
        },
        {
          stateField: 'llinkidCurriculums',
          removeIssuedHour: true,
        }
      )
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  hasError() {
    if (!this.selectedCurriculum) {
      this.notificationService.error(
        this.translationService.instant('components.llinkid.goalImplements.error.invalidCurriculum')
      );
      return true;
    }

    if (!this.selectedGoal) {
      this.notificationService.error(
        this.translationService.instant('components.llinkid.goalImplements.error.invalidRelation')
      );
      return true;
    }
    if (this.showRealisationChoice && !this.realisation) {
      this.notificationService.error(
        this.translationService.instant(
          'components.llinkid.goalImplements.error.invalidRealisation'
        )
      );
      return true;
    }
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close({ goal: this.selectedGoal, realisation: this.realisation });
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  changedCurriculum() {
    this.selectedGoal = null;
    this.relation = null;
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadLlinkidCurriculumGoalsAction(this.selectedCurriculum.key)
    );
  }
}

export default {
  template: require('./goalRelationsModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: LlinkidGoalRelationsModal,
};
