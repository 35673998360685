import {
  Content,
  ContentHref,
  ContentRelationHref,
  ContentRelationKey,
  IsPartOfRelation,
} from '@generalTypes/apiTypes';
import uuidv4 from 'uuid/v4';
import { nodeTypeConfigurations } from '../../config/nodeTypeConfigurations';

export function areAllResultsSelected(
  results: Content[],
  selected: Record<string, boolean>
): boolean {
  const allResultsSelected = results.every((result) => selected[result.$$meta.permalink] === true);
  return allResultsSelected;
}

export function getSelectedResults<T extends { $$meta: { permalink: string } }>(
  results: T[],
  selected: Record<string, boolean>
): T[] {
  const selectedResults = results.filter((result) => selected[result.$$meta.permalink] === true);
  return selectedResults;
}

function createSubnodeBlocks(block, parentKey) {
  const contentKey = uuidv4();
  const relationKey = uuidv4();

  const defaultChild = {
    key: contentKey,
    type: block.type,
    attachments: [],
    creators: [],
    importance: 'MEDIUM',
    language: 'nl',
  };

  const relation: IsPartOfRelation = {
    $$meta: {
      permalink: `/content/relation/${relationKey}` as ContentRelationHref,
      type: 'RELATION',
    },
    key: relationKey as ContentRelationKey,
    relationtype: 'IS_PART_OF',
    from: {
      href: `/content/${contentKey}` as ContentHref,
    },
    to: {
      href: `/content/${parentKey}` as ContentHref,
    },
    readorder: (block.readorder as number) || undefined,
  };

  // include in batch the new subchild and relation to root
  return [
    {
      verb: 'PUT',
      href: `/content/${defaultChild.key}`,
      body: defaultChild,
    },
    {
      verb: 'PUT',
      href: `/content/relations/${relation.key}`,
      body: relation,
    },
  ];
}

export function createApiPayloads(content) {
  const key = uuidv4();
  const body = {
    $$meta: { permalink: `/content/${key}` as ContentHref },
    key,
    type: content.node.type,
    isNew: true,
    title: content.title,
    readorder: 1,
    attachments: [],
    creators: [],
    importance: 'MEDIUM',
    language: 'nl',
    ...content.node,
    ...content.createDefaults,
  };
  if (content.documentType) {
    body.tags = [...body.tags, content.documentType.value];
  }

  if (content.newsLetterType) {
    body.newsletterType = { href: content.newsLetterType.$$meta.permalink };
  }

  if (content.identifier) {
    body.identifiers = body.identifiers
      ? [...body.identifiers, content.identifier]
      : [content.identifier];
  }

  if (content.themes) {
    body.themes = content.themes;
  }

  body.creators = content.authors.selected.map((author) => author.$$meta.permalink);

  let subnodes = [];
  if (
    (!content.importDocx || !content.importDocx.name) &&
    nodeTypeConfigurations[body.type]?.createChildDefaults
  ) {
    // useful to create default subnodes and add to batch when needed
    subnodes = nodeTypeConfigurations[body.type]?.createChildDefaults.flatMap((block) =>
      createSubnodeBlocks(block, key)
    );
  }

  return [
    {
      verb: 'PUT',
      href: `/content/${key}`,
      body,
    },
    ...(subnodes || []),
  ];
}
