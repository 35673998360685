import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { authorsComponent, publishComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';
import { settings } from '../../settings';
import constants from '../../../reduxLoop/constants/constants';
import { wwwDefaultBuildingBlocks, wwwTitleAndDescriptionComps } from './wwwGenericConfigProps';

export const WWW: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'WWW.-inhoud',
    plural: 'WWW.-inhoud',
    icon: require('../../../../img/icons/webpage.svg'),
    category: 'WWW',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['WWW_CONTENT'],
  },
  onLoadActions: [loadWebConfigurations()],
  createModal: {
    theme: {
      filter: {
        root: '085bc39d-c7a6-4209-bc7d-22962d23724b',
        typeIn: ContentType.THEME,
      },
    },
    authors: {
      addUser: true,
    },
    importDocx: {},
  },
  buildingBlocks: wwwDefaultBuildingBlocks,
  referenceGroupTypes: [
    constants.referenceGroupTypes.WWW_NEWS_ITEM,
    constants.referenceGroupTypes.WWW_CURRENT_THEME,
  ],
  tocTypes: [NodeType.SECTION, NodeType.SECTION_NEW],
  edit: [
    ...wwwTitleAndDescriptionComps,
    {
      component: 'description',
      property: 'shortdescription',
      width: '12',
      label: 'Tegeltekst',
      view: {
        hideIfEmpty: true,
      },
      options: {
        type: 'shortdescription',
      },
    },
    {
      component: 'image',
      property: 'attachments',
      width: '6',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        crop: {
          type: 'rectangle',
        },
        fields: [],
      },
    },
    authorsComponent,
    publishComponent,
    {
      component: 'linkGroup',
      width: '12',
      label: 'Gerelateerd thema on PRO',
      options: {
        type: 'group',
        modalTitle: 'Gerelateerd thema on PRO invoegen',
      },
      hiddenChildren: {
        containerType: ContentType.LINK_GROUP,
        items: [
          {
            type: ContentType.REFERENCE,
            label: 'link',
          },
        ],
      },
    },
  ],
  customEditorOptions: {
    hideTermButton: true,
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
};
