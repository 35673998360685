class asideVersion {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
      };
    })(this);
  }

  $onInit() {}
}

export default {
  template: require('./asideVersion.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideVersion,
};
