import { WebSiteHref, WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import * as ACTION_TYPES from '@store/constants/actionTypes';
import {
  publishComponent,
  titleComponent,
  authorsComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import * as apiRoutes from '../../../../reduxLoop/api/apiRoutes';
import constants from '../../../../reduxLoop/constants/constants';
import { settings } from '../../../settings';
import { themeNotEmpty } from '../../../../validations/themeNotEmpty';
import {
  proDefaultBuildingBlocks,
  proLinksAndMenuReferences,
  proThemeAudienceTab,
} from '../proGenericConfigProps';

export const SHARED_MINI_DATABASE_ITEM: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Gedeeld databankitem',
    plural: 'Gedeelde databankitems',
    category: 'LEERPLANSITES_SO',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_REFERENCE_FRAME,
      payload: { key: constants.contentTypesKey },
    },
  ],
  node: {
    type: ContentType.SHARED_MINI_DATABASE_ITEM,
    tags: ['SHARED_MINI_DATABASE_ITEM'],
  },
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
  ],
  websiteEdition: [
    {
      domain: settings.domains.pro,
    },
  ],
  webconfiguration: {
    type: 'MINI_DATABASE_ITEM',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a' as WebTemplateHref,
    },
  },
  tocTypes: [],
  buildingBlocks: proDefaultBuildingBlocks,
  createModal: {
    authors: {
      addUser: false,
      queryParams: constants.allAuthorsParameters,
    },
  },
  edit: [
    titleComponent,
    {
      component: 'description',
      property: 'description',
      required: RequiredType.WARNING,
      customRequiredMessage:
        'Je moet de <strong>inleiding</strong> invullen. (Belangrijk: de zoeker toont de inleiding in de zoekresultaten)',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Afbeelding',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      options: {
        queryParams: constants.allAuthorsParameters,
      },
    },
    publishComponent,
    {
      component: 'themes',
      label: "Thema's",
      required: RequiredType.WARNING,
      customRequiredMessage:
        'Je moet een <strong>thema</strong> invullen. Anders verschijnt dit item niet in een databank.',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [
          ContentType.THEME,
          ContentType.CURRICULUM_THEME,
          'COMMONS_STUDIEGEBIED',
          'COMMONS_SUBJECT',
        ],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
      validations: [themeNotEmpty], // need to stay, they are used in the aside itself
    },
    { component: 'curriculumSelector', label: "Leerplanthema's" },
    {
      component: 'themes',
      label: 'In welke databanktype(s) moet dit item getoond worden?',
      required: RequiredType.WARNING,
      customRequiredMessage:
        'Je moet een <strong>databanktype</strong> invullen. Anders verschijnt dit item niet in een databank.',
      options: {
        referenceFrameKey: constants.contentTypesKey,
        types: [ContentType.THEME],
      },
    },
    {
      component: 'webFacets',
      label: 'Facetten',
      width: '12',
      options: {
        source: 'themesMatches',
        matchingParams: {
          themesMatchingOnRoot: { referenceFrameKey: constants.dienstverleningKovKey },
          themesOverlaps: { referenceFrameKey: constants.contentTypesKey },
          'webPage.template': '34371fa8-c06b-4ace-83ad-180e5cd0b50b', // MINI_DATABASE
          $$includeWebPages: true,
        },
      },
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    ...proLinksAndMenuReferences,
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  audienceTab: proThemeAudienceTab,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  allowSuggestions: true,
  isImportable: true,
  globalAttachmentsGroupContainer: true,
};
