import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { importanceComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const EXAMPLE: BuildingBlockNodeConfig = {
  information: {
    single: 'Voorbeeld',
    plural: 'Voorbeelden',
  },
  node: {
    type: ContentType.EXAMPLE,
  },
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    {
      type: NodeType.REFERENCE_GROUP,
      showInAncestorTypes: [NodeType.VISION_TEXT],
    },
  ],
  edit: [importanceComponent],
  createInModal: false,
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default EXAMPLE;
