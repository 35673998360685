import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const LLINKID_STATIC_PART: BuildingBlockNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Statisch gedeelte',
    plural: 'Statisch gedeelte',
  },
  node: {
    type: ContentType.LLINKID_STATIC_PART,
  },
  edit: [],
  buildingBlocks: [
    {
      type: NodeType.PARAGRAPH,
      max: 1,
    },
  ],
  showPlaceholder: true,
  readOnlyNode: true,
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_STATIC_PART;
