import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { BatchItem } from '@generalTypes/sriTypes';
import {
  selectApiWithPendingChanges,
  selectWebPage,
} from '@newStore/documentApi/documentApiSelectors';
import {
  AsyncValidationRule,
  ValidationError,
  ValidationResult,
  ValidationRuleName,
} from '@newStore/validation/validationTypes';
import { getNodeType, getNodeTypeConfig } from '@nodeTypeConfig/index';
import { createTypedSelector } from '@newStore/genericHelpers';
import { isEqual } from 'lodash';
import { wrapNodeTypeLabel } from '@newStore/documentUI/transformProposal/proposalHelpers';
import { VALIDATION_PENDING } from '../validationHelpers';

const selectValidationData = (state: RootState, href: ContentHref) =>
  state.validationData[href]?.noDuplicateWebPaths?.data;

const selectValidation = createTypedSelector(
  [
    (state, href: ContentHref) => selectWebPage(state, href),
    (state, href: ContentHref) => selectValidationData(state, href),
  ],
  (webPage, validationData): ValidationResult => {
    if (!webPage || webPage.$$meta.deleted) {
      return true;
    }

    if (!validationData) {
      return VALIDATION_PENDING;
    }

    const duplicate = validationData.find((cont) => {
      const [webItem] = cont.$$webPages;
      const web = webItem.$$expanded;
      return (
        (web.website.href === webPage.website.href && web.path === webPage.path) ||
        (web.oldLocations &&
          web.oldLocations.some(
            (l) => l.website.href === webPage.website.href && l.path === webPage.path
          ))
      );
    });

    if (duplicate) {
      const [webItem] = duplicate.$$webPages;
      const web = webItem.$$expanded;
      const duplicateDocType = getNodeType(duplicate, web, null);
      const nodeTypeLabel = getNodeTypeConfig(duplicateDocType).information.single.split(':')[0];
      const error: ValidationError = {
        code: 'duplicateWebPath',
        rule: 'noDuplicateWebPaths',
        type: 'ERROR',
        message: `Pad <strong>${
          web.path
        }</strong> wordt al gebruikt bij ${wrapNodeTypeLabel`${nodeTypeLabel}`}: "${[
          ...duplicate.$$pathToRoot,
        ]
          .reverse()
          .join(' > ')}".<br /> Kies een ander <strong>pad</strong>`,
        property: 'webPage.path',
      };
      return error;
    }

    return true;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const noDuplicateWebPaths: AsyncValidationRule = {
  getName: (): ValidationRuleName => 'noDuplicateWebPaths',
  isAsync: true,
  selectNeedsData: (state, href) => {
    return selectValidationData(state, href) === undefined;
  },
  selectInvalidateDataOnChangeOf: (state, href) => {
    const webPages = selectApiWithPendingChanges(state)?.webPages;
    const webpage = webPages.find((w) => w.source.href === href);
    return webpage?.path;
  },
  selectDataBatch: createTypedSelector(
    [(state) => selectApiWithPendingChanges(state)?.webPages, (state, href) => href],
    (webPages, href) => {
      const webPagesForNode = webPages.filter((w) => w.source.href === href);
      const paths = webPagesForNode
        .reduce((accPaths, w) => {
          const oldLocations = w.oldLocations || [];
          return [...accPaths, w.path, ...oldLocations.map((l) => l.path)];
        }, [] as string[])
        .join(',');

      if (!paths.length) {
        return null;
      }
      const sourceKey = href.split('/').pop();

      const batchItem: BatchItem<'GET'> = {
        verb: 'GET',
        href: `/content?limit=5000&webPageAnyPath=${paths}&$$expandPathToRoot=true&$$includeWebPages=true&keyNot=${sourceKey}`,
      };
      return batchItem;
    }
    // {
    //   memoizeOptions: {
    //     resultEqualityCheck: isEqual,
    //   },
    // }
  ),
  selectValidation,
};
