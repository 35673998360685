import { Content, ContentHref, isVideoAttachmentMetaInfo } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { getEmbedVideoLinkFrom } from '@store/helpers/documentHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { VideoGroupNode, VideoNode } from '../documentUITypes';
import { selectProposedContentHrefsToDelete } from '../transformProposal/proposalSelectors';

export const selectVideoGroupAttachments = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectProposedContentHrefsToDelete(state),
    (state, href: ContentHref) => href,
  ],
  (relationsMap, content, proposedHrefsToDelete, href) =>
    relationsMap.to[href].map((child) => {
      const videoChild = content[child.from.href];
      const playbackUrl = videoChild.attachments.find(isVideoAttachmentMetaInfo)?.playbackUrl;
      return {
        key: videoChild.key,
        isDeletedByProposal: proposedHrefsToDelete.includes(videoChild.$$meta.permalink),
        videoSrc: playbackUrl && getEmbedVideoLinkFrom(playbackUrl),
      };
    })
);

export const selectVideoNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): VideoNode => {
    const playbackUrl = content.attachments?.find(isVideoAttachmentMetaInfo)?.playbackUrl;
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type: NodeType.VIDEO,
      videoSrc: playbackUrl && getEmbedVideoLinkFrom(playbackUrl),
      customRender: true,
    };
  }
);

export const selectVideoGroupNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectVideoGroupAttachments(state, href),
  ],
  (content: Content, videos): VideoGroupNode => {
    return {
      href: content.$$meta.permalink,
      key: content.key,
      description: content?.description || '',
      type: NodeType.VIDEO_GROUP,
      videos,
      customRender: true,
    };
  }
);
