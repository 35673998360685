import * as ACTION_TYPES from '@store/constants/actionTypes';
import { proThemeAudienceTab } from '@nodeTypeConfig/PRO/proGenericConfigProps';
import { RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
// import { globalDocumentDeleteValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import { getAccessRightsToStringFunction } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import { identifierComponent } from '../helpers/genericComponents';

const GLOBAL_DOCUMENT: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Gedeeld document',
    plural: 'Gedeelde documenten',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_IS_INCLUDED_IN_PRO_THEME,
    },
  ],
  node: {
    type: ContentType.UNSTRUCTURED_DOCUMENT,
    tags: ['PRO_UNSTRUCTURED_DOCUMENT'],
  },
  createDefaults: { accessRights: accessRights.publiek.namedsets },
  buildingBlocks: [],
  tocTypes: [],
  createModal: {
    identifier: {},
    attachmentDocumentType: { required: true },
    authors: { addUser: true },
    uploadAttachment: { required: true },
  },
  edit: [
    {
      component: 'title',
      label: 'Titel',
      required: RequiredType.ERROR,
      width: '10',
    },
    {
      ...identifierComponent,
      width: '2',
      label: 'Referentienummer',
    },
    {
      component: 'description',
      label: 'Inleiding / korte inhoud',
      required: RequiredType.WARNING,
    },
    {
      component: 'authors',
      label: 'Auteurs',
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: { field: 'accessRights', readOnly: false },
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: {
    property: 'accessRights',
    valueToString: getAccessRightsToStringFunction('accessRights'),
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
};

export default GLOBAL_DOCUMENT;
