import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proTileStandardComponents,
  proThemeAudienceTab,
  proThemeTocTypes,
  proAliasesComponent,
  proPageAttachmentsComponents as proPageAttachmentsComponent,
} from '../proGenericConfigProps';

export const proTextPageConfig = {
  information: {
    description:
      'Pro textPage (webTemplate code THEME_PAGE [not a very good name]) can be a SECTION (a tile) in a home or can be the root (single page pro theme)',
    single: 'PRO.-tegel: Tekstpagina',
    plural: "Tekstpagin's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  webTemplateHref: '/web/templates/3e09d09a-11b6-4bfe-a20d-62223633b09b' as WebTemplateHref,
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig THEME_PAGE becomes a PRO_TEXT_PAGE_TILE
export const PRO_TEXT_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proTextPageConfig,
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents,
    proAliasesComponent,
    proPageAttachmentsComponent,
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding THEME_PAGE WebConfig to a WEBPAGE2 it becomes a PRO_TEXT_PAGE_STANDALONE
export const PRO_TEXT_PAGE_STANDALONE: ExtendedRootNodeConfig = {
  ...proTextPageConfig,
  information: {
    ...proTextPageConfig.information,
    definiteArticle: true,
    single: 'PRO.-thema: Tekstpagina',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [
    ...proDocumentStandardComponents,
    proAliasesComponent,
    proPageAttachmentsComponent,
    ...proLinksAndMenuReferences,
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
