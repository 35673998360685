import { createTypedSelector } from '@newStore/genericHelpers';
import { orderBy } from 'lodash';

import { RootState } from '@generalTypes/rootStateTypes';
import { selectNewsletterSendersArray } from '@newStore/externalData/externalDataSelectors';

export const selectIsNewsletterSenderComponentLoading = (state: RootState) =>
  !state.document.viewModel.aside.editDocument.key ||
  selectNewsletterSendersArray(state).length === 0;

export const selectNewsletterSenderOptions = createTypedSelector(
  [(state) => selectNewsletterSendersArray(state)],
  (newsletterSenders) => {
    return orderBy(newsletterSenders, 'department').map((sender) => ({
      key: sender.key,
      href: sender.$$meta.permalink,
      display: `${sender.department} - ${sender.name} <${sender.email}>`,
    }));
  }
);

export const selectSelectedNewsletterSender = createTypedSelector(
  [
    (state: RootState) => state.document.viewModel.aside.editDocument.newsletterSender?.value.href,
    selectNewsletterSenderOptions,
  ],
  (selectedSenderHref, senderOptions) => senderOptions.find((o) => o.href === selectedSenderHref)
);
