const isInconsistentMainstructuresOuTypeCombinations = (
  node,
  inheritedMainstructuresOuTypeCombinations
) => {
  if (inheritedMainstructuresOuTypeCombinations && node.mainstructuresOuTypeCombinations) {
    if (
      new Set(node.mainstructuresOuTypeCombinations).size >
      new Set(inheritedMainstructuresOuTypeCombinations).size
    ) {
      return true;
    }

    const missingChildMainstructuresOuTypeCombinations =
      !node.mainstructuresOuTypeCombinations.every((child) => {
        return inheritedMainstructuresOuTypeCombinations.includes(child);
      });

    return missingChildMainstructuresOuTypeCombinations;
  }
  return false;
};

const getInheritedMainstructuresOuTypeCombinations = (node) => {
  function getOwnOrInheritedMainstructuresOuTypeCombinations(n) {
    if (n.mainstructuresOuTypeCombinations) {
      return n.mainstructuresOuTypeCombinations;
    }
    return n.$$parent ? getOwnOrInheritedMainstructuresOuTypeCombinations(n.$$parent) : undefined;
  }
  return node.$$parent
    ? getOwnOrInheritedMainstructuresOuTypeCombinations(node.$$parent)
    : undefined;
};

export const mainstructuresOuTypeCombinationsInconsistent = (n) => {
  return !isInconsistentMainstructuresOuTypeCombinations(
    n,
    getInheritedMainstructuresOuTypeCombinations(n)
  )
    ? true
    : {
        type: 'WARNING',
        message: 'components.mainstructuresOuTypeCombinations.error.inconsistent',
      };
};
