import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ZILL_LEERLIJN_CLUSTER: BuildingBlockNodeConfig = {
  information: {
    single: 'Stap',
    plural: 'Stappen ',
  },
  node: {
    type: ContentType.CURRICULUM_ZILL_LEERLIJN_CLUSTER,
  },
  edit: [
    {
      component: 'ageRange',
      label: 'Leeftijd',
      options: {
        editMonth: true,
      },
    },
    {
      component: 'developmentPhase',
      label: 'Ontwikkelingsfase',
      showInAncestorTypes: [NodeType.CURRICULUM_ZILL_LEERLIJN_AN],
      whitelist: [
        {
          parent: {
            specificForAN: true,
          },
        },
      ],
    },
    { component: 'description', label: 'Omschrijving' },
    { component: 'concordanties', label: 'Concordanties' },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default CURRICULUM_ZILL_LEERLIJN_CLUSTER;
