import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proTileStandardComponents,
  proThemeTocTypes,
} from '../proGenericConfigProps';

const proDownloadPage = {
  information: {
    definiteArticle: true,
    description:
      'Pro Downloadlijst (webTemplate code DOWNLOAD_PAGE) can be a SECTION (a tile) in a home or can be the root (single page pro theme, although only two standalone download pages in total...)',
    single: 'PRO.-tegel: Downloadlijst',
    plural: 'Downloadlijsten',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  webTemplateHref: '/web/templates/6a090cca-c380-4248-8722-2951431018a9' as WebTemplateHref,
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig DOWNLOAD_PAGE becomes a PRO_DOWNLOAD_PAGE_TILE
export const PRO_DOWNLOAD_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proDownloadPage,
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents.filter(
      (comp) => comp.component !== 'subject' && comp.property !== 'description'
    ),
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding DOWNLOAD_PAGE WebConfig to a WEBPAGE2 it becomes a PRO_DOWNLOAD_PAGE_STANDALONE
export const PRO_DOWNLOAD_PAGE_STANDALONE: ExtendedRootNodeConfig = {
  ...proDownloadPage,
  information: {
    ...proDownloadPage.information,
    single: 'PRO.-thema: Downloadlijst',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [...proDocumentStandardComponents, ...proLinksAndMenuReferences],
  addAccessRightsToAudienceTab: true,
};
