/* eslint-disable @typescript-eslint/no-explicit-any */
import { createNextState as produce } from '@reduxjs/toolkit';
import jsonPatch from 'json-patch';
import { cloneDeep } from 'lodash';
import {
  Content,
  ContentAttachmentHref,
  ContentRelation,
  Proposal,
  RequestedChange,
  ResourceType,
  WebPage,
  isContentAttachmentMetaInfo,
} from '@generalTypes/apiTypes';
import { getRelatedContentHref } from '../../reduxLoop/helpers/documentStateHelpers';
import { getResourceTypeFromHref } from '../genericHelpers';
import { ResourceMap } from './documentApiTypes';

const add$$metaSection = (resource: any, href: string, resourceType: ResourceType): any => {
  return {
    $$meta: {
      permalink: href,
      type: resourceType,
    },
    ...resource,
  };
};

/**
 * There is a legacy of "corrupt" proposals with patches which do not make sense
 * When refactering this method from the old code, I was not sure if this data is still encountered.
 * So rather than adding all these fallback ifs to correct this corrupt data, I moved it to a seperate function which warns when it would still do this
 * This way we can see if we still encounter these corrupt data and hopefully we can just delete this function
 * But actually the correct way to do this is see in Content Api if the data is still there and referenced and clean up Content Api data
 * @param { RequestedChange } change the change/patch
 * @param {Proposal} proposal the proposal
 * @param resource the resource
 */
const warnForAbnormalitiesInPatches = (
  change: RequestedChange,
  proposal: Proposal,
  resource: any
) => {
  // be sure the value is set in every patch item to avoid Invalid Patch error
  if (change.type !== 'PATCH') {
    return;
  }
  const patchesWithoutValue = change.patch
    .filter((p) => p.path !== '/$$new')
    .filter((p) => !p.value);
  if (patchesWithoutValue.length > 0) {
    console.warn(
      `[apiWithPendingChanges] There are patches without a value! for proposal ${proposal.$$meta.permalink}`,
      patchesWithoutValue
    );
  }
  /* change.patch.forEach(item => {
    if (!('value' in item)) {
      item.value = undefined;
    }
  }); */

  // ensure all fields with op 'replace' in the propsal patch actually exists in node
  const pathchesThatPointToNonExistingFields = change.patch
    .filter((p) => p.path !== '/$$new')
    .filter((p) => p.op === 'replace' && !resource[p.path.slice(1)]);
  if (pathchesThatPointToNonExistingFields.length > 0) {
    console.warn(
      `[apiWithPendingChanges] There are patches that change properties that do not exist on the node ${proposal.$$meta.permalink}`,
      pathchesThatPointToNonExistingFields
    );
  }
  /* change.patch
    .filter(p => p.path !== '$$new')// Ignore this nonsense which is now there. TODO remove when refactor is done and this is no longer created
    .forEach(p => {
      if (p.op === 'replace' && !resource[p.path.slice(1)]) {
        resource[p.path.slice(1)] = '';
      }
    }); */
};

export const applyProposals = (proposals: Proposal[], resourceMap: ResourceMap): ResourceMap => {
  const newResourceMap = produce(resourceMap, (draft) => {
    proposals.forEach((proposal) => {
      proposal.listOfRequestedChanges.forEach((change) => {
        if (change.type === 'UPLOAD') {
          const contentResource = draft.CONTENT[change.relatedTo.href];
          contentResource.attachments = [
            ...contentResource.attachments.filter(
              (a) => a.key !== (change.attachment.key || change.metadata.key)
            ),
            {
              ...change.attachment,
              ...change.metadata,
              href:
                !change.attachment.href || change.attachment.href.indexOf('/content') !== -1
                  ? (`/proposals/${proposal.key}/attachments/${change.attachment.name}` as ContentAttachmentHref)
                  : change.attachment.href,
            },
          ];
          return;
        }
        if (change.type === 'DELETE_UPLOAD') {
          const contentResource = draft.CONTENT[change.relatedTo.href];
          const attachmentKey = change.appliesTo.href.split('/').pop();
          const attachment = contentResource.attachments.find((a) => a.key === attachmentKey);
          // console.log('delete upload for', JSON.parse(JSON.stringify(contentResource)));
          if (!attachment) {
            return;
          }

          // attempt to keep deleted attachments there in order to show them as an indicated deleted proposal (just like content)
          // this did not work yet so in filter below they are just filtered out.
          // we will see if we take over the proposal bit how we will take this further (maybe with just an isolated selector to visualise deleted proposals)
          // if (!attachment.$$meta) {
          //   attachment.$$meta = {};
          //   // does this occur??? maybe we can remove this if and make $$meta required in types
          //   debugger;
          // }
          // attachment.$$meta.deleted = true;

          contentResource.attachments = contentResource.attachments.filter(
            (a) => a.key !== attachmentKey
          );
          return;
        }
        const resourceType = getResourceTypeFromHref(change.appliesTo.href);
        if (change.type === 'CREATE') {
          delete change.resource.$$parent; // delete because of immer complaining about circular structure
          delete change.resource.$$root;
          delete change.resource.$$children;
          delete change.resource.$$new; // delete because $$new triggers a new dispatch of the patchNodeAction
          delete change.resource.$$typeConfig;
          if (change.resource.from) {
            delete change.resource.from.$$expanded;
          }
          if (change.resource.to) {
            delete change.resource.to.$$expanded;
          }
          const newResource = add$$metaSection(
            change.resource,
            change.appliesTo.href,
            resourceType
          );
          // the cloneDeep is needed because we might be adding locked objects into our draft, which can't be mutated in later steps (patch or delete)
          draft[resourceType][change.appliesTo.href] = cloneDeep(newResource);
        } else if (change.type === 'PATCH') {
          const resource = draft[resourceType][change.appliesTo.href];
          if (!resource) {
            console.warn(
              `[apiWithPendingChanges] ${proposal.$$meta.permalink} refers to ${change.appliesTo.href} which is not part of the document!`
            );
            return;
          }
          const patchedResource = resource;
          warnForAbnormalitiesInPatches(change, proposal, patchedResource);

          const fixedPatches = change.patch
            .filter((p) => p.path !== '/$$new')
            .map((item) => {
              if (!('value' in item)) {
                // @ts-expect-error the value should always be present, but apparently, sometimes, it's not. remove this once proposals are fixed
                return { ...item, value: undefined };
              }
              return item;
            })
            .map((item) => {
              if (item.value && item.value.$$expanded) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { $$expanded, ...value } = item.value; // sometimes a proposal value comes with a $$expanded property, which we don't need.
                return { ...item, value };
              }
              return item;
            });

          // TODO remove $$new filter after refactor
          jsonPatch.apply(patchedResource, fixedPatches);
          draft[resourceType][change.appliesTo.href] = patchedResource;
        } else if (change.type === 'DELETE') {
          const resource = draft[resourceType][change.appliesTo.href];
          if (resource) {
            resource.$$meta.deleted = true;
            resource.deleteProposal = true; // the OLD API uses this to show a delete-mark in the UI
          }
        }
      });
    });
  });
  return newResourceMap;
};

export const update$$html = (resourceMap: ResourceMap): ResourceMap => {
  const newResourceMap = produce(resourceMap, (draft) => {
    Object.entries(resourceMap.CONTENT).forEach(([key, content]) => {
      const textAttachment = content.attachments?.find(isContentAttachmentMetaInfo);
      draft.CONTENT[key].$$html = textAttachment?.text ?? content.$$html;
    });
  });
  return newResourceMap;
};

export type PendingAction<T> = {
  verb: 'PUT' | 'DELETE';
  href: string;
  body: T;
};
// why fileUploads, fileDeletes, how does this work? would you not have to apply this stuff on attachments array Content??
// what is externalContent?
export type ApiPending = {
  content: PendingAction<Content>[];
  relations: PendingAction<ContentRelation>[];
  webpages: PendingAction<WebPage>[];
  proposals: PendingAction<Proposal>[];
  fileUploads: PendingAction<any>[];
  fileDeletes: PendingAction<any>[];
};

/**
 * Update an api data structure with user changes in apiPending.
 * (api cache after document is saved or apiWithPendingChanges when updating it for the view model)
 */
export const applyApiPending = (
  resourceMap: ResourceMap,
  proposals: Record<string, Proposal>,
  apiPending: ApiPending
) => {
  const newProposals = { ...proposals };
  Object.keys(apiPending).forEach((apiPendingKey) => {
    apiPending[apiPendingKey].forEach((pendingAction) => {
      if (
        pendingAction.href.match(
          /\/content\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/attachments\/.*$/g
        )
      ) {
        return; // skip attachments, they do not need to progress past apiPending
      }
      let resourceType = getResourceTypeFromHref(pendingAction.href);
      if (apiPendingKey === 'externalContent') {
        // special case of adding external resources that behaves like content resources
        // Matthias: "What???"
        resourceType = 'CONTENT';
        // stefan: This happens when you drag a basisoptie/studierichting/vak/... into the reference frame document!
        // they are added via selectApiContentWithExternalItemsAsFakeInternalContent
        // throw new Error(`externalContent was used: ${JSON.stringify(pendingAction.body)}`);
      }
      switch (pendingAction.verb) {
        case 'PUT': {
          // there was a param updateProposalAttHrefs which was true from saved, so let's see what to do when we work on the new save
          // proposal attachments: update /proposal hrefs to /content hrefs
          /* if (updateProposalAttHrefs && pendingResource.body.attachments) {
            pendingResource.body.attachments.forEach((att) => {
              if (att.href && att.href.startsWith('/proposals') && att.relatedTo) {
                att.href = `${att.relatedTo.href}/attachments/${att.name}`;
              }
            });
          } */
          // pending it's a modification then update the current resource
          if (resourceType === 'CONTENT_PROPOSAL') {
            // const hrefForPropoasal = getRelatedContentHref(
            //   pendingResource.body,
            //   pendingResource.href
            // );
            // const resourceTypeForProposal = getResourceTypeFromHref(hrefForPropoasal);
            // if (resourceTypeForProposal !== 'PROPOSAL') {
            //   resourceMap[resourceTypeForProposal][hrefForPropoasal] = pendingResource.body;
            // } else {
            //   throw new Error('Is this still possible? I think we can delete this else');
            newProposals[pendingAction.href] = { ...pendingAction.body };
            // }
          } else {
            const changedResourcePermalink = getRelatedContentHref(
              pendingAction.body,
              pendingAction.href
            );
            const changedResource = pendingAction.body.$$meta
              ? cloneDeep(pendingAction.body)
              : add$$metaSection(
                  cloneDeep(pendingAction.body),
                  changedResourcePermalink,
                  resourceType
                );
            resourceMap[resourceType][changedResourcePermalink] = changedResource;
          }
          break;
        }

        case 'DELETE': {
          if (resourceType === 'CONTENT_PROPOSAL') {
            // const hrefForPropoasal = getRelatedContentHref(
            //   pendingResource.body,
            //   pendingResource.href
            // );
            // const resourceTypeForProposal = getResourceTypeFromHref(hrefForPropoasal);
            // if (resourceTypeForProposal !== 'PROPOSAL') {
            //   delete resourceMap[resourceTypeForProposal][hrefForPropoasal];
            // } else {
            //   throw new Error('Is this still possible? I think we can delete this else');
            delete newProposals[pendingAction.href];
            // }
          }
          if (resourceMap[resourceType]) {
            delete resourceMap[resourceType][getRelatedContentHref(pendingAction)];
          }
          break;
        }

        default: {
          break;
        }
      }
    });
  });

  return newProposals;
};

// export const selectFromRelations = createSelector(
//   selectApiWithPendingChanges,
//   (apiWithPendingChanges): Record<string, ContentRelation[]> => {
//     const objMap = {};
//     apiWithPendingChanges.relations.forEach((rel) => {
//       const contentHref = rel.from.href;
//       if (!objMap[contentHref]) {
//         objMap[contentHref] = [rel];
//       } else {
//         objMap[contentHref].push(rel);
//       }
//     });
//     return objMap;
//   }
// );

// export const selectToRelations = createSelector(
//   selectApiWithPendingChanges,
//   (apiWithPendingChanges): Record<string, ContentRelation[]> => {
//     const objMap = {};
//     apiWithPendingChanges.relations.forEach((rel) => {
//       const contentHref = rel.to.href;
//       if (!objMap[contentHref]) {
//         objMap[contentHref] = [rel];
//       } else {
//         objMap[contentHref].push(rel);
//       }
//     });
//     return objMap;
//   }
// );
