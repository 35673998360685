import { ContentType } from '@generalTypes/apiTypes';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { menuItemWithReference } from '../../../deleteConfirmations/menuItemWithReference';
import * as apiRoutes from '../../../reduxLoop/api/apiRoutes';
import constants from '../../../reduxLoop/constants/constants';
import { documentTags } from '../../../reduxLoop/constants/documentTags';
import { currentUserAsCreators } from '../../../services/currentUserAsCreators';
import { settings } from '../../settings';

const { isGlobalDocumentAllowed } = require('../../../validations/isGlobalDocumentAllowed');

const GLOBAL_TRAININGSDATABASE = 'GLOBAL_TRAININGSDATABASE';

export default {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../img/buildingBlocks/SECTION.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  // conditional fields to parent webconfiguration
  isCollapsedByDefault: false,
  nodeConditional: [
    // not a very good name, this should be refactored to onCreateActions with actions that should be thrown on create of this building block
    {
      field: 'creators',
      value: currentUserAsCreators,
      condition: {
        parent: ['BLOG'],
      },
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'BLOG_ITEM',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`,
        },
        template: {
          href: '/web/templates/35213fa8-b26b-7ace-92ad-180e5cd0a37c',
        },
      },
      condition: {
        parent: ['BLOG'],
      },
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'MINI_DATABASE_ITEM',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`,
        },
        template: {
          href: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a',
        },
      },
      condition: {
        parent: ['MINI_DATABASE'],
      },
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'THEME_PAGE',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`,
        },
        template: {
          href: '/web/templates/3e09d09a-11b6-4bfe-a20d-62223633b09b',
        },
      },
      condition: {
        parent: ['THEME_HOME_FULL', 'THEME_HOME_PICTURE', 'THEME_HOME_TEXT'],
      },
    },
  ],
  buildingBlocks: [
    {
      type: ContentType.SECTION,
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    ContentType.PARAGRAPH,
    ContentType.IMAGE,
    {
      type: ContentType.ATTACHMENTS_GROUP,
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      type: ContentType.IMAGE_GROUP,
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      type: ContentType.MUSIC,
      blacklist: [
        { root: { $$type: NodeType.WEBPAGE2 } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
        { root: { $$type: NodeType.WWW } },
        { root: { $$type: NodeType.SHARED_MINI_DATABASE_ITEM } },
      ],
    },
    {
      type: ContentType.VIDEO,
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      type: ContentType.VIDEO_GROUP,
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      type: ContentType.QUOTE,
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      type: NodeType.WORD_IMPORT,
      options: {
        openImportModal: true,
      },
    },
    {
      type: 'EXAMPLE',
      whitelist: [{ root: { $$type: NodeType.VISION_TEXT } }],
    },
    {
      type: 'EXAMPLE',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }, { root: { $$type: NodeType.WWW } }],
    },
    {
      type: NodeType.REFERENCE_GROUP,
      whitelist: [
        { root: { $$type: NodeType.VISION_TEXT } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      type: NodeType.REFERENCE_GROUP,
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }, { root: { $$type: NodeType.WWW } }],
    },
    {
      type: ContentType.FAQ_GROUP,
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }, { root: { $$type: NodeType.WWW } }],
    },
    {
      type: 'SUMMARY',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }, { root: { $$type: NodeType.WWW } }],
    },
    {
      type: 'LEGAL',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }, { root: { $$type: NodeType.WWW } }],
    },
    {
      type: 'TEASER',
      whitelist: [{ root: { $$type: NodeType.PRONEWSLETTER } }],
    },
  ],
  edit: [
    {
      component: 'identifier',
      property: 'identifiers',
      width: '2',
      label: 'edit.prefix',
      view: {
        hideIfEmpty: true,
        dd: 'te',
      },
      blacklist: [
        {
          webconfiguration: {
            inherited: ['MINI_DATABASE_ITEM', GLOBAL_TRAININGSDATABASE],
          },
        },
      ],
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
    },
    {
      component: 'title',
      width: '10',
    },
    {
      component: 'description',
      width: '12',
      label: 'Tegeltekst',
      view: { hideIfEmpty: true },
      maxLength: 60,
      // maxAmountOfCharacters: 60,
      // validations: [shortDescriptionNotEmpty],
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: { parent: ['THEME_HOME_FULL', 'THEME_HOME_TEXT'] },
        },
      ],
      options: { type: 'shortdescription' },
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      view: { hideIfEmpty: true },
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: { self: ['THEME_PAGE', 'MINI_DATABASE_ITEM', 'TEMPORARY_PAGE'] },
        },
      ],
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      view: { hideIfEmpty: true },
      // validations: [descriptionEmpty],
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: { self: ['DOWNLOAD_PAGE', 'FAQ_PAGE'] },
        },
      ],
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      view: { hideIfEmpty: true },
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: {
            parent: ['THEME_HOME_TEXT', 'THEME_HOME_PICTURE', 'THEME_HOME_FULL'],
          },
        },
      ],
      blacklist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: {
            self: [
              'THEME_PAGE',
              'MINI_DATABASE_ITEM',
              'TEMPORARY_PAGE',
              'DOWNLOAD_PAGE',
              'FAQ_PAGE',
            ],
          },
        },
      ],
    },
    {
      component: 'importance',
      width: '6',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'MINI_DATABASE_ITEM',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'authors',
      width: '6',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              'MINI_DATABASE_ITEM',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'contacts',
      width: '12',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              'MINI_DATABASE_ITEM',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: {
            parent: ['THEME_HOME_FULL', 'THEME_HOME_PICTURE'],
          },
        },
      ],
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      component: 'image',
      width: '12',
      label: 'Afbeelding',
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: {
            self: ['MINI_DATABASE_ITEM', 'BLOG_ITEM'],
          },
        },
      ],
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      component: 'authors',
      width: '12',
      label: 'Auteur(s)',
      whitelist: [
        {
          root: { $$type: NodeType.WEBPAGE2 },
          webconfiguration: {
            self: ['MINI_DATABASE_ITEM'],
          },
        },
      ],
    },
    {
      component: 'linkGroup',
      width: '12',
      label: 'Links',
      options: {
        type: 'group',
      },
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'themes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      component: 'curriculumSelector',
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== ContentType.CURRICULUM_THEME ||
            !theme.$$children.some((c) => c.type === ContentType.CURRICULUM_THEME)
          );
        },
      },
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      validations: [isGlobalDocumentAllowed],
      blacklist: [
        { webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'] } },
      ],
    },
    {
      component: 'issued',
      whitelist: [
        {
          webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'] },
        },
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'color',
      whitelist: [{ webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2'] } }],
    },
    {
      component: 'image',
      label: 'edit.ICON',
      width: '6',
      options: { type: 'ICON', fields: [] },
      whitelist: [{ webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2'] } }],
    },
    {
      component: 'subjects',
      options: {
        rawLabel: true,
      },
      whitelist: [
        {
          webconfiguration: {
            self: [
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              'BLOG',
              'MINI_DATABASE',
              'THEME_PAGE',
              'THEME_HOME_FULL',
              'THEME_HOME_PICTURE',
              'THEME_HOME_TEXT',
            ],
          },
        },
      ],
    },
    {
      component: 'themes',
      whitelist: [
        { webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'] } },
      ],
    },
    {
      component: 'themes',
      label: 'Databanktype',
      options: {
        referenceFrameKey: constants.contentTypesKey,
        types: [ContentType.THEME],
      },
      whitelist: [
        {
          webconfiguration: {
            self: ['MINI_DATABASE'],
          },
        },
      ],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: ContentType.STRUCTURED_DOCUMENT,
          rootTag: [documentTags.webPage2],
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
      whitelist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'],
          },
        },
      ],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [documentTags.webPage2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [documentTags.webPage2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
        externalLinkHidden: true,
      },
      whitelist: [{ webconfiguration: { inherited: [GLOBAL_TRAININGSDATABASE] } }],
    },
    {
      component: 'image',
      label: 'Ikoon',
      property: 'attachments',
      options: {
        type: 'THUMBNAIL',
        fields: [],
      },
      whitelist: [{ parent: { $$type: NodeType.VISION_TEXT } }],
    },
    {
      component: 'webFacets',
      width: '12',
      options: { source: 'parent' },
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
      whitelist: [
        { root: { $$type: NodeType.WEBPAGE2 } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      component: 'coverage',
      tab: constants.asideTabs.AUDIENCE,
      whitelist: [
        { root: { $$type: NodeType.WEBPAGE2 } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
    {
      component: 'namedSet',
      label: 'Functies (doelgroep)',
      tag: 'doelgroepen',
      property: 'positions',
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights',
      },
      tab: constants.asideTabs.AUDIENCE,
      whitelist: [
        {
          webconfiguration: {
            parent: ['THEME_HOME_FULL', 'THEME_HOME_TEXT', 'THEME_HOME_PICTURE'],
          },
        },
      ],
    },
  ],
  allowImporting: true,
  documentViewIdentifier: 'p.i',
  isSearchable: false,
  websiteEdition: [
    {
      domain: settings.domains.pro,
    },
    {
      domain: settings.domains.www,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  globalAttachmentsGroupContainer: true,
  customConfirmDeleteMessage: menuItemWithReference,
};
