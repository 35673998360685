import { selectApiWithPendingChangesOldSlice } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { RootState } from '@generalTypes/rootStateTypes';

export const selectIsAsideReadonly = (state: RootState) =>
  state.document.viewModel.aside.isReadOnly;

export const selectAsideContentKey = (state: RootState) =>
  state.document.viewModel.aside.editDocument.key;

export const GoalRelationsSelector = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesOldSlice(state),
    (state) => state.document.viewModel?.aside?.editDocument?.key,
  ],
  (api, documentKey) => {
    const relations = api?.contentRelations?.from[`/content/${documentKey}`] ?? [];
    return relations;
  }
);
