import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const SOURCE: HiddenContentItemConfig = {
  information: {
    single: 'Bron',
    plural: 'Bronnen',
  },
  node: {
    type: ContentType.SOURCE,
  },
  // edit: [{ component: 'title', label: 'Titel' }], // there is no real edit, this node is hidden in the document and is managed from the aside
  hideInDocument: true,
};

export default SOURCE;
