import { BuildingBlockNodeConfig, RequiredType, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_THEME: BuildingBlockNodeConfig = {
  information: {
    single: 'Leerplanthema',
    plural: "Leerplanthema's",
  },
  node: {
    type: ContentType.CURRICULUM_THEME,
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_THEME }, { type: NodeType.THEME }],
  edit: [
    {
      component: 'title',
      label: 'Titel',
      required: RequiredType.ERROR,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  isCollapsible: false,
};

export default CURRICULUM_THEME;
