/* eslint-disable max-len */
import { formatDate } from '@newStore/genericHelpers';
import { regions } from '../helpers/dioceses';

const sanitize = (text) => {
  if (!text) {
    return '';
  }
  text = text && text[text.length - 1] === '\n' ? text.substr(0, text.length - 1).trim() : text;
  return `${text}\n`;
};

const isSameUser = (proposalCreators, currentUserKey) => {
  return proposalCreators.includes(`/persons/${currentUserKey}`);
};

// -- Diff box util functions -- //

export const diffText = (vmProposal) => {
  function diffsToHtml(diffs) {
    const html = [];
    const patternBR = /\n/g;
    for (let x = 0; x < diffs.length; x += 1) {
      const op = diffs[x][0];
      const data = diffs[x][1];
      const text = unescape(data).replace(patternBR, '<br/>');
      switch (op) {
        case 1:
          html[x] = `<ins>${text}</ins>`;
          break;
        case -1:
          html[x] = `<del>${text}</del>`;
          break;
        case 0:
          html[x] = `<span>${text}</span>`;
          break;
        default:
          break;
      }
    }
    return html.join('');
  }

  function diffMessageItem(message) {
    return `<li>${message}</li>`;
  }

  let text = '';
  if (vmProposal) {
    if (vmProposal.hasMainFieldsModifications) {
      // TODO check how to use the import for text-diff, I couldn't make it work
      const Diff = require('text-diff');

      const diff = new Diff();
      // produces diff array
      const textDiffs = diff.main(vmProposal.oldValue, vmProposal.newValue);
      diff.cleanupSemantic(textDiffs);
      // produces a formatted HTML string
      text += diffsToHtml(textDiffs);
    } else if (vmProposal.types && vmProposal.types.includes('UPDATE')) {
      // TODO see how to use translate service here
      text += diffMessageItem('Metadata changes');
    }

    if (vmProposal.types && vmProposal.types.includes('CHILD_UPDATE')) {
      // TODO see how to use translate service here
      text += diffMessageItem('Metadata changes');
    }

    if (
      vmProposal.types &&
      vmProposal.types.includes('RELOCATE') &&
      !vmProposal.types.includes('DELETE')
    ) {
      // TODO see how to use translate service here
      text += diffMessageItem('Node relocated');
    }
  }

  return text;
};

export const diffCreators = (creators) => {
  return creators ? creators.map((c) => `${c.firstName} ${c.lastName}`).join(', ') : '';
};

export const diffBox = (vmProposal) => {
  const manyCreatorsClass =
    vmProposal.expandedCreators && vmProposal.expandedCreators.length > 1 ? 'many-creators' : '';

  return (
    `<div class="diff">` +
    `<div class="diff-title">` +
    ` <div class="creators ${manyCreatorsClass}">Wijzigingen door: ${diffCreators(
      vmProposal.expandedCreators
    )}${vmProposal.lastModification ? ` - ${vmProposal.lastModification}` : ''}</div>` +
    `</div>` +
    `<div class="text">${diffText(vmProposal)}</div>` +
    `</div>`
  );
};

/**
 * Some fields need to translate the api node value which are permalinks to human readable string
 */
const translationsByField = {
  creators: (value, expandedResources, resourcesToExpand) => {
    return value
      .map((c) => {
        if (!expandedResources) {
          return c;
        }
        const author = expandedResources.find((a) => a.$$meta.permalink === c);
        if (author) {
          if (author.$$name) {
            return author.$$name;
          }

          if (author.name) {
            return author.name;
          }
          return `${author.firstName} ${author.lastName}`;
        }

        resourcesToExpand.add(c);
        return c;
      })
      .join(', ');
  },
  contacts: (value, expandedResources, resourcesToExpand) => {
    return value
      .map((c) => {
        if (!expandedResources) {
          return c;
        }
        const contact = expandedResources.find((a) => a.$$meta.permalink === c);
        if (contact) {
          return `${contact.firstName} ${contact.lastName}`;
        }

        resourcesToExpand.add(c);
        return c;
      })
      .join(', ');
  },
  themes: (value, expandedResources, resourcesToExpand) => {
    return value
      .map((c) => {
        if (!expandedResources) {
          return c;
        }
        const theme = expandedResources.find((a) => a.$$meta.permalink === c);
        if (theme) {
          return theme.title;
        }

        resourcesToExpand.add(c);
        return c;
      })
      .join(', ');
  },
  coverage: (value) => {
    return value
      .map((c) => {
        const coverage = regions.find((r) => r.$$meta.permalink === c);
        if (coverage) {
          return coverage.name;
        }
        return c;
      })
      .join(', ');
  },
  mainstructuresOuTypeCombinations: (value, expandedResources, resourcesToExpand) => {
    return value
      .map((c) => {
        if (!expandedResources) {
          return c;
        }
        const mainstructuresOuTypeCombination = expandedResources.find(
          (a) => a.$$meta.permalink === c
        );
        if (mainstructuresOuTypeCombination) {
          return mainstructuresOuTypeCombination.name;
        }

        resourcesToExpand.add(c);
        return c;
      })
      .join(', ');
  },
};

const translateFieldValue = (field, node, expandedResources, resourcesToExpand) => {
  const value = node[field] && node[field].value !== undefined ? node[field].value : node[field];

  return translationsByField[field] && value
    ? translationsByField[field](value, expandedResources, resourcesToExpand)
    : value;
};

export const diffBoxForField = (
  field,
  apiNode,
  updatedNode,
  proposalCreators,
  expandedResources,
  resourcesToExpand
) => {
  const diffBoxData = {
    hasMainFieldsModifications: true,
    oldValue:
      apiNode && apiNode.key
        ? sanitize(translateFieldValue(field, apiNode, expandedResources, resourcesToExpand))
        : '',
    newValue: updatedNode
      ? sanitize(translateFieldValue(field, updatedNode, expandedResources, resourcesToExpand))
      : '',
    expandedCreators: proposalCreators,
  };
  return diffBox(diffBoxData);
};

export const diffBoxForWebsiteConfiguration = (
  originalConfiguration,
  newConfiguration,
  proposalType,
  proposalCreators
) => {
  let oldValue = '';
  let newValue = '';

  if (originalConfiguration) {
    oldValue =
      sanitize(originalConfiguration.website.domain) +
      sanitize(originalConfiguration.path) +
      sanitize(originalConfiguration.type);
  }
  if (newConfiguration && proposalType !== 'DELETE') {
    newValue =
      sanitize(newConfiguration.website.domain) +
      sanitize(newConfiguration.path) +
      sanitize(newConfiguration.type);
  }

  const data = {
    hasMainFieldsModifications: true,
    oldValue,
    newValue,
    expandedCreators: proposalCreators,
  };
  return diffBox(data);
};

// get the main proposal type by importance:
// DELETE > CREATE > UPDATE > RELOCATE
export const getProposalType = (proposal) => {
  const isNodeCreation = proposal.listOfRequestedChanges.some((c) => c.type === 'CREATE');
  const isNodeDeletion = proposal.listOfRequestedChanges.some(
    (c) => c.type === 'DELETE' && c.appliesTo.href.match(/\/content\/[-0-9a-f].*$/) && !c.relatedTo
  ); // only consider /content resources deleted to avoid wrong behavior in doc view if /relations or /web/pages are removed inside the node
  const hasRelocation = proposal.listOfRequestedChanges.some(
    (c) => c.type === 'PATCH' && c.patch.some((p) => p.path === '/readorder')
  );
  const hasFieldUpdates = proposal.listOfRequestedChanges.some(
    (c) => c.type === 'PATCH' && !c.patch.find((p) => p.path === '/readorder')
  );

  if (isNodeDeletion) {
    return 'DELETE';
  }
  if (isNodeCreation) {
    return 'CREATE';
  }
  if (hasRelocation && !hasFieldUpdates) {
    return 'RELOCATE';
  }
  return 'UPDATE';
};

// get an array with all the types of the given proposal
export const getAllProposalTypes = (proposal) => {
  const types = [];
  types.push(
    proposal.listOfRequestedChanges.some((c) => c.type === 'CREATE') ? 'CREATE' : undefined
  );
  types.push(
    proposal.listOfRequestedChanges.some(
      (c) =>
        c.type === 'DELETE' && c.appliesTo.href.match(/\/content\/[-0-9a-f].*$/) && !c.relatedTo
    )
      ? 'DELETE'
      : undefined
  );
  types.push(
    proposal.listOfRequestedChanges.some(
      (c) => c.type === 'PATCH' && c.patch.some((p) => p.path === '/readorder')
    )
      ? 'RELOCATE'
      : undefined
  );
  types.push(
    proposal.listOfRequestedChanges.some(
      (c) => c.type === 'PATCH' && !c.patch.find((p) => p.path === '/readorder')
    )
      ? 'UPDATE'
      : undefined
  );
  types.push(
    proposal.listOfRequestedChanges.some((c) => c.type === 'CHILD_UPDATE')
      ? 'CHILD_UPDATE'
      : undefined
  );

  const result = types.filter((i) => i);
  return result.length > 0 ? result : ['UPDATE'];
};

const html = (n) => {
  if (!n) {
    return '';
  }
  const attachment = n.attachments ? n.attachments.find((a) => a.type === 'CONTENT') : null;
  return attachment && attachment.text ? attachment.text : n.$$html;
};

const countChilds = (node) => {
  let count = 0;
  if (node.$$children) {
    count = node.$$children.length;
    node.$$children.forEach((child) => {
      count += countChilds(child);
    });
  }
  return count;
};

/**
 * Combine title, description and html fields
 */
const getNodeText = (node) => {
  return (
    sanitize(node.title) + sanitize(node.description) + sanitize(node.html ? node.html : html(node))
  );
};

const getNodeAndChildsText = (node) => {
  let result = [getNodeText(node)];
  if (node.$$children && node.$$children.length > 0) {
    result = node.$$children.reduce((res, child) => {
      const childText = getNodeAndChildsText(child);
      res.push(...childText);
      return res;
    }, result);
  }
  return result;
};

export const getNodeProposal = (node, state) => {
  let proposal = state.apiWithPendingChanges.proposals.get(`/content/${node.key}`);
  if (!proposal && node.$$relation) {
    // exists the case of global document attachments where the proposal is stored as key /content/relations
    proposal = state.apiWithPendingChanges.proposals.get(
      `/content/relations/${node.$$relation.key}`
    );
  }
  return proposal;
};

function getHiddenChildsProposals(node, state, rootIsCollapsed) {
  return (node.$$children || []).reduce((list, child) => {
    const childProposal = getNodeProposal(child, state);
    if (
      childProposal &&
      ((child.$$typeConfig && child.$$typeConfig.hideInDocument) ||
        node.$$typeConfig.hideChildrenInDocument === true ||
        rootIsCollapsed)
    ) {
      list.push({ child, proposal: childProposal });
    }
    // if root is collapsed we need to continue getting proposals of childs in sub levels
    list = [...list, ...getHiddenChildsProposals(child, state, rootIsCollapsed)];
    return list;
  }, []);
}

function hasVisibleNodeInMiddle(child, parentNode) {
  function isVisible(node) {
    return (
      !node.$$typeConfig.hideInDocument &&
      (!node.$$parent || node.$$parent.$$typeConfig.hideChildrenInDocument !== true) &&
      node.$$typeConfig.information
    );
  }

  if (
    child.$$relation.relationtype === 'IS_INCLUDED_IN' &&
    parentNode.type === 'ATTACHMENTS_GROUP'
  ) {
    // special case: global document as child of attachments group never has visible node in the middle
    // but we can't check it with the following rules because the $$relation is not unique in the document
    return false;
  }

  if (child.$$parent && child.$$parent.key !== parentNode.key && isVisible(child.$$parent)) {
    return true;
  }
  return child.$$parent && child.$$parent.key !== parentNode.key
    ? hasVisibleNodeInMiddle(child.$$parent, parentNode)
    : false;
}

const buildViewModelForProposal = (proposal, node, state) => {
  /* if (proposal.$$meta.permalink === '/proposals/58043251-cbe8-48a9-a123-eb646d25f27c') {
    console.log('Hallo hier', proposal)
  } */
  let vmProposal;
  if (
    proposal &&
    (state.mode === 'SUGGESTING' ||
      (proposal.status === 'SUBMITTED_FOR_REVIEW' &&
        (state.mode === 'REVIEWING' ||
          (state.mode === 'EDIT' && state.viewModel.allowedAbilities.includes('REVIEW')))))
  ) {
    const apiNode = state.api.content.get(`/content/${node.key}`);
    const apiRelation = node.$$relation
      ? state.api.relations.get(`/content/relations/${node.$$relation.key}`)
      : undefined;
    const updatedNode = state.apiWithPendingChanges.content.get(`/content/${node.key}`);

    const type = getProposalType(proposal);

    let oldValue = '';
    let newValue = '';
    if (apiNode) {
      oldValue =
        type !== 'DELETE' ? getNodeText(apiNode) : getNodeAndChildsText(apiNode).join('<br>');
    }
    if (updatedNode && type !== 'DELETE') {
      newValue = getNodeText(updatedNode);
    }

    vmProposal = {
      key: node.key,
      isSameUser: isSameUser(proposal.creators, state.me.key),
      isSubmitted: proposal.status === 'SUBMITTED_FOR_REVIEW',
      isReviewingMode: state.mode === 'REVIEWING',
      isNew: !apiNode || !apiRelation,
      isDeleted: type === 'DELETE',
      type,
      types: getAllProposalTypes(proposal),
      oldValue,
      newValue,
      hasMainFieldsModifications: oldValue !== newValue,
      expandedCreators: proposal.expandedCreators,
      hasManyCreators: proposal.creators.length > 1,
      countDeletedNodes: type === 'DELETE' ? countChilds(node) + 1 : 0,
      hash: node.key + proposal.status + state.mode + type,
    };

    if (vmProposal.isSubmitted && vmProposal.isReviewingMode) {
      vmProposal.lastModification = formatDate(proposal.$$meta.modified);
    }

    vmProposal.diffBox = diffBox(vmProposal);
  }
  return vmProposal;
};

/**
 * Fill the proposal view model object that corresponds to the given tree node.
 * @param {object} node
 * @param {object} state
 */
export const fillTreeNodeProposalViewModel = (node, state) => {
  let proposal = getNodeProposal(node, state);

  // get the list of proposals for childs that are hidden in the document view.
  // childs of all levels until the leaf
  let hiddenChildsProposals = getHiddenChildsProposals(node, state, node.$$isCollapsed)
    .filter((item) => {
      const p = item.proposal;
      return (
        state.mode === 'SUGGESTING' ||
        (p.status === 'SUBMITTED_FOR_REVIEW' &&
          (state.mode === 'REVIEWING' ||
            (state.mode === 'EDIT' && state.viewModel.allowedAbilities.includes('REVIEW'))))
      );
    })
    // consider child proposals for this node in case there is no visible node in the middle of the hierarchy
    .filter((item) => {
      const hasNodeInMiddle = hasVisibleNodeInMiddle(item.child, node);
      // console.log('Visible node in middle:', node.key, item.child.key, hasNodeInMiddle);
      return node.$$isCollapsed || !hasNodeInMiddle;
    });

  hiddenChildsProposals = hiddenChildsProposals.map((i) => i.proposal);

  if (hiddenChildsProposals.length > 0) {
    // set a proposal for the parent according to hidden childs proposals data
    proposal = {
      creators: hiddenChildsProposals.reduce(
        (list, p) => {
          p.creators.forEach((creator) => {
            if (!list.find((c) => c === creator)) {
              list.push(creator);
            }
          });
          return list;
        },
        proposal ? [...proposal.creators] : []
      ),
      expandedCreators: hiddenChildsProposals.reduce(
        (list, p) => {
          if (p.expandedCreators) {
            p.expandedCreators.forEach((creator) => {
              if (!list.find((c) => c.key === creator.key)) {
                list.push(creator);
              }
            });
          }
          return list;
        },
        proposal ? [...proposal.expandedCreators] : []
      ),
      status: hiddenChildsProposals.some((p) => p.status === 'SUBMITTED_FOR_REVIEW')
        ? 'SUBMITTED_FOR_REVIEW'
        : 'IN_PROGRESS',
      listOfRequestedChanges: [
        ...(proposal ? proposal.listOfRequestedChanges : []),
        {
          type: 'CHILD_UPDATE', // add an empty update change that represents changes in some child
        },
      ],
      $$meta: {
        modified: hiddenChildsProposals[0].$$meta.modified,
      },
    };
  }

  return buildViewModelForProposal(proposal, node, state);
};

export const getProposalPropertyForReferenceRelation = (relationHref, node, state) => {
  const proposalForNode = getNodeProposal({ key: node.key }, state);
  let vmProposal;
  if (proposalForNode) {
    const listOfRequestedChanges = proposalForNode.listOfRequestedChanges.filter(
      (rc) => rc.appliesTo.href === relationHref
    );
    if (listOfRequestedChanges.length > 0) {
      vmProposal = buildViewModelForProposal(
        { ...proposalForNode, listOfRequestedChanges },
        node,
        state
      );
    }
  }
  return vmProposal;
};

/**
 * Check if there is a difference in the value of the field in the original node
 * compared with the node updated with proposals
 * @param {string} field
 * @param {object} apiNode
 * @param {object} updatedNode
 * @param {object} state
 * @param {object} nodeExtensions
 */
const existsProposalForField = (field, apiNode, updatedNode, state, proposal) => {
  const updatedValue =
    updatedNode[field] && updatedNode[field].value !== undefined
      ? updatedNode[field].value
      : updatedNode[field];

  let existsProposal =
    !apiNode || !apiNode.key || sanitize(updatedValue) !== sanitize(apiNode[field]);

  // special cases
  if (field === '$$attachments') {
    // images diff is handle differently because we need to set proposal for each type of attachment
    const proposalVM = {
      isSameUser: isSameUser(proposal.creators, state.me.key),
      isSubmitted: proposal.status === 'SUBMITTED_FOR_REVIEW',
      isReviewingMode: state.mode === 'REVIEWING',
      type: getProposalType(proposal),
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [type, attachment] of updatedNode[field]) {
      if (type !== 'CONTENT') {
        proposalVM.key = type;

        attachment.original = attachment.original || {};

        if (
          apiNode &&
          apiNode.key &&
          apiNode[field].has(type) &&
          apiNode[field].get(type).original
        ) {
          const sameUrl = apiNode[field].get(type).original.$$url === attachment.original.$$url;
          const sameAlt = apiNode[field].get(type).original.alt === attachment.original.alt;
          const sameDescription =
            apiNode[field].get(type).original.description === attachment.original.description;
          attachment.original.proposal = sameUrl && sameAlt && sameDescription ? null : proposalVM;
        } else {
          attachment.original.proposal = proposalVM;
        }
      }
    }

    existsProposal = false;
  }

  return existsProposal;
};

const getProposalVMForField = (field, apiNode, updatedNode, proposal, state, resourcesToExpand) => {
  const type = getProposalType(proposal);

  if (field === 'attachments') {
    field = '$$attachments';
  }

  const existsProposal = existsProposalForField(field, apiNode, updatedNode, state, proposal);

  if (existsProposal) {
    return {
      isSameUser: isSameUser(proposal.creators, state.me.key),
      isSubmitted: proposal.status === 'SUBMITTED_FOR_REVIEW',
      isReviewingMode: state.mode === 'REVIEWING',
      type,
      key: field,
      diffBox: diffBoxForField(
        field,
        apiNode,
        updatedNode,
        proposal.expandedCreators,
        state.viewModel.aside.expandedResources || [],
        resourcesToExpand
      ),
    };
  }

  return null;
};

/**
 * Fill the proposal view model object for each field of the aside edit node.
 * @param {object} node already updated with proposals
 * @param {object} state
 */
export const fillAsideNodeWithProposalViewModel = (
  node,
  state,
  nodeExtensions,
  resourcesToExpand
) => {
  // TODO remove when ready for all components
  const transformFields = [
    'title',
    'identifiers',
    'importance',
    'description',
    'html',
    'coverage',
    'mainstructures',
    'outypes',
    'creators',
    'contacts',
    'attachments',
  ];
  const excludeFields = ['key', 'type', 'tags', 'accessRights'];

  const proposal = state.apiWithPendingChanges.proposals.get(`/content/${node.key}`);

  if (proposal && ['IN_PROGRESS', 'SUBMITTED_FOR_REVIEW'].includes(proposal.status)) {
    const apiNode = { ...state.api.content.get(`/content/${node.key}`) };

    if (apiNode.key) {
      apiNode.html = html(apiNode);
      apiNode.$$attachments = nodeExtensions.$$attachments(apiNode, state);
    }

    // for each node field try to find a proposal change
    Object.keys(node).forEach((field) => {
      if (
        ((!field.startsWith('$$') && !excludeFields.some((f) => f === field)) ||
          transformFields.some((f) => f === field)) &&
        !nodeExtensions[field]
      ) {
        // check if there are modifications in the field comparing
        // api node with the updated node with proposals
        const proposalVM = getProposalVMForField(
          field,
          apiNode,
          node,
          proposal,
          state,
          resourcesToExpand
        );

        if (proposalVM) {
          node[field] = {
            ...node[field],
            originalValue: apiNode ? apiNode[field] : '',
            proposal: proposalVM,
          };
        }
      }
    });

    node.resourcesToExpand = resourcesToExpand;
  }
  return node;
};

export const fillWebsiteConfigurationWithProposalViewModel = (node, state) => {
  const proposal = state.apiWithPendingChanges.proposals.get(`/content/${node.key}`);

  if (
    proposal &&
    node.websitesConfiguration &&
    (state.mode === 'SUGGESTING' ||
      (state.mode === 'REVIEWING' && proposal.status === 'SUBMITTED_FOR_REVIEW'))
  ) {
    // original api data
    const apiNode = { ...state.api.content.get(`/content/${node.key}`) };
    apiNode.websitesConfiguration = [...state.api.webpages.values()].filter(
      (w) => w.source.href === `/content/${node.key}`
    );

    // create proposalVM for each website configuration that has it
    node.websitesConfiguration.map((configuration) => {
      const isConfigurationWithProposal = proposal.listOfRequestedChanges.find(
        (c) =>
          (c.resource && c.resource.key === configuration.key) ||
          c.appliesTo.href.split('/').pop() === configuration.key
      );

      if (isConfigurationWithProposal) {
        const proposalType = getProposalType(proposal);

        configuration.proposal = {
          isSameUser: isSameUser(proposal.creators, state.me.key),
          isSubmitted: proposal.status === 'SUBMITTED_FOR_REVIEW',
          isReviewingMode: state.mode === 'REVIEWING',
          isNew: !apiNode.websitesConfiguration.find((c) => c.key === configuration.key),
          isDeleted: proposalType === 'DELETE',
          type: proposalType,
          key: configuration.key,
          diffBox: diffBoxForWebsiteConfiguration(
            apiNode.websitesConfiguration.find((c) => c.key === configuration.key),
            configuration,
            proposalType,
            proposal.expandedCreators
          ),
        };
      }
      return configuration;
    });
  }
};

/**
 * Special proposal view model that will only apply for some special cases of nodes in the TOC
 * which have the proposal indicator representing their children that are hidden in the toc.
 * @param {object} node
 */
export const createTocNodeProposalViewModel = (node, childsWithProposalNotInTOC, state) => {
  let hasChildProposalOfSameUser = false;
  let hasChildProposalSubmitted = false;

  // if at least one child is same user or is submitted we take that color/type
  childsWithProposalNotInTOC.forEach((child) => {
    if (child.proposal.isSameUser) {
      hasChildProposalOfSameUser = true;
    }
    if (child.proposal.isSubmitted) {
      hasChildProposalSubmitted = true;
    }
  });

  return {
    isDisplayedOnlyInToc: true,
    isSubmitted: hasChildProposalSubmitted,
    isSameUser: hasChildProposalOfSameUser,
    isReviewingMode: state.mode === 'REVIEWING',
    hash: node.key + hasChildProposalSubmitted + state.mode,
  };
};

export const hasDeletionProposal = (node, state) => {
  const proposal = state.apiWithPendingChanges.proposals.get(`/content/${node.key}`);
  return proposal && getProposalType(proposal) === 'DELETE';
};
