import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { publishComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../../reduxLoop/constants/constants';
import { titleNotEmptyOnNewDocumentUpload } from '../../../../validations/titleNotEmptyOnNewDocumentUpload';
import { selectOneTypeOfDocument } from '../../../../validations/selectOneTypeOfDocument';
import { proThemeAudienceTab } from '../proGenericConfigProps';
import { leerplanSitesThemeComponents } from './leerplansitesGenericConfigProps';

export const SHARED_ATTACHMENTS_GROUP: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Achtergronddocument (leerplansites so)',
    plural: 'Achtergronddocumenten (leerplansites so)',
    icon: require('../../../../../img/icons/shared-attachments-group.svg'),
    category: 'LEERPLANSITES_SO',
  },
  node: {
    type: ContentType.SHARED_ATTACHMENTS_GROUP,
    tags: ['SHARED_ATTACHMENTS_GROUP'],
  },
  onLoadActions: [loadReferenceFrame({ referenceFrame: referenceFrames.pro })],
  tocTypes: [],
  buildingBlocks: [],
  edit: [
    {
      component: 'downloadDocument',
      label: 'Document',
      avoidDispatchDownloadTag: true,
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
        copyTitle: true,
      },
      validations: [titleNotEmptyOnNewDocumentUpload, selectOneTypeOfDocument],
    },
    publishComponent,
    ...leerplanSitesThemeComponents,
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: false },
    },
    {
      component: 'webFacets',
      label: 'Facetten',
      width: '12',
      options: {
        source: 'themesMatches',
        matchingParams: {
          themesMatchingOnRoot: { referenceFrameKey: constants.dienstverleningKovKey },
          'webPage.template': '6a090cca-c380-4248-8722-2951431018a9', // DOWNLOAD_PAGE
          $$includeWebPages: true,
        },
      },
    },
  ],
  audienceTab: proThemeAudienceTab,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  allowSuggestions: true,
  hideChildrenInDocument: true,
};
