import { showValidationErrors } from '@newStore/documentList/newDocumentListState';
import { selectAttachmentsGroupNode } from '@newStore/documentUI/transformContent/attachmentSelectors';
import {
  selectDescriptionNode,
  selectLegalNode,
  selectLlinkidGoalNode,
  selectParagraphNode,
  selectPlaceholderNode,
  selectQuoteNode,
  selectTermNode,
  selectThemeItemNode,
  selectTitleNode,
  selectTitleWithDescriptionNode,
} from '@newStore/documentUI/transformContent/genericSelectors';
import { selectGlobalDocumentNode } from '@newStore/documentUI/transformContent/globalDocumentSelectors';
import {
  selectImageGroupNode,
  selectImageNode,
} from '@newStore/documentUI/transformContent/imageSelectors';
import {
  selectGoalSubItem,
  selectLlinkidCurriculumNode,
} from '@newStore/documentUI/transformContent/llinkidSelectors';
import { selectMusicNode } from '@newStore/documentUI/transformContent/musicSelectors';
import {
  selectOdetDevelopmentGoalorEindtermNode,
  selectOdetKeyCompetenceNode,
  selectOdetLeergebiedNode,
  selectOdetSubsectionNode,
  selectOdetTitleNode,
} from '@newStore/documentUI/transformContent/odetContentNodeSelectors';
import { selectProNewsItemNode } from '@newStore/documentUI/transformContent/pronewsitemSelectors';
import { selectReferenceGroupNode } from '@newStore/documentUI/transformContent/referenceGroupSelectors';
import { selectTeaserNode } from '@newStore/documentUI/transformContent/teaserSelectors';
import {
  selectVideoGroupNode,
  selectVideoNode,
} from '@newStore/documentUI/transformContent/videoSelectors';
import {
  selectZillClusterNode,
  selectZillCurriculumNode,
  selectZillDevelopmentContentNode,
  selectZillDevelopmentFieldNode,
  selectZillDevelopmentThemeNode,
  selectZillGenericGoalNode,
  selectZillLeerlijnReferenceStepNode,
  selectZillLeerlijnStepNode,
} from '@newStore/documentUI/transformContent/zillContentNodeSelectors';
import {
  selectValidateAttachmentAudienceParentIntersectionNotEmpty,
  selectValidateTeaserAudienceParentIntersectionNotEmpty,
} from '@newStore/validation/validationRules/audienceIntersectionRules';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { selectDoesEveryChildHasTitle } from '@newStore/validation/validationRules/everyChildMustHaveTitle';
import { validateGlobalDocumentAllowedInAttachmentsGroup } from '@newStore/validation/validationRules/globalDocumentSecurityConsistency';
import { selectLlinkidCurriculumApplicabilityRule } from '@newStore/validation/validationRules/llinkidCurriculumApplicabilityValidation';
import { expiryDateValidationRules } from '@newStore/validation/validationRules/llinkidCurriculumExpiryDate';
import { teaserCallToActionValidationRules } from '@newStore/validation/validationRules/teaserCallToActionValidationRules';
import { selectValidateTeaserMatchesSection } from '@newStore/validation/validationRules/teaserMatchesSection';
import {
  validateWebConfigurationRequired,
  validateWebTemplateConfig,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { themeReferencesWithRootsCmd } from '@store/commands/documentCommands';
import {
  createNewsLetterTemplateValidationCmd,
  globalDocumentDeleteValidationCmd,
  newsItemDeleteValidationCmd,
  referenceFrameDeleteValidationCmd,
  teasersDeleteValidationCmd,
} from '@store/commands/documentListCommands';
import { validateChildrenAreRequired } from '../../store/validation/validationRules/childrenAreRequired';
import { proSelectors } from './PRO/proNodeTypeSelectors';
import { NodeType, NodeTypeSelectors } from './configTypes';

const getThemeReferencesWithRootsCmd = (node) => ({
  cmd: themeReferencesWithRootsCmd,
  params: [node.$$meta.permalink],
  message: 'validation.themeReferenced',
});

/**
 * Configuration Map that defines which selectors are used for each NodeType
 * To avoid circular dependency cycles configuration this is in a separate file
 * because selector files typically need the NodeTypeConfiguration,
 * so if that would include selectors you would have a cycle
 */
const nodeTypeSelectorsMap: NodeTypeSelectors = {
  // Pro Themes and Pro Menu
  ...proSelectors,
  // Pro News
  [NodeType.TEASER]: {
    nodeSelector: selectTeaserNode,
    deleteFromListValidations: [teasersDeleteValidationCmd],
    validationRules: [
      validateWebConfigurationRequired,
      validateWebTemplateConfig,
      { rule: selectValidateTeaserMatchesSection },
      ...teaserCallToActionValidationRules,
      { rule: selectCurriculumThemesValidation },
      { rule: selectValidateTeaserAudienceParentIntersectionNotEmpty },
    ],
  },
  [NodeType.PRONEWSITEM]: {
    nodeSelector: selectProNewsItemNode,
    deleteFromListValidations: [newsItemDeleteValidationCmd],
    validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  },
  [NodeType.PRONEWSLETTER]: {
    nodeSelector: selectTitleNode,
    validationRules: [],
  },
  [NodeType.PRONEWSLETTERTEMPLATE]: {
    nodeSelector: selectTitleNode,
    validationRules: [],
    createValidations: [
      {
        cmd: createNewsLetterTemplateValidationCmd,
        failAction: showValidationErrors,
      },
    ],
  },
  [NodeType.PRO_NEWSLETTER_SECTION]: {
    nodeSelector: selectTitleNode,
    validationRules: [{ rule: selectCurriculumThemesValidation }],
  },
  // Leerplansites
  [NodeType.SHARED_MINI_DATABASE_ITEM]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [validateWebTemplateConfig, { rule: selectCurriculumThemesValidation }],
  },
  [NodeType.SHARED_FAQ]: {
    nodeSelector: selectTitleNode,
    validationRules: [{ rule: selectCurriculumThemesValidation }],
  },
  [NodeType.SHARED_ATTACHMENTS_GROUP]: {
    nodeSelector: selectAttachmentsGroupNode,
    validationRules: [
      validateChildrenAreRequired,
      { rule: selectDoesEveryChildHasTitle },
      { rule: selectCurriculumThemesValidation },
    ],
  },
  // www
  [NodeType.WWW_STATIC_PAGE]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [validateWebTemplateConfig],
  },
  [NodeType.WWW]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  },
  [NodeType.WWWNEWSITEM]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  },
  [NodeType.JOB_OFFER]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  },
  [NodeType.PRESS]: {
    nodeSelector: selectTitleWithDescriptionNode,
    validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  },
  // LLINKID
  [NodeType.LLINKID_CURRICULUM]: {
    nodeSelector: selectLlinkidCurriculumNode,
    validationRules: [
      ...expiryDateValidationRules,
      { rule: selectLlinkidCurriculumApplicabilityRule },
    ],
  },
  [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM]: {
    nodeSelector: selectLlinkidCurriculumNode,
    validationRules: [
      ...expiryDateValidationRules,
      { rule: selectLlinkidCurriculumApplicabilityRule },
    ],
  },
  [NodeType.LLINKID_GOAL_LIST]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_GOAL_SECTION]: { nodeSelector: selectTitleNode },
  [NodeType.LLINKID_GOAL]: { nodeSelector: selectLlinkidGoalNode },
  [NodeType.LLINKID_GOAL_DEMARCATION]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_PEDAGOGICAL_TIP]: {
    nodeSelector: selectGoalSubItem,
  },
  [NodeType.LLINKID_EXTRA_GOAL_INFORMATION]: {
    nodeSelector: selectGoalSubItem,
  },
  [NodeType.LLINKID_GOAL_EXPLANATION]: {
    nodeSelector: selectGoalSubItem,
  },
  [NodeType.LLINKID_INITIAL_SITUATION]: {
    nodeSelector: selectGoalSubItem,
  },
  [NodeType.LLINKID_GUIDING_PRINCIPLE_GROUP]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_GUIDING_PRINCIPLE]: { nodeSelector: selectDescriptionNode },
  [NodeType.LLINKID_STATIC_PART]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_CONTEXT]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_BASIC_MATERIAL_REQUIREMENTS]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_CORRELATION_ENDTERMS]: { nodeSelector: selectPlaceholderNode },
  [NodeType.LLINKID_CONCORDANCE]: { nodeSelector: selectTitleNode },
  // Zill
  [NodeType.CURRICULUM_ZILL]: { nodeSelector: selectZillCurriculumNode },
  [NodeType.CURRICULUM_ZILL_CLUSTER]: { nodeSelector: selectZillClusterNode },
  [NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD]: { nodeSelector: selectZillDevelopmentFieldNode },
  [NodeType.CURRICULUM_ZILL_DEVELOPMENT_THEME]: { nodeSelector: selectZillDevelopmentThemeNode },
  [NodeType.CURRICULUM_ZILL_GENERIC_GOAL]: { nodeSelector: selectZillGenericGoalNode },
  [NodeType.CURRICULUM_ZILL_CONTENT_GROUP]: { nodeSelector: selectPlaceholderNode },
  [NodeType.CURRICULUM_ZILL_LEERLIJN]: { nodeSelector: selectPlaceholderNode },
  [NodeType.CURRICULUM_ZILL_LEERLIJN_AN]: { nodeSelector: selectPlaceholderNode },
  [NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT]: {
    nodeSelector: selectZillDevelopmentContentNode,
  },
  [NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER]: { nodeSelector: selectZillLeerlijnStepNode },
  [NodeType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE]: {
    nodeSelector: selectZillLeerlijnReferenceStepNode,
  },
  [NodeType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE]: {
    nodeSelector: selectZillLeerlijnReferenceStepNode,
  },
  [NodeType.ZILL_ILLUSTRATION]: { nodeSelector: selectTitleNode },
  [NodeType.PRACTICAL_EXAMPLE]: { nodeSelector: selectTitleWithDescriptionNode },
  [NodeType.WEBPAGE]: { nodeSelector: selectTitleWithDescriptionNode },
  // ODET
  [NodeType.CURRICULUM_ODET]: { nodeSelector: selectOdetTitleNode },
  [NodeType.CURRICULUM_ODET_LEVEL]: { nodeSelector: selectOdetTitleNode },
  [NodeType.CURRICULUM_ODET_LEERGEBIED]: { nodeSelector: selectOdetLeergebiedNode },
  [NodeType.CURRICULUM_ODET_KEY_COMPETENCE]: { nodeSelector: selectOdetKeyCompetenceNode },
  [NodeType.CURRICULUM_ODET_SUBSECTION]: { nodeSelector: selectOdetSubsectionNode },
  [NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL]: {
    nodeSelector: selectOdetDevelopmentGoalorEindtermNode,
  },
  [NodeType.CURRICULUM_ODET_ENDTERM]: { nodeSelector: selectOdetDevelopmentGoalorEindtermNode },
  // Other generic root documents
  [NodeType.VISION_TEXT]: { nodeSelector: selectTitleWithDescriptionNode },
  [NodeType.VISION_TEXT_SECTION]: { nodeSelector: selectTitleNode },
  [NodeType.TERM]: { nodeSelector: selectTermNode },
  [NodeType.MARK_EXPLANATION]: { nodeSelector: selectTitleWithDescriptionNode },
  [NodeType.GLOBAL_DOCUMENT]: {
    nodeSelector: selectGlobalDocumentNode,
    deleteFromListValidations: [globalDocumentDeleteValidationCmd],
  },
  // Refererence Frames
  [NodeType.REFERENCE_FRAME]: {
    nodeSelector: selectTitleNode,
    deleteFromListValidations: [referenceFrameDeleteValidationCmd],
  },
  [NodeType.THEME]: {
    nodeSelector: selectThemeItemNode,
    deleteValidations: [getThemeReferencesWithRootsCmd],
  },
  [NodeType.CURRICULUM_CLUSTER]: { nodeSelector: selectTitleNode },
  [NodeType.CURRICULUM_THEME]: { nodeSelector: selectTitleNode },
  // Building blocks
  [NodeType.REFERENCE_GROUP]: {
    nodeSelector: selectReferenceGroupNode,
    validationRules: [{ rule: selectCurriculumThemesValidation }],
  },
  [NodeType.REFERENCE]: {},
  [NodeType.SECTION]: { nodeSelector: selectTitleNode },
  [NodeType.SECTION_NEW]: { nodeSelector: selectTitleNode },
  [NodeType.PARAGRAPH]: { nodeSelector: selectParagraphNode },
  [NodeType.IMAGE]: { nodeSelector: selectImageNode },
  [NodeType.IMAGE_GROUP]: {
    nodeSelector: selectImageGroupNode,
    validationRules: [validateChildrenAreRequired],
  },
  [NodeType.VIDEO]: { nodeSelector: selectVideoNode },
  [NodeType.VIDEO_GROUP]: {
    nodeSelector: selectVideoGroupNode,
    validationRules: [validateChildrenAreRequired],
  },
  [NodeType.EXAMPLE]: { nodeSelector: selectPlaceholderNode },
  [NodeType.ATTACHMENT]: {},
  [NodeType.ATTACHMENTS_GROUP]: {
    nodeSelector: selectAttachmentsGroupNode,
    validationRules: [
      validateChildrenAreRequired,
      { rule: selectDoesEveryChildHasTitle },
      validateGlobalDocumentAllowedInAttachmentsGroup,
      { rule: selectValidateAttachmentAudienceParentIntersectionNotEmpty },
    ],
  },
  [NodeType.MUSIC]: { nodeSelector: selectMusicNode },
  [NodeType.QUOTE]: { nodeSelector: selectQuoteNode },
  [NodeType.FAQ]: { nodeSelector: selectTitleNode },
  [NodeType.FAQ_GROUP]: { nodeSelector: selectTitleNode },
  [NodeType.SUMMARY]: { nodeSelector: selectTitleNode },
  [NodeType.LEGAL]: { nodeSelector: selectLegalNode },
  [NodeType.SOURCE]: {},
  [NodeType.LINK_GROUP]: {},
  [NodeType.PAGE_ATTACHMENTS_GROUP]: {},
  [NodeType.WORD_IMPORT]: {},
};

export default nodeTypeSelectorsMap;
