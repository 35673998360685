import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { dienstverleningKovKey } from '@store/constants/constants';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { titleComponent } from '../../helpers/genericComponents';
// Extends SECTION => A SECTION inside a PRONEWSLETTER or a PRONEWSLETTERTEMPLATE becomes a PRO_NEWSLETTER_SECTION
const PRO_NEWSLETTER_SECTION: ExtendedBuildingBlockNodeConfig = {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  documentTypes: [NodeType.PRONEWSLETTER, NodeType.PRONEWSLETTERTEMPLATE],
  isCollapsedByDefault: false,
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.REFERENCE_GROUP },
    { type: NodeType.TEASER, showInAncestorTypes: [NodeType.PRONEWSLETTER] },
    { type: NodeType.WORD_IMPORT },
  ], // no SECTION in PRONEWSLETTER SECTION you can only go one level deep
  edit: [
    titleComponent,
    {
      component: 'themes',
      label: "Thema's",
      options: {
        referenceFrameKey: dienstverleningKovKey,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
    },
    {
      component: 'curriculumSelector',
      label: "Leerplanthema's",
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== ContentType.CURRICULUM_THEME ||
            !theme.$$children.some((c) => c.type === ContentType.CURRICULUM_THEME)
          );
        },
      },
    },
  ],
};
export default PRO_NEWSLETTER_SECTION;
