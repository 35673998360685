import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { countCharacters } from '../../../reduxLoop/helpers/documentHelpers';

class asideDescription {
  constructor(ModalWindowService, $scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.modalWindowService = ModalWindowService;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const field =
        this.sConfig.options && this.sConfig.options.type
          ? this.sConfig.options.type
          : 'description';

      return {
        documentSections: state.document.viewModel.aside.documentSections,
        rootDocument: state.document.viewModel.aside.rootDocument,
        description: {
          ...state.document.viewModel.aside.editDocument[field],
        },
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);

    this.characters = countCharacters(this.description.value);
    this.$scope.maxAmountOfCharacters = { maxCharacters: this.sConfig.maxLength };
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(scope, event, data) {
    // key pressed
    scope.ctrl.characters = countCharacters(data.value);
    scope.$apply();
  }

  blur(scope, event, data) {
    const field =
      scope.ctrl.sConfig.options && scope.ctrl.sConfig.options.type
        ? scope.ctrl.sConfig.options.type
        : 'description';
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(scope.ctrl.editKey, { [field]: data.value })
    );
  }

  showDemarcationButton() {
    return this.sConfig.options && this.sConfig.options.showDemarcationButton;
  }

  showCustomLinkButton() {
    return !this.sDocument.$$isRoot && this.sDocument.$$root.$$type === 'WEBPAGE2';
  }
}

export default {
  template: require('./asideDescription.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideDescription,
};
