export const setOldUrls = (websiteConfigurations) => {
  if (!websiteConfigurations || websiteConfigurations.length === 0) {
    return;
  }
  websiteConfigurations.forEach((configuration) => {
    if (
      !configuration.oldUrlsVm &&
      configuration.oldLocations &&
      configuration.oldLocations.length > 0
    ) {
      configuration.oldUrlsVm = configuration.oldLocations.map((x) => x.$$oldUrl);
    }
  });
};

export const getOldLocation = (oldUrl, websites) => {
  const oldUrlObject = new URL(oldUrl);
  const oldWebsite = websites.find((x) => x.domain === oldUrlObject.hostname);
  return {
    website: {
      href: oldWebsite.$$meta.permalink,
    },
    path: oldUrl.split(oldUrlObject.hostname)[1],
  };
};

export const getOldLocations = (oldUrls, websites) => {
  if (!oldUrls || oldUrls.length === 0) {
    return [];
  }
  return oldUrls.map((oldUrl) => getOldLocation(oldUrl, websites));
};

export const getOldUrl = (oldLocation, websites) => {
  const oldWebsite = websites.find((x) => x.$$meta.permalink === oldLocation.website.href);
  return `https://${oldWebsite.domain}${oldLocation.path}`;
};

export const getOldUrls = (oldLocations, websites) => {
  if (!oldLocations || oldLocations.length === 0) {
    return [];
  }
  return oldLocations.map((oldLocation) => getOldUrl(oldLocation, websites));
};

export const replacePathSpecialCharacters = (value) => {
  return value
    .replace(/,/g, '')
    .replace(/:/g, '')
    .replace(/!/g, '')
    .replace(/\./g, '')
    .replace(/\?/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/'/g, '')
    .replace(/\+/g, '')
    .replace(/–/g, '')
    .replace(/\//g, '-')
    .replace(/…/g, '')
    .replace(/&amp;/g, '')
    .replace(/#/g, '')
    .replace(/‘/g, '')
    .replace(/’/g, '')
    .replace(/<br>/g, '')
    .replace(/@/g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/\$/g, '')
    .replace(/\*/g, '')
    .replace(/=/g, '')
    .replace(/&quot;/g, '')
    .replace(/&lt;/g, '')
    .replace(/&gt;/g, '')
    .replace(/%/g, '')
    .replace(/{/g, '')
    .replace(/}/g, '')
    .replace(/\|/g, '')
    .replace(/\\/g, '')
    .replace(/\^/g, '')
    .replace(/`/g, '')
    .replace(/;/g, '') // remove semicolon after &quot; &lt; etcs
    .trim()
    .replace(/\s+/g, '-')
    .replace(/--/g, '-');
};
