import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const PARAGRAPH: BuildingBlockNodeConfig = {
  information: {
    single: 'Alinea',
    plural: "Alinea's",
    ribonIcon: require('../../../../img/buildingBlocks/PARAGRAPH.svg'),
  },
  node: {
    type: ContentType.PARAGRAPH,
  },
  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      hideInAncestorTypes: [
        NodeType.PRONEWSLETTER,
        NodeType.LLINKID_CURRICULUM,
        NodeType.PRO_STATIC_PAGE,
        NodeType.WWW_STATIC_PAGE,
      ],
      blacklist: [
        { root: { $$type: NodeType.PRONEWSLETTER } },
        { root: { $$type: NodeType.LLINKID_CURRICULUM } },
        {
          webconfiguration: {
            inherited: ['STATIC', 'WWW_HARD_CODED'],
          },
        },
      ],
    },
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.PRONEWSLETTER],
      whitelist: [{ root: { $$type: NodeType.PRONEWSLETTER } }],
      options: {
        hideTermButton: true,
      },
    },
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.LLINKID_CURRICULUM],
      whitelist: [{ root: { $$type: NodeType.LLINKID_CURRICULUM } }],
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'staticHtml',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.PRO_STATIC_PAGE, NodeType.WWW_STATIC_PAGE],
      whitelist: [
        {
          webconfiguration: {
            inherited: ['STATIC', 'WWW_HARD_CODED'],
          },
        },
      ],
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Afbeelding',
      options: {
        type: 'ILLUSTRATION',
        fields: ['alt'],
      },
      hideInAncestorTypes: [
        NodeType.LLINKID_STATIC_PART,
        NodeType.TERM,
        NodeType.PRONEWSLETTER,
        NodeType.PRONEWSLETTERTEMPLATE,
      ],
      blacklist: [
        { parent: { $$type: NodeType.LLINKID_STATIC_PART } },
        { parent: { $$type: NodeType.TERM } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
      ],
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        { root: { $$type: NodeType.WEBPAGE2 } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        { root: { $$type: NodeType.WEBPAGE2 } },
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'namedSet',
      label: 'Functies (doelgroep)',
      tag: 'doelgroepen',
      property: 'positions',
      tab: constants.asideTabs.AUDIENCE,
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'html',
  },
};

export default PARAGRAPH;
