import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideCheckbox {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    const component = this;
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const contents = new Map(state.document.apiWithPendingChanges.content);
      const editDocument = contents.get(`/content/${component.sDocument.key}`);

      return {
        model: editDocument[component.sConfig.component],
        readOnly: state.document.viewModel.isReadOnlyDocument,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change() {
    const patch = {};
    patch[this.sConfig.component] = !this.model;

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, patch));
  }
}

export default {
  template: require('./asideCheckbox.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideCheckbox,
};
