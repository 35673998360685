import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { PRONEWSLETTER } from './PRONEWSLETTER';

const PRONEWSLETTERTEMPLATE: RootNodeConfig = {
  ...PRONEWSLETTER,
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbriefsjabloon',
    plural: 'PRO.-nieuwsbriefsjablonen',
    icon: require('../../../../../img/icons/document.svg'),
    category: 'PRO',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['PRONEWSLETTERTEMPLATE'],
  },
  createModal: {
    authors: { addUser: true },
    newsLetterType: { required: true },
  },
  preloadActions: [getAllOfResource({ resource: '/newsletter/senders', refresh: false })],
  tocTypes: [NodeType.SECTION, NodeType.PRO_NEWSLETTER_SECTION],
  edit: PRONEWSLETTER.edit.filter((e) => e.component !== 'dateToSend'),
};

export default PRONEWSLETTERTEMPLATE;
