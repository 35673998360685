export const DOCUMENT_CLEAR_REDIRECT = 'DOCUMENT_CLEAR_REDIRECT';
export const REMOVE_ATTACHMENTS = 'REMOVE_ATTACHMENTS';
export const ADD_ATTACHMENTS = 'ADD_ATTACHMENTS';
export const FINISH_DRAG_NODE = 'FINISH_DRAG_NODE';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';
export const ADD_NODE = 'ADD_NODE';
export const ADD_RELATION = 'ADD_RELATION';
export const ADD_EXTERNAL_RELATION = 'ADD_EXTERNAL_RELATION';
export const ADD_NODE_TO_PARENT_NODE = 'ADD_NODE_TO_PARENT_NODE';
export const PATCH_RELATION = 'PATCH_RELATION';
export const DIRTY_NODE = 'DIRTY_NODE';
export const REMOVE_NODE = 'REMOVE_NODE';
export const HOVER_ON_COLLAPSE = 'HOVER_ON_COLLAPSE';
export const ADD_EDIT_LINK_REFERENCE_NODE = 'ADD_EDIT_LINK_REFERENCE_NODE';
export const ADD_LINK_REFERENCE_NODE = 'ADD_LINK_REFERENCE_NODE';
export const EDIT_LINK_REFERENCE_NODE = 'EDIT_LINK_REFERENCE_NODE';
export const INIT = 'INIT';
export const INIT_SUCCESS = 'INIT_SUCCESS';

export const DOCUMENT_LIST_PUBLISH = 'DOCUMENT_LIST_PUBLISH';
export const DOCUMENT_LIST_PUBLISHED = 'DOCUMENT_LIST_PUBLISHED';
export const DOCUMENT_LIST_PUBLISH_FAILED = 'DOCUMENT_LIST_PUBLISH_FAILED';
export const DOCUMENT_LIST_DELETE_FAILED = 'DOCUMENT_LIST_DELETE_FAILED';
export const DOCUMENT_LIST_EXPAND_STATE_RESULTS = 'DOCUMENT_LIST_EXPAND_STATE_RESULTS';
export const SEND_PUBLISHED_EMAIL = 'SEND_PUBLISHED_EMAIL';
export const SEND_LIST_PUBLISHED_EMAIL = 'SEND_LIST_PUBLISHED_EMAIL';

export const PUBLISH_DOCUMENT = 'PUBLISH_DOCUMENT';
export const DOCUMENT_PUBLISHED = 'DOCUMENT_PUBLISHED';
export const OPENED_PUBLISH_MODAL = 'OPENED_PUBLISH_MODAL';
export const CLOSE_PUBLISH_MODAL = 'CLOSE_PUBLISH_MODAL';
export const SHOW_NOT_ALLOWED_ROOT_NODE_EDITION_WARNING =
  'SHOW_NOT_ALLOWED_ROOT_NODE_EDITION_WARNING';
export const LAST_READ_MARK_UPDATED = 'LAST_READ_MARK_UPDATED';
export const SECURITY_SET_UI_TYPES = 'SECURITY_SET_UI_TYPES';
export const DOCUMENT_SAVE_FAILED = 'DOCUMENT_SAVE_FAILED';
export const DOCUMENT_SAVED = 'DOCUMENT_SAVED';
export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const LOAD_DATA_TO_SAVE_DOCUMENT = 'LOAD_DATA_TO_SAVE_DOCUMENT';
export const LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS = 'LOAD_DATA_TO_SAVE_DOCUMENT_SUCCESS';
export const ATTACHMENT_SAVE_FAILED = 'ATTACHMENT_SAVE_FAILED';
export const PATCH_NODE = 'PATCH_NODE';
export const SET_LINKED_CONTENT_TYPE = 'SET_LINKED_CONTENT_TYPE';
export const SET_LINKED_CALL_TO_ACTION = 'SET_LINKED_CALL_TO_ACTION';
export const VALIDATE_AND_REMOVE_SELECTIONS = 'VALIDATE_AND_REMOVE_SELECTIONS';
export const REMOVE_SELECTIONS = 'REMOVE_SELECTIONS';
export const TOGGLE_SELECTION = 'TOGGLE_SELECTION';
export const SET_SELECTION = 'SET_SELECTION';
export const UNDO_ALL = 'UNDO_ALL';
export const UPDATE_DOCUMENT_TREE = 'UPDATE_DOCUMENT_TREE';
export const UPDATE_DOCUMENT_FLAT = 'UPDATE_DOCUMENT_FLAT';
export const UPDATE_API_PENDING_AND_WITH_CHANGES = 'UPDATE_API_PENDING_AND_WITH_CHANGES';
export const UPDATE_API_PENDING_AND_WITH_CHANGES_SUCCESS =
  'UPDATE_API_PENDING_AND_WITH_CHANGES_SUCCESS';
export const UPDATE_API_WITH_PENDING_CHANGES_AND_UPDATE_DOCUMENT_TREE =
  'UPDATE_API_WITH_PENDING_CHANGES_AND_UPDATE_DOCUMENT_TREE';
export const SET_DOCUMENT_NODES = 'SET_DOCUMENT_NODES';
export const INIT_DOCUMENT = 'INIT_DOCUMENT';
export const OPEN_WINDOW = 'OPEN_WINDOW';
export const UPDATE_DOCUMENT_VIEW_MODEL = 'UPDATE_DOCUMENT_VIEW_MODEL';
export const LOAD_REFERENCES_TO_DOCUMENT = 'LOAD_REFERENCES_TO_DOCUMENT';
export const UPDATE_ASIDE_VIEW_MODEL = 'UPDATE_ASIDE_VIEW_MODEL';
export const FAILED_LOADING_DOCUMENT = 'FAILED_LOADING_DOCUMENT';
export const PROPOSED_DELETION_VALIDATION_ERROR = 'PROPOSED_DELETION_VALIDATION_ERROR';
export const REMOVE_RELATION = 'REMOVE_RELATION';
export const SET_PRIVATE_STATE = 'SET_PRIVATE_STATE';
export const UPDATE_PRIVATE_STATE = 'UPDATE_PRIVATE_STATE';
export const SET_DOCUMENT_PROPOSALS = 'SET_DOCUMENT_PROPOSALS';
export const SET_COLLAPSED_NODES = 'SET_COLLAPSED_NODES';
export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';
export const CLEAR_HOVER_ON_COLLAPSE = 'CLEAR_HOVER_ON_COLLAPSE';
export const LOAD_DOCUMENT_AUTHORS = 'LOAD_DOCUMENT_AUTHORS';
export const SET_DOCUMENT_AUTHORS = 'SET_DOCUMENT_AUTHORS';
export const LOAD_NAMED_SETS = 'LOAD_NAMED_SETS';
export const SET_NAMED_SETS = 'SET_NAMED_SETS';
export const UPDATE_NAMED_SETS = 'UPDATE_NAMED_SETS';
export const SELECT_NAMED_SETS = 'SELECT_NAMED_SETS';
export const RESET_NAMED_SETS = 'RESET_NAMED_SETS';
export const LOAD_LABELS = 'LOAD_LABELS';
export const SET_LABELS = 'SET_LABELS';
export const LOAD_SUBJECTS = 'LOAD_SUBJECTS';
export const SET_SUBJECTS = 'SET_SUBJECTS';
export const ADD_IMAGE_ATTACHMENT_FILE = 'ADD_IMAGE_ATTACHMENT_FILE';
export const UPDATE_WEBSITE_CONFIGURATION = 'UPDATE_WEBSITE_CONFIGURATION';
export const REMOVE_WEBSITE_CONFIGURATION = 'REMOVE_WEBSITE_CONFIGURATION';
export const INIT_WEBSITE_CONFIGURATION = 'INIT_WEBSITE_CONFIGURATION';
export const SET_WEBSITE_CONFIGURATION = 'SET_WEBSITE_CONFIGURATION';
export const SET_ALL_WEBSITES = 'SET_ALL_WEBSITES';
export const UPDATE_FACET_REFERENCE_FRAMES = 'UPDATE_FACET_REFERENCE_FRAMES';
export const INIT_WEBSITE_THEME_REFERENCE_FRAMES = 'INIT_WEBSITE_THEME_REFERENCE_FRAMES';
export const SET_WEBSITE_THEME_REFERENCE_FRAMES = 'SET_WEBSITE_THEME_REFERENCE_FRAMES';
export const INIT_WHOLE_DOCUMENT_WEBSITE_THEME_REFERENCE_FRAMES =
  'INIT_WHOLE_DOCUMENT_WEBSITE_THEME_REFERENCE_FRAMES';
export const LOAD_REFERENCE_FRAME_EXTERNAL_OPTIONS = 'LOAD_REFERENCE_FRAME_EXTERNAL_OPTIONS';
export const SET_REFERENCE_FRAME_EXTERNAL_OPTIONS = 'SET_REFERENCE_FRAME_EXTERNAL_OPTIONS';
export const INIT_FIELD_CHOICES = 'INIT_FIELD_CHOICES';
export const SET_FIELD_CHOICES = 'SET_FIELD_CHOICES';
export const INIT_LLINKID_THEME_REFERENCES = 'INIT_LLINKID_THEME_REFERENCES';
export const SET_LLINKID_THEME_REFERENCES = 'SET_LLINKID_THEME_REFERENCES';
export const EXPAND_LLINKID_GOAL_RELATIONS = 'EXPAND_LLINKID_GOAL_RELATIONS';
export const SET_EXPANDED_LLINKID_GOAL_RELATIONS = 'SET_EXPANDED_LLINKID_GOAL_RELATIONS';
export const GET_ALL_LLINKID_CURRICULUMS = 'GET_ALL_LLINKID_CURRICULUMS';
export const SET_ALL_LLINKID_CURRICULUMS = 'SET_ALL_LLINKID_CURRICULUMS';
export const LOAD_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS =
  'LOAD_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS';
export const SET_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS =
  'SET_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS';
export const LOAD_LLINKID_CURRICULUM_GOALS = 'LOAD_LLINKID_CURRICULUM_GOALS';
export const SET_LLINKID_CURRICULUM_GOALS = 'SET_LLINKID_CURRICULUM_GOALS';
export const FILL_LLINKID_CURRICULUM_GOALS_COMPLETE_IDENTIFIER =
  'FILL_LLINKID_CURRICULUM_GOALS_COMPLETE_IDENTIFIER';
export const LOAD_LLINKID_ODET_GOALS = 'LOAD_LLINKID_ODET_GOALS';
export const SET_LLINKID_ODET_GOALS = 'SET_LLINKID_ODET_GOALS';
export const FILL_LLINKID_ODET_GOALS_COMPLETE_IDENTIFIER =
  'FILL_LLINKID_ODET_GOALS_COMPLETE_IDENTIFIER';
export const GET_EDUCATIONAL_ACTIVITY_TYPES = 'GET_EDUCATIONAL_ACTIVITY_TYPES';
export const SET_EDUCATIONAL_ACTIVITY_TYPES = 'SET_EDUCATIONAL_ACTIVITY_TYPES';
export const EXPAND_RELATIONS = 'EXPAND_RELATIONS';
export const FILL_RELATIONS_WITH_EXPANDED_RESOURCES = 'FILL_RELATIONS_WITH_EXPANDED_RESOURCES';
export const SET_EXPANDED_RELATIONS = 'SET_EXPANDED_RELATIONS';
export const EXPAND_RELATIONS_TO = 'EXPAND_RELATIONS_TO';
export const SET_EXPANDED_RELATIONS_TO = 'SET_EXPANDED_RELATIONS_TO';
export const EXPAND_RELATIONS_FROM = 'EXPAND_RELATIONS_FROM';
export const SET_EXPANDED_RELATIONS_FROM = 'SET_EXPANDED_RELATIONS_FROM';
export const INIT_ZILL_ODET_CURRICULUM_DOCUMENT = 'INIT_ZILL_ODET_CURRICULUM_DOCUMENT';
export const SET_ZILL_ODET_CURRICULUM = 'SET_ZILL_ODET_CURRICULUM';
export const LOAD_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS =
  'LOAD_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS';
export const SET_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS = 'SET_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS';
export const ADD_ZILL_ILLUSTRATION = 'ADD_ZILL_ILLUSTRATION';
export const REMOVE_ZILL_ILLUSTRATION_RELATION = 'REMOVE_ZILL_ILLUSTRATION_RELATION';
export const EXPAND_ZILL_GOAL_SELECTIONS = 'EXPAND_ZILL_GOAL_SELECTIONS';
export const SET_EXPANDED_ZILL_GOAL_SELECTIONS = 'SET_EXPANDED_ZILL_GOAL_SELECTIONS';
export const GET_ALL_OF_TYPE = 'GET_ALL_OF_TYPE';
export const SET_ALL_OF_TYPE = 'SET_ALL_OF_TYPE';
export const GET_REFERENCE_FRAME = 'GET_REFERENCE_FRAME';
export const SET_REFERENCE_FRAME = 'SET_REFERENCE_FRAME';
export const LOAD_TERM_REFERENCES = 'LOAD_TERM_REFERENCES';
export const SET_TERM_REFERENCES = 'SET_TERM_REFERENCES';
export const ADD_TERM_REFERENCE = 'ADD_TERM_REFERENCE';
export const REMOVE_TERM_REFERENCE = 'REMOVE_TERM_REFERENCE';
export const LOAD_EXTERNAL_DOCUMENT_SECTIONS = 'LOAD_EXTERNAL_DOCUMENT_SECTIONS';
export const SET_EXTERNAL_DOCUMENT_SECTIONS = 'SET_EXTERNAL_DOCUMENT_SECTIONS';
export const REMOVE_REFERENCE_GROUP_REFERENCES = 'REMOVE_REFERENCE_GROUP_REFERENCES';
export const CHANGE_MODE = 'CHANGE_MODE';
export const TOGGLE_SUBMIT_SUGGESTIONS_OF_GROUP = 'TOGGLE_SUBMIT_SUGGESTIONS_OF_GROUP';
export const SUBMIT_SUGGESTIONS = 'SUBMIT_SUGGESTIONS';
export const SUGGESTIONS_SUBMITTED = 'SUGGESTIONS_SUBMITTED';
export const REJECT_SUGGESTIONS = 'REJECT_SUGGESTIONS';
export const SUGGESTIONS_REJECTED = 'SUGGESTIONS_REJECTED';
export const ACCEPT_SUGGESTIONS = 'ACCEPT_SUGGESTIONS';
export const SUGGESTIONS_ACCEPTED = 'SUGGESTIONS_ACCEPTED';
export const CANCEL_SUGGESTIONS = 'CANCEL_SUGGESTIONS';
export const SUGGESTIONS_CANCELLED = 'SUGGESTIONS_CANCELLED';
export const HOVER_PROPOSAL_LINE = 'HOVER_PROPOSAL_LINE';
export const CLEAR_HOVER_PROPOSAL_LINE = 'CLEAR_HOVER_PROPOSAL_LINE';
export const CLOSE_ASIDE = 'CLOSE_ASIDE';
export const BACK_TO_DOCUMENTS_LIST = 'BACK_TO_DOCUMENTS_LIST';
export const OPEN_SUBMIT_SUGGESTIONS_MODAL = 'OPEN_SUBMIT_SUGGESTIONS_MODAL';
export const CLOSE_SUBMIT_SUGGESTIONS_MODAL = 'CLOSE_SUBMIT_SUGGESTIONS_MODAL';
export const SEND_SUBMITTED_SUGGESTIONS_EMAIL = 'SEND_SUBMITTED_SUGGESTIONS_EMAIL';
export const CALCULATE_SUGGESTIONS_TO_SUBMIT = 'CALCULATE_SUGGESTIONS_TO_SUBMIT';
export const CALCULATE_SUGGESTIONS_TO_REVIEW = 'CALCULATE_SUGGESTIONS_TO_REVIEW';
export const OPEN_REVIEW_SUGGESTIONS_MODAL = 'OPEN_REVIEW_SUGGESTIONS_MODAL';
export const CLOSE_REVIEW_SUGGESTIONS_MODAL = 'CLOSE_REVIEW_SUGGESTIONS_MODAL';
export const SET_EXPANDED_PROPOSALS_CREATORS = 'SET_EXPANDED_PROPOSALS_CREATORS';
export const SET_PROPOSALS_EXTERNAL_CONTENT = 'SET_PROPOSALS_EXTERNAL_CONTENT';
export const SET_EXPANDED_ASIDE_COMPONENTS_VALUES = 'SET_EXPANDED_ASIDE_COMPONENTS_VALUES';
export const EXPAND_RESOURCES = 'EXPAND_RESOURCES';
export const SET_EXPANDED_RESOURCES_VALUES = 'SET_EXPANDED_RESOURCES_VALUES';
export const GET_TREE_AS_LEAF_FOR_RESOURCES = 'GET_TREE_AS_LEAF_FOR_RESOURCES';
export const SET_TREE_AS_LEAF_FOR_RESOURCES = 'SET_TREE_AS_LEAF_FOR_RESOURCES';
export const ADD_IMAGE_IN_GROUP = 'ADD_IMAGE_IN_GROUP';
export const EDIT_IMAGE_IN_GROUP = 'EDIT_IMAGE_IN_GROUP';
export const ADD_REFERENCE_FROM_NODE = 'ADD_REFERENCE_FROM_NODE';
export const ADD_ATTACHMENT_TO_NODE = 'ADD_ATTACHMENT_TO_NODE';
export const REPLACE_CONTENT_ATTACHMENT_OF_GLOBAL_DOCUMENT =
  'REPLACE_CONTENT_ATTACHMENT_OF_GLOBAL_DOCUMENT';
export const ADD_GLOBAL_DOCUMENT_RELATION = 'ADD_GLOBAL_DOCUMENT_RELATION';
export const EDIT_GLOBAL_DOCUMENT_RELATION = 'EDIT_GLOBAL_DOCUMENT_RELATION';
export const REPLACE_GLOBAL_DOCUMENT_RELATION_WITH_ATTACHMENT =
  'REPLACE_GLOBAL_DOCUMENT_RELATION_WITH_ATTACHMENT';
export const IMPORT_DOCUMENT_IN_SECTION = 'IMPORT_DOCUMENT_IN_SECTION';
export const PATCH_NODE_ATTACHMENT = 'PATCH_NODE_ATTACHMENT';
export const REMOVE_NODE_ATTACHMENT = 'REMOVE_NODE_ATTACHMENT';
export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_ABILITIES = 'UPDATE_ABILITIES';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATED = 'AUTHENTICATED';
export const START_DRAG_NODE = 'START_DRAG_NODE';
export const STOP_DRAG_NODE = 'STOP_DRAG_NODE';
export const MOVE_SELECTIONS_TO_PARENT_NODE = 'MOVE_SELECTIONS_TO_PARENT_NODE';
export const MOVE_ATTACHMENT_POSITION = 'MOVE_ATTACHMENT_POSITION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REFRESH_NEWSLETTER = 'REFRESH_NEWSLETTER';
export const LOAD_TEASERS_SUCCESS = 'LOAD_TEASERS_SUCCESS';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const SET_NEWSLETTER_SETTINGS = 'SET_NEWSLETTER_SETTINGS';
export const GET_NEWSLETTER_SETTINGS = 'GET_NEWSLETTER_SETTINGS';
export const LOAD_PREVIOUS_VERSION = 'LOAD_PREVIOUS_VERSION';
export const SET_PREVIOUS_VERSION = 'SET_PREVIOUS_VERSION';

export const ADD_TEASER = 'ADD_TEASER';
export const ADD_TEASER_SUCCESS = 'ADD_TEASER_SUCCESS';
export const PATCH_DATE_TO_SEND = 'PATCH_DATE_TO_SEND';
export const PATCH_THEMES = 'PATCH_THEMES';
export const VALIDATE_EXTERNAL_RELATION = 'VALIDATE_EXTERNAL_RELATION';
export const OPEN_SELECT_EXTERNAL_RELATION_MODAL = 'OPEN_SELECT_EXTERNAL_RELATION_MODAL';
export const TRIGGER_PRELOAD_ACTIONS = 'ACTION_TYPES.TRIGGER_PRELOAD_ACTIONS';
export const OPEN_NOT_APPLICABLE_PROPOSALS_MODAL = 'OPEN_NOT_APPLICABLE_PROPOSALS_MODAL';
export const CLOSE_NOT_APPLICABLE_PROPOSALS_MODAL = 'CLOSE_NOT_APPLICABLE_PROPOSALS_MODAL';

export const SET_SELECTED_ZILL_CURRICULUM_AS_FRAME = 'SET_SELECTED_ZILL_CURRICULUM_AS_FRAME';
export const EDIT_ZILL_CURRICULUM_AS_FRAME = 'EDIT_ZILL_CURRICULUM_AS_FRAME';

export const GET_IS_INCLUDED_IN_PRO_THEME = 'GET_IS_INCLUDED_IN_PRO_THEME';
export const SET_IS_INCLUDED_IN_PRO_THEME = 'SET_IS_INCLUDED_IN_PRO_THEME';

export const GET_SECONDARY_EDUCATION_TYPES = 'GET_SECONDARY_EDUCATION_TYPES';
export const SET_SECONDARY_EDUCATION_TYPES = 'SET_SECONDARY_EDUCATION_TYPES';
export const PATCH_SECONDARY_EDUCATION_TYPES = 'PATCH_SECONDARY_EDUCATION_TYPES';

export const INIT_WEB_FACETS = 'INIT_WEB_FACETS';
export const GET_FACET_SOURCE_WEB_CONFIGS = 'GET_FACET_SOURCE_WEB_CONFIGS';
export const SET_FACET_SOURCE_WEB_CONFIGS = 'SET_FACET_SOURCE_WEB_CONFIGS';
export const PATCH_EXPIRY_DATE = 'PATCH_EXPIRY_DATE';
