import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { llinkidGoalTypes } from '@store/constants/constants';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

import './asideGoalType.scss';

const AsideGoalType = ({ label = '' }) => {
  const $translate = getAngularService('$translate');
  const editDocument = useSelector((state) => state.document.viewModel?.aside?.editDocument);
  const readOnly = useSelector((state) => state.document.viewModel?.isReadOnlyDocument);
  const dispatch = useDispatch();

  const options = useMemo(
    () =>
      llinkidGoalTypes.map((type) => ({
        label: $translate.instant(`components.llinkid.goalType.types.${type.toLocaleLowerCase()}`),
        key: type,
      })),
    [$translate]
  );

  const goalType = options.find((t) => editDocument.llinkidGoalType?.value === t.key);

  const onChange = (selection) => {
    const mandatory = selection.key === 'REGULAR' || selection.key === 'EXTRA';
    if (selection?.key) {
      dispatch(
        DOCUMENT_ACTIONS.patchNodeAction(editDocument.key, {
          llinkidGoalType: selection.key,
          mandatory,
        })
      );
    }
  };

  return (
    <div className="aside-goal-type col-md-12">
      <label className="control-label">{$translate.instant(label)}</label>
      <KovResourcePicker
        localData={options}
        display="label"
        defaultValue={goalType}
        clearable={false}
        disabled={readOnly}
        disableSearch={true}
        style={{ option: (provided) => ({ ...provided, cursor: 'pointer' }) }}
        onChange={onChange}
      />
    </div>
  );
};

AsideGoalType.propTypes = {
  label: string,
};

export default AsideGoalType;
