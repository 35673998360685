import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const LLINKID_GOAL_EXPLANATION: BuildingBlockNodeConfig = {
  information: {
    single: 'Duiding',
    plural: 'Duidingen',
  },
  node: {
    type: ContentType.LLINKID_GOAL_EXPLANATION,
  },
  edit: [
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.WARNING,
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'previousVersionItems',
      type: 'previousVersionItems',
      label: 'components.llinkid.previousVersionItems.title',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidPreviousVersionModal',
        listColumn: 'description',
        placeholder: 'components.llinkid.explanationReplaces.placeholder',
        relation: 'components.llinkid.explanationReplaces.relation',
        errorMessage: 'components.llinkid.explanationReplaces.error.invalidRelation',
        type: 'LLINKID_GOAL_EXPLANATION',
      },
    },
  ],
  buildingBlocks: [],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_GOAL_EXPLANATION;
