import { ContentType } from '@generalTypes/apiTypes';
import {
  AudienceTabComponent,
  BuildingBlock,
  EditComponent,
  NodeType,
  RequiredType,
} from '@nodeTypeConfig/configTypes';
import {
  coverageHrefsToString,
  entityHrefsArrayToString,
  getAccessRightsToStringFunction,
  themeHrefsToString,
} from '@nodeTypeConfig/helpers/editComponentHelpers';
import {
  authorsComponent,
  identifierComponent,
  importanceComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { contentTypesKey, dienstverleningKovKey } from '@store/constants/constants';

export const proDefaultBuildingBlocks: Array<BuildingBlock> = [
  { type: NodeType.SECTION },
  { type: NodeType.PARAGRAPH },
  { type: NodeType.IMAGE },
  { type: NodeType.ATTACHMENTS_GROUP },
  { type: NodeType.IMAGE_GROUP },
  { type: NodeType.VIDEO },
  { type: NodeType.QUOTE },
  { type: NodeType.EXAMPLE },
  { type: NodeType.SUMMARY },
  { type: NodeType.WORD_IMPORT },
];

export const proThemeTocTypes: Array<NodeType> = [
  NodeType.PRO_THEME_SECTION,
  NodeType.PRO_TEXT_PAGE_TILE,
  NodeType.PRO_DATABASE_TILE,
  NodeType.PRO_BLOG_TILE,
  NodeType.PRO_DOWNLOAD_PAGE_TILE,
  NodeType.PRO_FAQ_PAGE_TILE,
  NodeType.PRO_CURRICULUM_PAGE_TILE,
  NodeType.PRO_TEMPORARY_PAGE_TILE,
  NodeType.PRO_BLOG_ITEM,
  NodeType.PRO_DATABASE_ITEM,
  NodeType.FAQ_GROUP,
];

export const proContactsComponent: EditComponent = {
  component: 'contacts',
  property: 'contacts',
  label: 'Contactpersonen',
  valueToString: (c, a) => entityHrefsArrayToString(c.contacts, a),
};

export const proThumbnailComponent: EditComponent = {
  component: 'image',
  property: 'attachments',
  label: 'Afbeelding',
  options: {
    type: 'THUMBNAIL',
    crop: true,
    fields: ['alt'],
  },
};

export const proPageAttachmentsComponents: EditComponent = {
  component: 'attachmentsGroup',
  label: 'Bijlagen',
  hiddenChildren: {
    containerType: ContentType.ATTACHMENTS_GROUP,
    items: [
      {
        type: ContentType.ATTACHMENT,
        label: 'bijlage',
      },
      {
        type: ContentType.UNSTRUCTURED_DOCUMENT,
        label: 'gedeeld document',
      },
    ],
  },
  options: { global: true },
};

export const proDocumentStandardComponents: Array<EditComponent> = [
  titleComponent,
  {
    component: 'description',
    property: 'description',
    label: 'Inleiding',
    fullLabel: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    required: RequiredType.WARNING,
  },
  {
    ...authorsComponent,
    label: 'Themaverantwoordelijke',
  },
  {
    ...proThumbnailComponent,
    label: 'Tegelfoto',
    width: '6',
  },
  publishComponent,
  {
    component: 'themes',
    label: "Thema's",
    options: {
      referenceFrameKey: dienstverleningKovKey,
      types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
      highlightOldCurrItems: true,
    },
    valueToString: themeHrefsToString,
  },
  {
    component: 'curriculumSelector',
    label: "Leerplanthema's",
    options: {
      // do not send main curriculum themes
      filter: (theme) => {
        return (
          theme.type !== ContentType.CURRICULUM_THEME ||
          !theme.$$children.some((c) => c.type === ContentType.CURRICULUM_THEME)
        );
      },
    },
  },
  {
    ...proContactsComponent,
    width: '12',
  },
];

export const proAliasesComponent: EditComponent = {
  component: 'subjects',
  label: 'Aliassen',
  valueToString: (c) => (c.subjects ? c.subjects.join(', ') : ''),
  options: {
    rawLabel: true,
  },
};

export const proTileStandardComponents: Array<EditComponent> = [
  {
    ...identifierComponent, // can be removed in the future, not used (confirmed by Isabelle)
    width: '2',
  },
  {
    ...titleComponent,
    width: '10',
  },
  {
    component: 'description',
    property: 'shortdescription',
    width: '12',
    label: 'Tegeltekst',
    maxLength: 80,
    required: RequiredType.WARNING,
    showInAncestorTypes: [NodeType.PRO_THEME_HOME_FULL, NodeType.PRO_THEME_HOME_TEXT],
  },
  {
    component: 'description',
    property: 'description',
    width: '12',
    label: 'Inleiding',
    fullLabel: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    required: RequiredType.WARNING,
  },
  {
    ...importanceComponent, // can be removed: this was only for the menu not for pro themes (and even there it is no longer used in pro)
    width: '6',
  },
  {
    ...authorsComponent,
    width: '6',
  },
  {
    ...proContactsComponent,
    width: '12',
  },
  {
    ...proThumbnailComponent,
    label: 'Tegelfoto',
    width: '6',
    showInAncestorTypes: [NodeType.PRO_THEME_HOME_FULL, NodeType.PRO_THEME_HOME_PICTURE],
    hideInAncestorTypes: [NodeType.PRO_THEME_HOME_TEXT],
  },
];

export const proLinksAndMenuReferences: Array<EditComponent> = [
  {
    component: 'menuItemReference',
    label: 'Navigeert naar',
    property: 'referenceRelation.to',
    options: {
      filter: {
        typeIn: 'STRUCTURED_DOCUMENT,SECTION',
        rootTag: [NodeType.WEBPAGE2],
        $$expandPathToRoot: true,
        orderBy: '$$meta.modified',
        descending: true,
      },
    },
  },
  {
    component: 'linkGroup',
    label: 'Links',
    width: '12',
    options: {
      type: 'group',
    },
    hiddenChildren: {
      containerType: ContentType.LINK_GROUP,
      items: [
        {
          type: ContentType.REFERENCE,
          label: 'link',
        },
      ],
    },
  },
];

export const proContentTypeForDatabasePicker: EditComponent = {
  component: 'themes',
  label: 'Databanktype',
  options: {
    referenceFrameKey: contentTypesKey,
    types: [ContentType.THEME],
  },
};

export const proThemeAudienceTab: Array<AudienceTabComponent> = [
  {
    component: 'namedSet',
    property: 'mainstructuresOuTypeCombinations',
    label: 'Onderwijsniveaus en instellingstypes',
    labelSingular: 'Onderwijsniveau en instellingstype',
    tag: 'mainstructure_outype_ext_comm',
    isInheritedProperty: true,
    // valueToString: msAndOuTypesHrefsToString, TODO
  },
  {
    component: 'coverage',
    property: 'coverage',
    label: "Regio's",
    labelSingular: 'Regio',
    valueToString: coverageHrefsToString,
    isInheritedProperty: true,
  },
];

export const proNewsletterAudienceTab: Array<AudienceTabComponent> = [
  ...proThemeAudienceTab,
  {
    component: 'namedSet',
    property: 'positions',
    label: 'Functies (doelgroep)',
    labelSingular: 'doelgroep',
    tag: 'doelgroepen',
    isInheritedProperty: true,
  },
];

export const proAccessRightsComponent: AudienceTabComponent = {
  component: 'accessRights',
  property: 'descendantsAccessRights',
  label: 'Afscherming',
  labelSingular: 'Afscherming',
  isInheritedProperty: true,
  valueToString: getAccessRightsToStringFunction('descendantsAccessRights'),
} as const;
