import { Content, ContentHref, ContentRelation } from '@generalTypes/apiTypes';
import { PrivateState } from '@generalTypes/personApiTypes';
import { ContentRecord } from '@newStore/documentApi/documentApiTypes';
import {
  add$$metaAndKey,
  getSortedChildHrefsFromRelations,
  parentChildRelationFilter,
} from '@newStore/genericHelpers';
import { NodeType, isHiddenNodeConfig } from '@nodeTypeConfig/configTypes';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { settings } from '../../config/settings';

export const createNewPrivateState = (
  documentHref: string,
  userHref: string,
  collapsedNodes: string[]
): PrivateState => ({
  ...add$$metaAndKey('/privatestates'),
  context: settings.privateState.component,
  state: {
    root: documentHref,
    version: 1,
    collapsedNodes,
    lastRead: new Date().toISOString(),
  },
  owner: {
    href: userHref,
  },
});

export const getVisibleTOCChildren = (
  contentNodeChildren: ContentHref[],
  content: ContentRecord,
  allNodeTypesMap: Record<ContentHref, NodeType>,
  typesFilterList: string[]
) => {
  const visibleChildren = contentNodeChildren.filter((childHref) => {
    const child = content[childHref];
    const type = allNodeTypesMap[childHref];

    if (child && ['MEDIUM', 'HIGH'].includes(child.importance) && typesFilterList.includes(type)) {
      return true;
    }
    return false;
  });

  return visibleChildren;
};

export const getAllDescendantRelationHrefs = (
  toRelationsMap: Record<string, Array<ContentRelation>>,
  href: ContentHref
): any => {
  // this has to be a recursive array
  const relations = toRelationsMap[href]
    ?.filter(parentChildRelationFilter)
    .sort((a, b) => (a.readorder || 0) - (b.readorder || 0));
  return relations.map((rel) => [
    rel.$$meta.permalink,
    ...getAllDescendantRelationHrefs(toRelationsMap, rel.from.href),
  ]);
};

export const getAllDescendantParentChildHrefs = (
  toRelationsMap: Record<string, Array<ContentRelation>>,
  href: ContentHref,
  excludeHrefs?: Set<ContentHref>
): Array<{ parentHref: ContentHref; childHref: ContentHref }> => {
  const childHrefs = getSortedChildHrefsFromRelations(toRelationsMap[href]);
  return childHrefs
    .filter((childHref) => !excludeHrefs || !excludeHrefs.has(childHref))
    .map((childHref) => [
      { parentHref: href, childHref },
      ...getAllDescendantParentChildHrefs(toRelationsMap, childHref, excludeHrefs),
    ])
    .flat();
};

export const getAllDescendantHrefs = (
  toRelationsMap: Record<string, Array<ContentRelation>>,
  href: ContentHref,
  excludeHrefs?: Set<ContentHref>
): ContentHref[] => {
  return getAllDescendantParentChildHrefs(toRelationsMap, href, excludeHrefs).map(
    (rel) => rel.childHref
  );
};

export const getHiddenChildrenHrefs = (
  href: ContentHref,
  toRelationsMap: Record<string, ContentRelation[]>,
  allNodeTypesMap: Record<ContentHref, NodeType>
): ContentHref[] => {
  const childHrefs = toRelationsMap[href]
    ?.filter(parentChildRelationFilter)
    .map((rel) => rel.from.href);

  const nodeTypeConfig = getNodeTypeConfig(allNodeTypesMap[href]);
  if (!isHiddenNodeConfig(nodeTypeConfig) && nodeTypeConfig.hideChildrenInDocument === true) {
    return childHrefs as ContentHref[];
  }
  return childHrefs.filter((childHref) => {
    if (!allNodeTypesMap[childHref]) {
      return false;
    }
    const childTypeConfig = getNodeTypeConfig(allNodeTypesMap[childHref]);
    if (isHiddenNodeConfig(childTypeConfig)) {
      return true;
    }
    return false;
  }) as ContentHref[];
};

/**
 * this function returns all hidden descendants of a node. and it will return it's hidden children if there are any.
 * if the href you send, has no hidden children, it will return an empty array.
 */
export const getHiddenDescendants = (
  href: ContentHref,
  toRelationsMap: Record<string, ContentRelation[]>,
  contentMap: Record<string, Content>,
  allNodeTypesMap: Record<ContentHref, NodeType>
): Content[] => {
  const hiddenChildrenHrefs = getHiddenChildrenHrefs(href, toRelationsMap, allNodeTypesMap);
  const hiddenChildren = hiddenChildrenHrefs
    .map((childHref) => contentMap[childHref])
    .filter(Boolean);
  return hiddenChildren
    .map((hiddenChild) => [
      hiddenChild,
      ...getHiddenDescendants(
        hiddenChild.$$meta.permalink,
        toRelationsMap,
        contentMap,
        allNodeTypesMap
      ),
    ])
    .flat();
};

export const getBuildingBlockType = (nodeType: NodeType) => {
  const nodeTypeConfig = getNodeTypeConfig(nodeType);
  if ('extends' in nodeTypeConfig && nodeTypeConfig.extends) {
    return nodeTypeConfig.extends;
  }
  return nodeType;
};
