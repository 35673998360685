import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

const LLINKID_GUIDING_PRINCIPLE: BuildingBlockNodeConfig = {
  information: {
    single: 'Krachtlijn',
    plural: 'Krachtlijn',
  },
  node: {
    type: ContentType.LLINKID_GUIDING_PRINCIPLE,
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
      options: {
        showMarkerButton: true,
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_GUIDING_PRINCIPLE;
