/* eslint-disable max-len */
import { formatDate } from '@newStore/genericHelpers';
import { get$$prefixForDocumentVmNode } from '@store/helpers/documentAsideHelpers';
import { isContentHref } from '@generalTypes/apiTypes';
import { getNodeTypeConfig } from '../../config/nodeTypeConfigurations';
import {
  flatTree,
  getImage,
  isResourceSupported,
  isResourceToBeExpanded,
  isAttachmentsGroupOnNodeLevelAllowed,
} from '../helpers/documentHelpers';
import { createDocumentTree } from '../createDocumentTree';
import { fillTreeNodeProposalViewModel } from './proposalViewModel';
import { accessRights } from '../helpers/accessRights';
import { documentTags } from '../constants/documentTags';
import { config as documentTypes } from '../constants/documentTypes';
import * as constants from '../constants/constants';

const getReference = (key, content, contentRelations) => {
  const relations = contentRelations.to[`/content/${key}`];
  if (relations) {
    const relationTo = relations.find(
      (relation) => relation.relationtype === 'IS_PART_OF' && !relation.deleteProposal
    );
    if (relationTo) {
      return content.get(relationTo.from.href);
    }
  }

  return undefined;
};

export const getLinkedContent = (node, state) => {
  if (node.type !== 'TEASER') {
    return undefined;
  }
  // LinkedContent is created multiple times because of expanding resources, this causes weird resource picker behavior.
  // This needs to be refactored to adding current resource picker values to expanded resources.
  if (state.resourcesToExpand.length !== 0 || state.resourcesToGetTreeAsLeaf.length !== 0) {
    const teaser = state.viewModel.flat && state.viewModel.flat.find((f) => f.key === node.key);
    return teaser ? teaser.linkedContent : undefined;
  }

  const callToAction = state.callToActions.get(node.key);
  const linkedContentType = state.linkedContentTypes.get(node.key);
  const reference = getReference(
    node.key,
    state.apiWithPendingChanges.content,
    state.apiWithPendingChanges.contentRelations
  );
  if (!reference) {
    return {
      callToAction: callToAction || 'Lees meer',
      type: linkedContentType || constants.teaserLinkOptions.NOT_LINKED.name,
    };
  }

  const relation = state.apiWithPendingChanges.contentRelations.from[
    `/content/${reference.key}`
  ].find((relation) => relation.relationtype === 'REFERENCES');
  const resource = relation.to.$$expanded;

  const callToActions = new Map(state.callToActions);
  callToActions.set(node.key, callToAction || reference.title);
  state.callToActions = callToActions;

  // EXTERNAL_URL
  if (!resource && !isContentHref(relation.to.href) && !relation.to.href.startsWith('/training')) {
    return {
      referenceKey: reference.key,
      referencedResourceHref: relation.to.href,
      callToAction: callToAction || reference.title,
      type: constants.teaserLinkOptions.EXTERNAL_URL.name,
      title: relation.to.href,
    };
  }

  if (!resource) {
    return undefined;
  }

  if (resource.$$meta.permalink.startsWith('/training')) {
    return {
      referenceKey: reference.key,
      referencedResourceHref: resource.$$meta.permalink,
      referencedResource: resource,
      callToAction: callToAction || reference.title,
      type: constants.teaserLinkOptions.EVENT.name,
      title: resource.title,
    };
  }

  // JOB_OFFER
  if (resource.tags && ['JOB_OFFER', documentTags.proNewsItem].includes(resource.tags[0])) {
    return {
      referenceKey: reference.key,
      referencedResourceKey: resource.key,
      referencedResourceHref: resource.$$meta.permalink,
      referencedResource: resource,
      callToAction: callToAction || reference.title,
      type: constants.teaserLinkOptions[resource.tags[0]].name,
      title: resource.title,
    };
  }

  // SHARED_MINI_DATABASE_ITEM
  if (resource.tags && ['SHARED_MINI_DATABASE_ITEM'].includes(resource.tags[0])) {
    return {
      referenceKey: reference.key,
      referencedResourceKey: resource.key,
      referencedResourceHref: resource.$$meta.permalink,
      referencedResource: resource,
      callToAction: callToAction || reference.title,
      type: constants.teaserLinkOptions[resource.tags[0]].name,
      title: resource.title,
    };
  }

  // WEBPAGE2
  const root = getRoot(resource, state);
  if (['STRUCTURED_DOCUMENT', 'SECTION'].includes(resource.type)) {
    return {
      referenceKey: reference.key,
      referencedResourceKey: root ? root.key : resource.key,
      referencedResourceHref: resource.$$meta.permalink,
      referencedResourceRoot: root || resource,
      referencedResource: root ? resource : undefined,
      callToAction: callToAction || reference.title,
      type: constants.teaserLinkOptions.WEBPAGE2.name,
      title: root ? `${root.title} - ${resource.title}` : resource.title,
      filter: {
        typeIn: 'SECTION',
        root: root ? root.$$meta.permalink : resource.$$meta.permalink,
        $$expandPathToRoot: true,
      },
    };
  }

  return undefined;
};

const getRoot = (resource, state) => {
  return resource.type === 'SECTION' && state.resourcesWithTreeAsLeaf[resource.$$meta.permalink]
    ? state.resourcesWithTreeAsLeaf[resource.$$meta.permalink].find(
        (r) => r.type === 'STRUCTURED_DOCUMENT'
      )
    : undefined;
};

export const getLinkedTeasers = (state) => {
  const references = [];
  const relations = state.apiWithPendingChanges.contentRelations.to[`/content/${state.key}`];
  if (relations) {
    const relationsTo = relations.filter((relation) => relation.relationtype === 'REFERENCES');
    relationsTo.forEach((e) => {
      const leaf = state.resourcesWithTreeAsLeaf[e.from.href];
      if (leaf) {
        references.push(leaf);
      }
    });
  }

  const linkedTeasers = references.flat().filter((r) => r.type === 'TEASER');

  return linkedTeasers.map((e) => {
    return {
      key: e.key,
      title: e.title,
    };
  });
};

export const getImagesInGroup = (node, state, removedChildren, settings) => {
  if (node.type === 'IMAGE_GROUP') {
    return node.$$children
      .filter(
        (c) =>
          (!removedChildren || !removedChildren.find((r) => r.endsWith(c.key))) &&
          c.$$type === 'IMAGE'
      )
      .map((c) => {
        const c$$attachments = get$$attachments(c, state, settings);
        return {
          key: c.key,
          imageAttachment: getImage(c$$attachments, 'ILLUSTRATION', 800),
          proposal:
            state.viewModel.flatWithHiddens &&
            state.viewModel.flatWithHiddens.find((n) => n.key === c.key)
              ? state.viewModel.flatWithHiddens.find((n) => n.key === c.key).proposal
              : null,
        };
      });
  }
  return [];
};

export const getExpandedFieldResources = (node, field, state) => {
  const allResources = [];

  if (node[field]) {
    const hrefs = [...new Set(node[field].value ? node[field].value : node[field])];
    hrefs.forEach((href) => {
      // In refactor we created some corrupt proposals when submitting this logline helps detecting corrupt nodes. Never went to acc or prod so this can be removed
      if (typeof href !== 'string') {
        console.warn(`node with property ${field} is not of type string`, node);
      }
      if (state.expandedResources[href]) {
        allResources.push(state.expandedResources[href]);
      } else if (
        isResourceToBeExpanded(
          href,
          state.resourcesToExpand,
          state.expandedResources,
          state.notFoundResourcesSet
        )
      ) {
        state.resourcesToExpand.push({
          href,
        });
      }
    });
  }

  return allResources;
};

export const getReferenceRelationsFrom = (node, state) => {
  const references = (
    state.apiWithPendingChanges.contentRelations.from[`/content/${node.key}`] || []
  ).filter((relation) => relation.relationtype === 'REFERENCES');

  const allReferences = [];
  references.forEach((rel) => {
    const resourceHref = rel.to.href;
    if (!rel.to.$$expanded) {
      if (state.expandedResources[resourceHref]) {
        allReferences.push({
          ...rel,
          to: { ...rel.to, $$expanded: state.expandedResources[resourceHref] },
        });
      } else if (
        state.viewModel.flatWithHiddens &&
        state.viewModel.flatWithHiddens.find((n) => `/content/${n.key}` === resourceHref)
      ) {
        // reference to a node of the current document
        const $$expanded = state.viewModel.flatWithHiddens.find(
          (n) => `/content/${n.key}` === resourceHref
        );
        allReferences.push({ ...rel, to: { ...rel.to, $$expanded } });
      } else if (!rel.to.href.startsWith('/')) {
        // to external url (not api href) we need it as reference aswell
        allReferences.push(rel);
      } else if (
        isResourceToBeExpanded(
          resourceHref,
          state.resourcesToExpand,
          state.expandedResources,
          state.notFoundResourcesSet
        )
      ) {
        state.resourcesToExpand.push({
          href: resourceHref,
        });
      }
    } else {
      allReferences.push(rel);
    }
  });

  return allReferences;
};

export const getReferenceRelationsTo = (node, state) => {
  const references = [];
  const relations = (
    state.apiWithPendingChanges.contentRelations.to[`/content/${node.key}`] || []
  ).filter((relation) => relation.relationtype === 'IS_PART_OF');

  if (relations) {
    relations.forEach((relation) => {
      const fromReferenceNode = state.apiWithPendingChanges.content.get(relation.from.href);
      if (fromReferenceNode && fromReferenceNode.type === 'REFERENCE') {
        references.push(fromReferenceNode);
      }
    });
  }

  // set references from expended resources, if not present queue to expand
  const allReferences = [];
  references.forEach((reference) => {
    const refRelations = (
      state.apiWithPendingChanges.contentRelations.from[`/content/${reference.key}`] || []
    ).filter(
      (relation) =>
        relation.relationtype === 'REFERENCES' &&
        relation.to.href &&
        isResourceSupported(relation.to.href)
    );

    refRelations.forEach((rel) => {
      const resourceHref = rel.to.href;
      // try to set the expanded resource in the 'to' relation or append to fetch it
      if (!rel.to.$$expanded) {
        if (state.expandedResources[resourceHref]) {
          allReferences.push({
            ...rel,
            to: { ...rel.to, $$expanded: state.expandedResources[resourceHref] },
          });
        } else if (
          isResourceToBeExpanded(
            resourceHref,
            state.resourcesToExpand,
            state.expandedResources,
            state.notFoundResourcesSet
          )
        ) {
          state.resourcesToExpand.push({
            href: resourceHref,
          });
        }
      } else {
        allReferences.push(rel);
      }
    });
  });

  return allReferences;
};

export const getWebsitesConfiguration = (node, state) => {
  return [...state.apiWithPendingChanges.webpages.values()]
    .filter((w) => w.source.href === `/content/${node.key}`)
    .map((w) => {
      const config = { ...w };
      if (!config.website.domain) {
        if (state.expandedResources[config.website.href]) {
          config.website = {
            ...config.website,
            ...state.expandedResources[config.website.href],
          };
        } else if (
          isResourceToBeExpanded(
            config.website.href,
            state.resourcesToExpand,
            state.expandedResources,
            state.notFoundResourcesSet
          )
        ) {
          state.resourcesToExpand.push({
            href: config.website.href,
          });
        }
      }
      if (!config.template.code) {
        config.template = (state.webtemplates || []).find(
          (t) => t.$$meta.permalink === config.template.href
        );
      }
      return config;
    });
};

export const getOdetIdentifier = (node) => {
  let upperIdentifiers = [];

  function readUpperPrefix(parent) {
    const $$prefix = get$$prefixForDocumentVmNode(parent);
    if ($$prefix) {
      upperIdentifiers.push($$prefix);
    }

    if (parent.$$parent && parent.$$parent.$$level > 2) {
      return readUpperPrefix(parent.$$parent);
    }
    return upperIdentifiers;
  }

  upperIdentifiers = readUpperPrefix(node.$$parent).reverse();

  const identifier =
    node.identifiers && node.identifiers.length > 0 ? node.identifiers.join(' ') : '';
  let nodePrefix = identifier;
  if (upperIdentifiers[1]) {
    nodePrefix = ` ${upperIdentifiers[1]}.${identifier}`;
  }

  return upperIdentifiers[0] + nodePrefix;
};

export const getDocumentReferencedTerms = (state) => {
  const localTerms = [...state.apiWithPendingChanges.content.values()].filter(
    (node) => node.$$type === 'TERM'
  );

  // global terms are those referenced from tree subparts but are not in the local terms list
  const globalTerms = state.termReferences.filter((term) => {
    return !localTerms.some((localTerm) => {
      return `/content/${localTerm.key}` === `/content/${term.key}`;
    });
  });

  return {
    local: localTerms,
    global: globalTerms,
  };
};

export const getDocumentTermOptions = (state, rootNode, localTerms = []) => {
  if (!state.allTerms) {
    return [];
  }

  if (rootNode && rootNode.type === 'LLINKID_CURRICULUM') {
    const filteredTerms = state.allTerms.filter((term) => {
      // check if it's a local term that belongs to the rootDocument
      const localTerm = term.$$relationsFrom.find((relation) => {
        return (
          relation.$$expanded.relationtype === 'IS_PART_OF' &&
          relation.$$expanded.to.href === rootNode.$$meta.permalink
        );
      });
      if (localTerm) {
        term.status = '(lokaal)';
      }

      // global term are ok
      if (!localTerm && term.tags && term.tags.includes('TERM')) {
        term.status = '(globaal)';
        return true;
      }
      return localTerm;
    });

    // try to add local terms recently created that are not stored in the api yet
    localTerms.forEach((lt) => {
      if (!state.allTerms.find((t) => t.key === lt.key)) {
        lt.status = '(lokaal)';
        filteredTerms.push(lt);
      }
    });

    return filteredTerms;
  }

  // avoid showing local terms
  return state.allTerms.filter((term) => {
    return !term.$$relationsFrom.find((relation) => {
      return relation.$$expanded.relationtype === 'IS_PART_OF';
    });
  });
};

/**
 * Find those creators in the given proposals that are different to me.
 * We need the expanded resource for those creators to get the name.
 * @param {*} proposals
 * @param {*} state
 */
export const otherCreatorsInSuggestions = (proposals, state) => {
  let otherCreators = [];
  const meHref = state.me ? state.me.$$meta.permalink : '';

  proposals.forEach((proposal) => {
    otherCreators = proposal.creators.reduce((list, creatorHref) => {
      if (
        creatorHref !== meHref &&
        !list.find((c) => creatorHref === c.$$meta.permalink) &&
        proposal.creators.includes(meHref)
      ) {
        if (state.expandedResources[creatorHref]) {
          list.push(state.expandedResources[creatorHref]);
        } else if (
          isResourceToBeExpanded(
            creatorHref,
            state.resourcesToExpand,
            state.expandedResources,
            state.notFoundResourcesSet
          )
        ) {
          state.resourcesToExpand.push({
            href: creatorHref,
          });
        }
      }
      return list;
    }, otherCreators);
  });

  return otherCreators.length
    ? otherCreators.map((c) => `${c.firstName} ${c.lastName}`).join(', ')
    : undefined;
};

/**
 * See web/js/store/genericHelpers for new version of getAttachemntUrl.
 * This is only used in the old code
 */
const getAttachmentUrl = (apisAndUrls, href) => {
  if (!href) {
    return undefined;
  }

  if (href.startsWith('/content')) {
    return apisAndUrls.contentApi + href;
  }

  if (href.startsWith('/proposals')) {
    return apisAndUrls.proposalApi + href;
  }

  return undefined;
};

export const get$$attachments = (node, state, settings) => {
  //
  const attachmentMap = new Map();
  const pendingForNode = state.apiPending.fileUploads.filter((o) => o.documentKey === node.key);
  [
    ...(node.attachments?.value ? node.attachments.value : node.attachments || []),
    ...(pendingForNode[0] ? pendingForNode[0].attachments : []),
  ].forEach((att) => {
    const attachment = { ...att };
    if (attachment.$$base64) {
      attachment.$$url = attachment.$$base64;
    } else {
      attachment.$$url = getAttachmentUrl(settings.apisAndUrls, attachment.href);
    }
    if (!attachmentMap.has(attachment.type)) {
      attachmentMap.set(attachment.type, {
        original: null,
        resized: [],
      });
    }

    const attachmentInMap = attachmentMap.get(attachment.type);
    if (attachment.resized) {
      attachmentInMap.resized.push(attachment);
    } else {
      attachmentInMap.original = attachment;
    }

    attachmentMap.set(attachment.type, attachmentInMap);
  });
  return attachmentMap;
};

/**
 * There are some nodes (for instance a "SECTION" node within a pro-theme with "BLOG" webconfiguration) that allows the user
 * to attach documents to the node itself (node "SECTION"). In the view this is represented with an attachmentsGroup component
 * that is shown in the aside of the node (node "SECTION").
 * Then, in the data, this is saved like this:
 *  root document > node "SECTION" > node "ATTACHMENTS_GROUP" > document/s attached
 * The property that differenciates the "ATTACHMENTS_GROUP"s in those situation is in the `tags` array.
 * In the case that we are talking about it has the value "PAGE_ATTACHMENTS_GROUP" instead of "INLINE_ATTACHMENTS_GROUP"
 *
 * The following code is returning the children of a given node taking all of this into account.
 * @param {
 *  node: nodeElement,
 *  fromAside: boolean
 * } opts
 * @returns children[]
 */
export const getNodeChildren = (opts) => {
  const { node, fromAside } = opts;

  const attachmentsGroupOnNodeLevelAllowed = isAttachmentsGroupOnNodeLevelAllowed(node);
  const children = [];

  node.$$children.forEach((chld) => {
    if (attachmentsGroupOnNodeLevelAllowed) {
      const isGlobalAttachmentsGroup =
        chld.$$type === 'ATTACHMENTS_GROUP' &&
        chld.tags &&
        chld.tags.includes('PAGE_ATTACHMENTS_GROUP');
      if (isGlobalAttachmentsGroup) {
        children.push(chld.$$children);
        return;
      }
      if (fromAside) {
        // Rule Exception: When we open the ASIDE of one of those special nodes (root nodes that allow users to add
        // attachments directly on the root node) we should only show the attachments attached directly to that root node
        // so we SHOULD NOT add the other $$children that doesn't belong to that special "attachmentsGroup"
        return;
      }
    }
    children.push(chld);
  });

  return children.flat();
};
export const isGlobalDocument = (node) => {
  if (!node) {
    return false;
  }

  return (
    (node.$$type && node.$$type === documentTypes.globalDocument) ||
    (node.type && node.type === 'UNSTRUCTURED_DOCUMENT')
  );
};

const getProcessedAttachmentResources = (opts) => {
  const { children, state, settings } = opts;
  let count = 1;

  return children
    .map((child) => {
      child.isGlobalDocument = isGlobalDocument(child);
      child.deleteProposal = child.$$relation.deleteProposal;

      const child$$attachments = get$$attachments(child, state, settings);

      let childVM = child;
      // eslint-disable-next-line no-nested-ternary
      childVM.file =
        child$$attachments && child$$attachments.has('ATTACHMENT')
          ? child$$attachments.get('ATTACHMENT').original
          : child.attachments
          ? child.attachments.find((a) => a.type === 'CONTENT')
          : null;

      childVM.position = count;
      count += 1;
      // In refactor we created some corrupt proposals when submitting this logline helps detecting corrupt nodes. Never went to acc or prod so this can be removed
      if (childVM.creators && childVM.creators[0] && typeof childVM.creators[0] !== 'string') {
        console.warn('There is a proposal node with corrupt creators field', childVM);
      }
      if (childVM.creators) {
        childVM.authors = getExpandedFieldResources(childVM, 'creators', state) || [];
      }
      childVM = { ...childVM };
      // set proposal data to the child attachment in case it has it
      childVM.proposal = fillTreeNodeProposalViewModel(
        {
          key: childVM.key,
          $$relation: childVM.$$relation,
        },
        state
      );
      return childVM;
    })
    .sort((c1, c2) => {
      return c1.$$relation.readorder - c2.$$relation.readorder;
    });
};

export const getAttachmentResources = (opts) => {
  const { node, state, fromAside, settings } = opts;

  const children = getNodeChildren({ node, fromAside });
  return getProcessedAttachmentResources({ children, state, settings });
};

const getNumberOfModificationsChildren = (nodes, numberOfModifications, lastReadDate) => {
  if (nodes.length === 0) {
    return numberOfModifications;
  }
  nodes.forEach((n) => {
    const hasModifications = n.$$meta && new Date(n.$$meta.modified) > lastReadDate;
    if (hasModifications) {
      numberOfModifications += 1;
    }
    numberOfModifications = getNumberOfModificationsChildren(
      n.$$children.filter((c) => c.type !== 'REFERENCE'),
      numberOfModifications,
      lastReadDate
    );
  });
  return numberOfModifications;
};

export const getNumberOfModifications = (node, state) => {
  const numberOfModifications = 0;
  const lastReadDate = new Date(state.lastRead);
  return getNumberOfModificationsChildren(node.$$children, numberOfModifications, lastReadDate);
};

// check if the given node was modified after the last read date mark.
// it will check in the content resource modification and also in the related relations from/to it
export const getModificationAfterLastRead = (node, state) => {
  function gatherModifiedDatesFromRelations(relations) {
    if (!relations) {
      return [];
    }
    return relations
      .filter((relation) => relation.$$meta)
      .map((relation) => relation.$$meta.modified);
  }

  function gatherModifiedDatesFromChildren(parent) {
    return parent.$$children.filter((child) => child.$$meta).map((child) => child.$$meta.modified);
  }

  const lastReadDate = new Date(state.lastRead);

  // 1. gather modified date of node itself
  const contentModificationDate = node.$$meta?.modified;

  // 2. gather modified dates of relations from node (relocation)
  let modificationDates = [
    ...gatherModifiedDatesFromRelations(
      state.apiWithPendingChanges.contentRelations.from[`/content/${node.key}`]
    ),
  ];

  // 3. gather modified dates of hidden children
  const hiddenChildsModifiedDates = (node.$$children || []).reduce((list, c) => {
    const child = state.apiWithPendingChanges.content.get(`/content/${c.key}`);
    if (
      child &&
      (!child.$$typeConfig.node ||
        child.$$typeConfig.hideInDocument ||
        node.$$typeConfig.hideChildrenInDocument === true ||
        node.$$isCollapsed)
    ) {
      if (child.$$meta) {
        list.push(child.$$meta.modified);
      }
      list = [...list, ...gatherModifiedDatesFromChildren(child)];
    }
    return list;
  }, []);

  modificationDates = [...modificationDates, ...hiddenChildsModifiedDates];

  if (contentModificationDate) {
    modificationDates.push(contentModificationDate);
  }

  // filter modification dates that are smaller than lastReadDate
  modificationDates = modificationDates
    .filter((date) => new Date(date) > lastReadDate)
    .sort((a, b) => new Date(a) - new Date(b));

  return modificationDates.length
    ? {
        lastModification: formatDate(modificationDates[modificationDates.length - 1]),
      }
    : undefined;
};

const createReferenceFrameTree = (key, referenceFrame) => {
  const relationsTo = {};
  // note: Map used until we change how the tree function is implemented
  const nodesMap = new Map();
  referenceFrame.forEach((n) => {
    relationsTo[`/content/${n.key}`] = n.$$relationsTo.map((r) => r.$$expanded);
    nodesMap.set(n.$$meta.permalink, n);
  });

  return createDocumentTree(key, nodesMap, relationsTo);
};

export const createReferenceFrameOptions = (referenceFrame) => {
  return referenceFrame
    .filter((r) => r.type !== 'REFERENCE_FRAME')
    .map((r) => {
      if (!r.$$type) {
        return r;
      }

      const typeConfig = getNodeTypeConfig(r.$$type);
      return {
        ...r,
        display:
          (r.title || r.name) +
          (r.label ? ` ${r.label}` : '') +
          (typeConfig && typeConfig.themesDisplaySuffix
            ? ` ${typeConfig.themesDisplaySuffix}`
            : ''),
      };
    });
};

export const getReferenceFrameThemes = (state) => {
  const themeKey = constants.dienstverleningKovKey;
  if (!state.referenceFrame[themeKey] || state.referenceFrame[themeKey].length === 0) {
    return {};
  }

  const tree = createReferenceFrameTree(themeKey, state.referenceFrame[themeKey]);
  const itemsRecursiveChildren = flatTree(new Map(), tree);
  const options = createReferenceFrameOptions(state.referenceFrame[themeKey]);

  return {
    options,
    itemsRecursiveChildren,
    tree,
  };
};

export const getInheritedWebConfigurations = (node, state) => {
  function getOwnOrInheritedWebConfigurations(n) {
    if (n.websitesConfiguration && n.websitesConfiguration.length > 0) {
      return n.websitesConfiguration;
    }
    if (!n.websitesConfiguration) {
      const websitesConfiguration = getWebsitesConfiguration(n, state);
      if (websitesConfiguration && websitesConfiguration.length > 0) {
        return websitesConfiguration;
      }
    }
    return n.$$parent ? getOwnOrInheritedWebConfigurations(n.$$parent) : [];
  }
  return node.$$parent ? getOwnOrInheritedWebConfigurations(node.$$parent) : [];
};

export const getReferenceRelations = (key, apiWithPendingChanges) => {
  const relations = (apiWithPendingChanges.contentRelations.to[`/content/${key}`] || [])
    .filter((relation) => relation.relationtype === 'IS_PART_OF')
    .sort((a, b) => a.readorder - b.readorder);
  const references = relations.reduce((references, relation) => {
    const reference = apiWithPendingChanges.content.get(relation.from.href);
    if (reference && reference.type === 'REFERENCE') {
      references.push(reference);
    }
    return references;
  }, []);

  return references
    .map((reference) =>
      (apiWithPendingChanges.contentRelations.from[`/content/${reference.key}`] || [])
        .filter((relation) => relation.relationtype === 'REFERENCES')
        .map((relation) => {
          return {
            ...relation,
            description: reference.description,
            type:
              (relation.to.$$expanded &&
                (constants.referenceGroupTypes[relation.to.$$expanded.type] ||
                  constants.referenceGroupTypes[relation.to.$$expanded.$$meta.type])) ||
              constants.referenceGroupTypes.WEBPAGE2,
          };
        })
    )
    .flat();
};

export const getInheritedCoverage = (node) => {
  function getOwnOrInheritedCoverage(n) {
    if (n.coverage) {
      return n.coverage;
    }
    return n.$$parent ? getOwnOrInheritedCoverage(n.$$parent) : undefined;
  }
  return node.$$parent ? getOwnOrInheritedCoverage(node.$$parent) : undefined;
};

const getAccessRights = (node) => {
  if (!node) {
    return undefined;
  }

  const accessRightsToApply = isGlobalDocument(node)
    ? node.accessRights
    : node.descendantsAccessRights;

  if (accessRightsToApply) {
    return accessRightsToApply.value || accessRightsToApply;
  }

  return getAccessRights(node.$$parent);
};

export const getInheritedAccessRights = (node) => {
  if (!(node && node.$$parent)) {
    return undefined;
  }
  return getAccessRights(node.$$parent);
};

const namedSetsMap = new Map([
  ['mainstructure_outype_ext_comm', 'mainstructuresOuTypeCombinations'],
  ['doelgroepen', 'positions'],
]);

const tooltips = [
  { name: 'Middenkader', help: 'components.positions.help.middle' },
  { name: 'Ondersteunend personeel', help: 'components.positions.help.supporting' },
];

export const getNamedSetsOptions = (node, state) => {
  const namedSetsOptions = new Map();
  namedSetsMap.forEach((value, key) => {
    const options = state.namedSets.has(key)
      ? state.namedSets.get(key).map((v) => {
          const tooltip = tooltips.find((t) => t.name === v.name);
          const namedSets =
            (node[value] && node[value].value) || getFirstParentWithNamedSets(node, value) || [];
          return {
            ...v,
            selected: namedSets.includes(v.$$meta.permalink),
            tooltip: tooltip && tooltip.help,
          };
        })
      : [];
    namedSetsOptions.set(
      value,
      options.sort((a, b) => {
        if (a.name === 'Andere') {
          return 1;
        }
        if (b.name === 'Andere') {
          return -1;
        }
        return a.name.localeCompare(b.name);
      })
    );
  });

  return namedSetsOptions;
};

const getFirstParentWithNamedSets = (document, property) => {
  return (
    document.$$parent &&
    (document.$$parent[property] || getFirstParentWithNamedSets(document.$$parent, property))
  );
};

const isNamedSetsInconsistent = (document, property) => {
  if (document.$$isRoot) {
    return false;
  }
  const parent = getFirstParentWithNamedSets(document, property) || [];
  const child = document[property] ? document[property].value : [];

  return !child.every((l) => parent.includes(l));
};

export const getIsNamedSetsInconsistent = (document) => {
  const namedSetsInconsistent = new Map();
  namedSetsMap.forEach((value) => {
    namedSetsInconsistent.set(value, isNamedSetsInconsistent(document, value));
  });

  return namedSetsInconsistent;
};

export const getSelectedAccessRights = (node) => {
  let selectedAccessRights;
  const currentAccessRights = getAccessRights(node);

  if (currentAccessRights) {
    Object.values(accessRights).forEach((type) => {
      if (
        type.namedsets.length === currentAccessRights.length &&
        type.namedsets.every((ns) => currentAccessRights.includes(ns))
      ) {
        selectedAccessRights = type;
      }
    });
  } else {
    selectedAccessRights = accessRights.publiek;
  }
  return selectedAccessRights;
};

export const getIsAccessRightsInconsistent = (node) => {
  function getAccessRightPosition(accessRightsNamedsets) {
    if (!accessRightsNamedsets) {
      return -1;
    }
    return Object.values(accessRights).findIndex(
      (ar) =>
        ar.namedsets.length === accessRightsNamedsets.length &&
        ar.namedsets.every((ns) => accessRightsNamedsets.includes(ns))
    );
  }
  return node.selectedAccessRights
    ? getAccessRightPosition(node.selectedAccessRights.namedsets) <
        getAccessRightPosition(node.inheritedAccessRights)
    : false;
};
