import { ContentHref } from '@generalTypes/apiTypes';
import { ValidationErrorWithNode, ValidationStatus } from '@newStore/validation/validationTypes';
// eslint-disable-next-line
import ErrorWarningIcon from '!!raw-loader!@img/error-warning-icon.svg';
import ValidationErrors from './ValidationErrors';
import './ValidationIndicator.scss';

const ValidationIndicator: React.FC<{
  href: ContentHref;
  status: ValidationStatus;
  validationErrors: Array<ValidationErrorWithNode>;
  onIconClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ href, status, validationErrors, onIconClick }) => {
  return (
    <>
      <span
        className={`error-warning-icon ${status === 'INVALID' ? 'error' : 'warning'}`}
        dangerouslySetInnerHTML={{ __html: ErrorWarningIcon }}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (onIconClick) {
            onIconClick(event);
          }
        }}
      ></span>

      <div className={`validation-message-hover-container`}>
        <ValidationErrors
          href={href}
          validationErrors={validationErrors}
          defaultMinimized={false}
        />
      </div>
      {/* <div className={`validation-message-hover-container`} onClick={() => openAside()}>
        {validationErrors.length > 1 && (
          <span
            className={`show-more-icon ${hasErrorType(validationErrors) ? 'error' : 'warning'}`}
          >{`+${validationErrors.length - 1}`}</span>
        )}

        {validationErrors.map((error) => (
          <div key={error.code} className={`error-message type-${error.type.toLowerCase()}`}>
            <div className="error-message-content">
              <span dangerouslySetInnerHTML={{ __html: error.message }} />
            </div>
          </div>
        ))}
      </div> */}
    </>
  );
};

export default ValidationIndicator;
