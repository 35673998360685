import { IApiResouce, sriMeta } from './sriTypes';

export type OrganisationalUnitHref = `/sam/organisationalunits/${string}`;
export type VosOrganisationalUnitHref = `/organisations/${string}`;
export type StudyProgrammeHref = `/sam/commons/studyprogrammes/${string}`;
export type StudyProgrammeGroupHref = `/sam/commons/studyprogrammegroups/${string}`;
export type StudiegebiedHref = `/sam/commons/studiegebieden/${string}`;
export type SubjectHref = `/sam/commons/subjects/${string}`;
export type VakHref = `/vakken/${string}`;
export type EducationalActivityTypeHref = `/sam/commons/educationalactivitytypes/${string}`;

export const isOrganisationalUnitHref = (href: string): href is OrganisationalUnitHref =>
  !!href.match(
    /^\/sam\/organisationalunits\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
  );

export const isVosOrganisationalUnitHref = (href: string): href is VosOrganisationalUnitHref =>
  !!href.match(/^\/organisations\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);

export type VosOrganisationalUnit = {
  $$meta: sriMeta & { permalink: VosOrganisationalUnitHref; type: 'ORGANISATION' };
  key: string;
  $$name: string;
};

export type OrganisationalUnit = {
  $$meta: sriMeta & { permalink: OrganisationalUnitHref; type: 'ORGANISATIONAL_UNIT' };
  /**
   * GUID
   */
  key: string;
  /**
   * A more human readable code to distinguish between various instances. This code is automatically generated when a new organisational unit of type SCHOOLENTITY is created.
   */
  code?: string;
  /**
   * Type of the organisational unit
   */
  type:
    | 'SCHOOL'
    | 'GOVERNINGINSTITUTION'
    | 'CLB'
    | 'CLBLOCATION'
    | 'SCHOOLCOMMUNITY'
    | 'BOARDING'
    | 'GROUP'
    | 'CLASS'
    | 'SCHOOLENTITY'
    | 'ONDERWIJSKOEPEL'
    | 'TEACHER_GROUP'
    | 'SCHOOLENTITY_CLUSTER'
    | 'LEERSTEUNCENTRUM';
  /**
   * The different names by which the organizational unit is known.
   */
  names: {
    type: 'OFFICIAL' | 'SHORT' | 'CALL' | 'OTHER';
    /**
     * Name of the organisational unit.
     */
    value: string;
    /**
     * Day on which the resource initiates.
     */
    startDate: string;
    /**
     * Day on which the resource ends.
     */
    endDate?: string;
  }[];
  subsidised?: {
    value?: boolean;
    /**
     * Day on which the resource initiates.
     */
    startDate?: string;
    /**
     * Day on which the resource ends.
     */
    endDate?: string;
  }[];
  /**
   * The description of the organisation.
   */
  description?: string;
  /**
   * Day on which the resource initiates.
   */
  startDate: string;
  /**
   * Day on which the resource ends.
   */
  endDate?: string;
  /**
   * The name of the organisational unit for the current date.
   */
  $$displayName: string;
};

export type StudyProgrammeGroupRelation = {
  studyProgrammeGroup: {
    /**
     * The group this study programme is part of.
     */
    href: StudyProgrammeGroupHref;
  };
  /**
   * When did this programme become a part of the group?
   */
  startDate: string;
  /**
   * When did this programme stop being a part of the group?
   */
  endDate?: string;
};

export type StudyProgramme = {
  $$meta: sriMeta & { permalink: StudyProgrammeHref; type: 'COMMONS_STUDY_PROGRAMME' };
  /**
   * GUID
   */
  key?: string;
  type: {
    /**
     * A reference to type of study programme.
     */
    href: string;
  };
  /**
   * The name of the studyprogramme.
   */
  title: string;
  /**
   * A short (user-facing) description of this group.
   */
  description?: string;
  /**
   * When did this programme become valid?
   */
  startDate: string;
  /**
   * When did this programme cease to be valid?
   */
  endDate?: string;
  studyProgrammeGroups?: StudyProgrammeGroupRelation[];
  // $$curriculaDefinedBy?: {
  //   /**
  //    * Contains a reference to the OU for which this study programme defines a complete and unique set of mandatory curriculum goals
  //    */
  //   href: string;
  // };
};

export type StudyProgrammeGroup = {
  $$meta: sriMeta & { permalink: StudyProgrammeGroupHref; type: 'COMMONS_STUDY_PROGRAMME_GROUP' };
  /**
   * GUID
   */
  key?: string;
  type: {
    /**
     * A reference to type of study programme group.
     */
    href: string;
  };
  /**
   * The name of the group.
   */
  title: string;
  /**
   * A short (user-facing) description of this group.
   */
  description?: string;
  /**
   * When did this programme group become valid?
   */
  startDate: string;
  /**
   * When did this programme group cease to be valid?
   */
  endDate?: string;
};

export type Studiegebied = {
  $$meta: sriMeta & { permalink: StudiegebiedHref; type: 'COMMONS_STUDIEGEBIED' };
  /**
   * GUID
   */
  key?: string;
  name: string;
  studieGebiedGroep?: {
    /**
     * studiegebiedgroepen
     */
    href: string;
  };
};

export type EducationalActivityType = {
  $$meta: sriMeta & {
    permalink: EducationalActivityTypeHref;
    type: 'COMMONS_EDUCATIONAL_ACTIVITIES_TYPE';
  };
  /**
   * GUID
   */
  key?: string;
  code: string;
  name: string;
  description?: string;
};

export type Subject = {
  $$meta: sriMeta & { permalink: SubjectHref; type: 'COMMONS_SUBJECT' };
  /**
   * GUID
   */
  key?: string;
  definedBy?: {
    /**
     * Point at which OU defined this subject.
     */
    href: string;
  };
  /**
   * A short (2-3 letters) code that is recognized by teachers
   */
  code: string;
  /**
   * The full name of the subject
   */
  title: string;
  /**
   * An explanation of this subject.
   */
  description?: string;
  /**
   * When did this subject become valid?
   */
  startDate: string;
  /**
   * When did this subject cease to be valid?
   */
  endDate?: string;
};

export type Vak = {
  $$meta: sriMeta & { permalink: VakHref; type: 'VAK' };
  key: string;
  code: string;
  name: string;
  vakrubricering?: 'KV' | 'TV' | 'AV' | 'PV' | 'BG';
};
export const isOrganisationalUnit = (r: IApiResouce | undefined): r is OrganisationalUnit =>
  !!r && r.$$meta.type === 'ORGANISATIONAL_UNIT';

export const isVosOrganisation = (r: IApiResouce | undefined): r is VosOrganisationalUnit =>
  !!r && r.$$meta.type === 'ORGANISATION';
