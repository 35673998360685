import EncodedText from '@UI/encodedText/EncodedText';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { selectExternalGoalOrItem } from '@newStore/llinkid/llinkidSelectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';

import './asideLlinkidGoal.scss';

const AsideLlinkidGoal = ({ item, options, href }) => {
  const $translate = getAngularService('$translate');
  const [isLoading, setIsLoading] = useState(true);

  const goal = useSelector((state: RootState) => selectExternalGoalOrItem(state, href));

  useEffect(() => {
    if (goal) {
      setIsLoading(false);
    }
  }, [goal]);
  const loadingClass = isLoading ? 'loadingSlider' : '';

  return (
    <>
      <div className={`col-md-2 indented ${loadingClass}`}>
        <EncodedText>{goal?.identifier}</EncodedText>
      </div>
      {options.selectType && (
        <div className={`col-md-2 indented ${loadingClass}`}>
          {$translate.instant(`relationType.${item.relationtype}`)}
        </div>
      )}
      <div className={`col-md-${options.selectType ? '7' : '9'} indented ${loadingClass}`}>
        <span
          dangerouslySetInnerHTML={{
            __html: goal?.descriptionText,
          }}
        ></span>
      </div>
      <div className={`col-md-1 indented ${loadingClass}`}>
        {goal?.version &&
          $translate.instant('components.llinkid.goalRelations.fromVersion', {
            version: goal.version,
          })}
      </div>
    </>
  );
};

export default AsideLlinkidGoal;
