import Diff from 'text-diff';
import { Content, ContentHref, Proposal, isPatchChange } from '@generalTypes/apiTypes';
import { EditHiddenChildrenComponent } from '@nodeTypeConfig/configTypes';
import { getNodeType, getNodeTypeConfig } from '@nodeTypeConfig/index';
import { diffsToHtml, getProposalType } from './diffTextCalculator';
import { DiffMessage } from '../documentUITypes';
import { wrapNodeTypeLabel, wrapPropertyLabel } from './proposalHelpers';

export const getEditMessageForHiddenChild = (
  content: Content,
  oldContent: Content,
  readOrderChanged: boolean,
  nodeTypeLabel: string | undefined
): string => {
  if (content.title !== oldContent.title && (content.title || content.title)) {
    const diff = new Diff();
    // produces diff array
    const textDiffs = diff.main(oldContent.title || '', content.title || '');
    diff.cleanupSemantic(textDiffs);
    // produces a formatted HTML string
    return wrapNodeTypeLabel`${nodeTypeLabel}: "${diffsToHtml(textDiffs)}"${
      readOrderChanged ? ' volgorde' : ''
    } aangepast`;
  }
  return wrapNodeTypeLabel`${nodeTypeLabel} ${content.title ? `"${content.title}"` : ''}${
    readOrderChanged ? ' volgorde' : ''
  } aangepast`;
};

const getMessageForHiddenChild = (
  content: Content,
  rawContent: Content | undefined,
  proposal: Proposal,
  proposedHrefsToDelete: Array<ContentHref>,
  nodeTypeLabel: string
): string => {
  const type = getProposalType(content, proposal, proposedHrefsToDelete);
  if (type === 'DELETE') {
    const deletedValue = rawContent && rawContent.title;
    return wrapNodeTypeLabel`${nodeTypeLabel} ${
      deletedValue ? `"${deletedValue}"` : ''
    } verwijderd`;
  }

  // item created
  if (type === 'CREATE' || !rawContent) {
    return wrapNodeTypeLabel`${nodeTypeLabel} ${
      content.title ? `"${content.title}"` : ''
    } toegevoegd`;
  }

  const readOrderChanged = proposal.listOfRequestedChanges.some(
    (c) => isPatchChange(c) && c.patch.some((p) => p.path === '/readorder')
  );
  return getEditMessageForHiddenChild(content, rawContent, readOrderChanged, nodeTypeLabel);
};

export type HiddenDescendantWithProposal = {
  content: Content;
  rawContent: Content | undefined;
  proposal: Proposal;
};
export const getDiffMessagesForHiddenDescendants = (
  hiddenDescendants: Array<HiddenDescendantWithProposal>,
  component: EditHiddenChildrenComponent | undefined,
  proposedHrefsToDelete: Array<ContentHref>
): DiffMessage | null => {
  if (!component) {
    // catch for when NodeTypeConfig is not properly configured for this hidden child
    const nodeTypeConfig = getNodeTypeConfig(
      getNodeType(hiddenDescendants[0].content, undefined, null)
    );
    const nodeTypeLabel = nodeTypeConfig.information.plural;
    return {
      message: wrapNodeTypeLabel`${nodeTypeLabel}`,
      subItems: hiddenDescendants.map(({ content, rawContent, proposal }) =>
        getMessageForHiddenChild(
          content,
          rawContent,
          proposal,
          proposedHrefsToDelete,
          getNodeType(content, undefined, null)
        )
      ),
    };
  }
  // const hiddenChildConfigs = components.map((c) => c.hiddenChildren).flat();
  // if (hiddenChildConfigs.some((hcConfig) => hcConfig.containerType === contentType)) {
  //   return null;
  // }

  const subItems = hiddenDescendants.flatMap(({ content, rawContent, proposal }) => {
    if (component.hiddenChildren.containerType === content.type) {
      // if the hidden child is a container, we will ignore it. From a user perspective they didn't do any change to the container (fe for adding a link the LINK_GROUP container is also created but we don't want to show a message that this LINK_GROUP was also created)
      return [];
    }
    const hiddenChildLabel =
      component.hiddenChildren.items.find((item) => item.type === content.type)?.label || ''; // will never be the empty string
    return getMessageForHiddenChild(
      content,
      rawContent,
      proposal,
      proposedHrefsToDelete,
      hiddenChildLabel
    );
  });
  const propertyLabel = component.label;
  return {
    message: wrapPropertyLabel`${propertyLabel}`,
    hiddenChildKey: component.hiddenChildren.containerType,
    subItems,
  };
};
