import { ExtendedBuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import {
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proThumbnailComponent,
} from '../proGenericConfigProps';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { titleComponent, authorsComponent } from '../../helpers/genericComponents';
// Extends SECTION => A SECTION inside a Pro Theme and webConfig MINI_DATABASE_ITEM becomes a PRO_DATABASE_ITEM
const PRO_DATABASE_ITEM: ExtendedBuildingBlockNodeConfig = {
  information: {
    definiteArticle: true,
    description: 'A Database Item, can only appear as child of a blog',
    single: 'Databank item',
    plural: 'Databank Items',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  webTemplateHref: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a' as WebTemplateHref,
  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: proDefaultBuildingBlocks,
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
    {
      component: 'description',
      property: 'description',
      width: '12',
      label: 'Inleiding',
      fullLabel: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      required: RequiredType.WARNING,
    },
    {
      ...proThumbnailComponent,
      width: '12',
    },
    {
      ...authorsComponent,
      width: '12',
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    ...proLinksAndMenuReferences,
  ],
  confirmDelete: true,
};

export default PRO_DATABASE_ITEM;
