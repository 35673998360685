import {
  ContentHref,
  ContentType,
  ExternalUrl,
  InvalidExternalUrl,
  isContentHref,
  RedactieHref,
} from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectExternalContent,
  selectPathToRootFromExternalData,
} from '@newStore/externalData/externalDataSelectors';
import {
  createTypedSelector,
  isValidExternalUrl,
  isValidInternalReference,
  stripHtml,
} from '@newStore/genericHelpers';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';
import { selectExternalGoalOrItem } from '@newStore/llinkid/llinkidSelectors';
import { shallowEqual } from 'react-redux';

export type ContentReferenceLink = {
  shortTitle: string;
  title?: string | undefined;
  referenceLink: ExternalUrl | RedactieHref;
};

export const selectContentNodeReferences = createTypedSelector(
  [
    (state) => selectExternalContent(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state, href: ContentHref) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).from[href],
  ],
  (externalContent, content, relationsFrom) => {
    return relationsFrom
      .filter(
        (relation) =>
          !relation.$$meta.deleted &&
          relation.relationtype === 'REFERENCES' &&
          (relation.strength === 'MEDIUM' || !relation.strength)
      )
      .reduce((contentNodeReferences, currentRelation) => {
        /**
         * we only care about the to.href being either content OR an http(s) links.
         * it could also be an event or training, but these are not used for this functionality. (teasers / reference_groups)
         */
        if (!isValidInternalReference(currentRelation.to.href)) {
          if (isValidExternalUrl(currentRelation.to.href)) {
            contentNodeReferences.push(currentRelation.to.href);
          } else {
            contentNodeReferences.push(currentRelation.to.href as InvalidExternalUrl);
          }
        }

        const referenceItem =
          content[currentRelation.to.href] || externalContent[currentRelation.to.href];
        if (referenceItem) {
          contentNodeReferences.push(referenceItem.$$meta.permalink);
        }
        return contentNodeReferences;
      }, [] as Array<ContentHref | ExternalUrl | InvalidExternalUrl>);
  }
);
export const selectReferenceVM = createTypedSelector(
  [
    (state: RootState, reference: ContentHref | ExternalUrl | InvalidExternalUrl) => reference,
    (state: RootState) => selectExternalContent(state),
    (state: RootState, reference: ContentHref | ExternalUrl | InvalidExternalUrl) =>
      isContentHref(reference) ? selectContentItem(state, reference) : null,
    (state: RootState, reference: ContentHref | ExternalUrl | InvalidExternalUrl) =>
      isValidInternalReference(reference)
        ? selectPathToRootFromExternalData(state, reference)
        : null,
    (state: RootState, reference: ContentHref | ExternalUrl | InvalidExternalUrl) =>
      isValidInternalReference(reference) ? selectExternalGoalOrItem(state, reference) : null,
  ],
  (
    reference,
    externalContent,
    internalContentItem,
    externalPathToRoot,
    externalGoal
  ): ContentReferenceLink | null => {
    /**
     * case: external url
     */
    if (!isValidInternalReference(reference)) {
      if (isValidExternalUrl(reference)) {
        return {
          shortTitle: reference,
          referenceLink: reference,
        };
      }
      return null;
    }

    /**
     * case: reference to a llinkid goal (will always be external)
     */
    if (externalContent[reference]?.type === ContentType.LLINKID_GOAL && externalGoal) {
      const root = externalPathToRoot && externalPathToRoot[externalPathToRoot.length - 1];
      if (!root) {
        return null;
      }
      return {
        shortTitle: externalGoal.identifier,
        title: stripHtml(externalGoal.descriptionText),
        referenceLink: `/edit/${root.key}`,
      };
    }

    if (
      externalContent[reference]?.type === ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL &&
      externalGoal
    ) {
      const root = externalPathToRoot && externalPathToRoot[externalPathToRoot.length - 1];
      if (!root) {
        return null;
      }
      return {
        shortTitle: externalGoal.identifier,
        title: stripHtml(externalGoal.descriptionText),
        referenceLink: `/edit/${root.key}`,
      };
    }

    /**
     * case: reference to a zill goal
     */
    if (externalContent[reference]?.type === ContentType.CURRICULUM_ZILL_GENERIC_GOAL) {
      const root = externalPathToRoot && externalPathToRoot[externalPathToRoot.length - 1];
      if (!root) {
        return null;
      }
      return {
        shortTitle: getGoalIdentifier(externalPathToRoot.slice(0, -2)) || '',
        title: externalContent[reference].title,
        referenceLink: `/edit/${root.key}`,
      };
    }

    /**
     * case: reference to a pro-theme sub-section (expect this to always be external)
     */
    if (externalContent[reference]) {
      const root = externalPathToRoot && externalPathToRoot[externalPathToRoot.length - 1];
      if (!root) {
        return null;
      }
      return {
        shortTitle: stripHtml(externalContent[reference].title || externalContent[reference].type),
        referenceLink: `/edit/${root.key}`,
      };
    }

    const root = externalPathToRoot && externalPathToRoot[externalPathToRoot.length - 1];
    if (root && internalContentItem) {
      return {
        shortTitle: stripHtml(internalContentItem.title || internalContentItem.type),
        referenceLink: `/edit/${root.key}`,
      };
    }
    return null;
  }
);
export const selectReferenceDetails = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentNodeReferences(state, href),
    (state: RootState) => state,
  ],
  (references, state) => {
    return references
      .map((reference) => {
        return selectReferenceVM(state, reference);
      })
      .filter(Boolean) as ContentReferenceLink[];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual, // compares the reference of each item in the array
    },
  }
);
