import { ContentType } from '@generalTypes/apiTypes';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { coverageInconsistent } from '../../../validations/coverageInconsistent';
import { mainstructuresOuTypeCombinationsInconsistent } from '../../../validations/mainstructuresOuTypeCombinationsInconsistent';
import constants from '../../../reduxLoop/constants/constants';

export const REFERENCE_FRAME: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Ordeningskader',
    plural: 'Ordeningskaders',
    category: 'GENERAL',
  },
  node: {
    type: ContentType.REFERENCE_FRAME,
  },
  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [{ type: NodeType.THEME }],
  tocTypes: [NodeType.THEME],
  edit: [
    { component: 'title', label: 'Titel' },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      validations: [mainstructuresOuTypeCombinationsInconsistent],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      tab: constants.asideTabs.AUDIENCE,
      validations: [coverageInconsistent],
    },
  ],
  // audienceTab: proThemeAudienceTab, // probably a mistake that the audience tab ever was there
  isSearchable: true,
  isCreatable: true,
  allowSuggestions: false,
};

export default REFERENCE_FRAME;
