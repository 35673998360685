/* eslint-disable max-len */
import { escapeRegExp } from 'lodash';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideSecondaryEducationTypes {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const secondaryEducationTypesOptions = state.document.viewModel.aside.secondaryEducationTypes;

      return {
        secondaryEducationTypesOptions,
        selectedSecondaryEducationType:
          state.document.viewModel.aside.editDocument.selectedSecondaryEducationType,
        isLoading: !secondaryEducationTypesOptions,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
        editKey: state.document.viewModel.aside.editDocument.key,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(e, item) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchSecondaryEducationTypes(this.editKey, [item.$$meta.permalink])
    );
  }

  filterData(data, str) {
    if (str.length === 0) {
      return data;
    }
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  }
}

export default {
  template: require('./asideSecondaryEducationTypes.html'),
  controllerAs: 'ctrl',
  binding: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideSecondaryEducationTypes,
};
