import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ZILL_DEVELOPMENT_CONTENT: BuildingBlockNodeConfig = {
  information: {
    single: 'Inhoud',
    plural: 'Inhouden',
  },
  node: {
    type: ContentType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT,
  },
  buildingBlocks: [
    { type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT },
    {
      type: NodeType.CURRICULUM_ZILL_LEERLIJN,
      max: 1,
    },
    {
      type: NodeType.CURRICULUM_ZILL_LEERLIJN_AN,
      max: 1,
    },
  ],
  edit: [titleComponent],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_DEVELOPMENT_CONTENT;
