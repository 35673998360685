import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';

const WORD_IMPORT: BuildingBlockNodeConfig = {
  information: {
    single: 'Importeren',
    plural: 'Importeren',
    ribonIcon: require('../../../../img/buildingBlocks/WORD_IMPORT.svg'),
  },
  node: null,
  /* node: {
    type: NodeType.WORD_IMPORT, // @Stefan can this go? Does not make sense to try to match on a node with type WORD_IMPORT
  }, */
  buildingBlocks: [],
  edit: [],
  // isSearchable: false,
  isCollapsible: false,
  // ribbon: {
  //   importFromWord: true,
  // },
  onNewNodeDropped: {
    openAside: false,
  },
};

export default WORD_IMPORT;
