import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import {
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proThemeTocTypes,
} from '../proGenericConfigProps';
// Extends WEBPAGE2 => When adding STATIC WebConfig to a WEBPAGE2 it becomes a PRO_STATIC_PAGE
export const PRO_STATIC_PAGE: ExtendedRootNodeConfig = {
  information: {
    description:
      'Pro Static Page (webTemplate code STATIC) can only be a root root (single page pro theme). This is not really a structured document because it has mainly one big node with static html.',
    single: 'PRO.-Thema: Statische pagina',
    plural: "Statische pagina's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
  },
  extends: NodeType.WEBPAGE2,
  webTemplateHref: '/web/templates/a623e1c1-81a3-4de7-a231-281da633a9cc' as WebTemplateHref,
  tocTypes: proThemeTocTypes,
  buildingBlocks: proDefaultBuildingBlocks,
  edit: [...proDocumentStandardComponents, ...proLinksAndMenuReferences],
  addAccessRightsToAudienceTab: true,
};
