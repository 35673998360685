import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';

const VISION_TEXT: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Visietekst',
    plural: 'Visieteksten',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['VISION_TEXT'],
  },
  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION, NodeType.SECTION_NEW],
  edit: [
    titleComponent,
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    {
      component: 'image',
      label: 'Omslagfoto',
      property: 'attachments',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: false,
};

export default VISION_TEXT;
