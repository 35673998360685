import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const REFERENCE_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Verwijsgroep',
    plural: 'Verwijsgroepen',
    ribonIcon: require('../../../../img/buildingBlocks/REFERENCE_GROUP.svg'),
  },
  node: { type: ContentType.REFERENCE_GROUP },
  edit: [
    {
      component: 'referenceGroup',
      label: 'Globale verwijzingen',
      hiddenChildren: {
        containerType: ContentType.REFERENCE_GROUP,
        items: [
          {
            type: ContentType.REFERENCE,
            label: 'verwijzing',
          },
        ],
      },
    },
    {
      component: 'themes',
      label: "Thema's",
      showInAncestorTypes: [NodeType.PRONEWSLETTERTEMPLATE, NodeType.PRONEWSLETTER],
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
    },
    {
      component: 'curriculumSelector',
      label: "Leerplanthema's",
      showInAncestorTypes: [NodeType.PRONEWSLETTERTEMPLATE, NodeType.PRONEWSLETTER],
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== ContentType.CURRICULUM_THEME ||
            !theme.$$children.some((c) => c.type === ContentType.CURRICULUM_THEME)
          );
        },
      },
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'namedSet',
      label: 'Functies (doelgroep)',
      tag: 'doelgroepen',
      property: 'positions',
      whitelist: [
        { root: { $$type: NodeType.PRONEWSLETTERTEMPLATE } },
        { root: { $$type: NodeType.PRONEWSLETTER } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCollapsible: false,
  hideChildrenInDocument: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default REFERENCE_GROUP;
