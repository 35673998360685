import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const VIDEO: BuildingBlockNodeConfig = {
  information: {
    single: 'Video',
    plural: "Video's",
    ribonIcon: require('../../../../img/buildingBlocks/VIDEO.svg'),
  },
  node: {
    type: ContentType.VIDEO,
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'video',
      label: 'Video',
      property: 'attachments',
      required: RequiredType.ERROR,
      options: { type: ContentType.VIDEO },
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default VIDEO;
