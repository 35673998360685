import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { extendsSection } from '../helpers/genericConfigHelpers';
import { titleComponent } from '../helpers/genericComponents';

const SECTION_NEW: ExtendedBuildingBlockNodeConfig = {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../img/buildingBlocks/SECTION.svg'),
  },
  node: null,
  /* node: { // for now we don't want to match
    type: ContentType.SECTION,
  }, */
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.IMAGE_GROUP },
    {
      type: NodeType.MUSIC,
      hideInAncestorTypes: [NodeType.JOB_OFFER, NodeType.LLINKID_CURRICULUM],
    },
    { type: NodeType.VIDEO },
    {
      type: NodeType.VIDEO_GROUP,
      hideInAncestorTypes: [
        NodeType.WWW,
        NodeType.WWW_STATIC_PAGE,
        NodeType.PRESS,
        NodeType.JOB_OFFER,
        NodeType.WWWNEWSITEM,
        NodeType.LLINKID_CURRICULUM,
      ],
    },
    { type: NodeType.QUOTE, hideInAncestorTypes: [NodeType.LLINKID_CURRICULUM] },
    {
      type: NodeType.WORD_IMPORT,
      options: {
        openImportModal: true,
      },
    },
  ],
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
  ],
};
export default SECTION_NEW;
