import { ExtendedBuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proTileStandardComponents,
  proPageAttachmentsComponents,
} from '../proGenericConfigProps';
// Extends SECTION => A SECTION inside a Pro Theme with webConfig CURRICULUM_PAGE becomes a PRO_CURRICULUM_PAGE_TILE
export const PRO_CURRICULUM_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  information: {
    description:
      'Pro curriculum page (webTemplate code CURRICULUM_PAGE) can be only be a SECTION (a tile) in a home',
    single: 'PRO.-tegel: Leerplan pagina',
    plural: "Leerplan pagina's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  webTemplateHref: '/web/templates/93a15016-3983-4175-8804-c8f2a2d8134a' as WebTemplateHref,
  ...extendsSection,
  isCollapsible: true,
  isCollapsedByDefault: true,
  buildingBlocks: proDefaultBuildingBlocks,
  confirmDelete: true,
  edit: [...proTileStandardComponents, proPageAttachmentsComponents, ...proLinksAndMenuReferences],
};
