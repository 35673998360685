import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent, identifierComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ZILL_CLUSTER: BuildingBlockNodeConfig = {
  information: {
    single: 'Cluster',
    plural: 'Clusters',
  },
  node: {
    type: ContentType.CURRICULUM_ZILL_CLUSTER,
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD }],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      property: 'description',
      label: 'Omschrijving',
    },
  ],
  documentViewIdentifier: 'i',
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_CLUSTER;
