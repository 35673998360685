import { settings } from '../../config/settings';
import { nodeTypeConfigurations } from '../../config/nodeTypeConfigurations/index';

export const getUrlsForPath = (resourcesRaw: string[], pathname: string): any[] => {
  return resourcesRaw
    .map((rawUrl) => new URL(`${settings.apisAndUrls.api}${rawUrl}`))
    .filter((url) => url.pathname === pathname);
};

export const getAllowedContentTypesAndTags = (resourcesRaw: string[]): any => {
  const rRawContent = getUrlsForPath(resourcesRaw, '/content');

  let allowedContentTags: string[] = [];
  let allowedContentTypes: string[] = [];
  const allowedPublishedEdits: string[] = [];
  rRawContent.forEach((rawUrl) => {
    [...rawUrl.searchParams.entries()].forEach(([key, value]) => {
      if (['tagsIn', 'tags', 'rootTag'].includes(key)) {
        allowedContentTags = [...allowedContentTags, ...value.split(',')];
      } else if (['typeIn', 'type', 'rootType'].includes(key)) {
        // TODO: what if a security rule has both rootType and rootTag?
        allowedContentTypes = [...allowedContentTypes, ...value.split(',')];
      }
    });

    const rootTypeOrTag = rawUrl.searchParams.get('rootType') || rawUrl.searchParams.get('rootTag');
    const rootPublished = rawUrl.searchParams.get('rootPublished');

    if (rootTypeOrTag && rootPublished !== 'false') {
      allowedPublishedEdits.push(...rootTypeOrTag.split(','));
    }
  });
  return {
    allowedContentTags: [...new Set(allowedContentTags)],
    allowedContentTypes: [...new Set(allowedContentTypes)],
    allowedPublishedEdits: [...new Set(allowedPublishedEdits)],
    hasFullAccess: rRawContent.some((rawUrl) =>
      (rawUrl.pathname + rawUrl.search).match(/^\/content(?:\?\$\$meta.deleted=any)?$/g)
    ),
  };
};

export const getAllowedTypes = (securityData: Record<string, string[]> | null): any[] => {
  const resourcesRawForUpdate = securityData ? securityData.UPDATE_CONTENT : [];
  const { hasFullAccess, allowedContentTags, allowedContentTypes, allowedPublishedEdits } =
    getAllowedContentTypesAndTags(resourcesRawForUpdate);

  return Object.entries(nodeTypeConfigurations)
    .filter(
      ([type, typeConfig]: [string, any]) =>
        hasFullAccess ||
        (typeConfig.node?.type && allowedContentTypes.includes(typeConfig.node.type)) ||
        (typeConfig.node?.tags && allowedContentTags.includes(typeConfig.node.tags[0]))
    )
    .map(([type, typeConfig]: [string, any]) => ({
      type,
      isSearchable: typeConfig.isSearchable,
      isCreatable: typeConfig.isCreatable,
      isCloneable: typeConfig.isCloneable,
      isPublishedEditable:
        hasFullAccess ||
        (typeConfig.node?.type && allowedPublishedEdits.includes(typeConfig.node.type)) ||
        (typeConfig.node?.tags && allowedPublishedEdits.includes(typeConfig.node.tags[0])),
      category: typeConfig.information.category,
    }));
};
