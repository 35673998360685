import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import constants from '../../../reduxLoop/constants/constants';

const ATTACHMENTS_GROUP: BuildingBlockNodeConfig = {
  information: {
    single: 'Bijlagegroep',
    plural: 'Bijlagegroep',
    description:
      'Download or bijlagegroep that is a building block (as opposed to PAGE_ATTACHMENTS_GROUP) and appears inline in the document',
    conditionalTitle: (node) => (node.isDownloadAttachmentsGroup ? 'Download' : 'Bijlagegroep'),
    ribbonTitle: 'Bijlagegroep/Download',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  node: {
    type: ContentType.ATTACHMENTS_GROUP,
    tags: ['INLINE_ATTACHMENTS_GROUP'], // vs PAGE_ATTACHMENTS_GROUP -> used in redactie
    // importance: 'MEDIUM', // in Pro they filter on importance high to not show attachments INLINE so it can not be high but either LOW or MEDIUM
  },
  edit: [
    // components downloadDocument and attachmentsGroup render MULTIPLE components themselves so configuration regarding diffText generation and error messages is not working properly for this component
    {
      component: 'downloadDocument',
      label: 'Bijlagen',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      avoidDispatchDownloadTag: true,
      hiddenChildren: {
        containerType: ContentType.ATTACHMENTS_GROUP,
        items: [
          {
            type: ContentType.ATTACHMENT,
            label: 'bijlage',
          },
          {
            type: ContentType.UNSTRUCTURED_DOCUMENT,
            label: 'gedeeld document',
          },
        ],
      },
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
      },
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      hiddenChildren: {
        containerType: ContentType.ATTACHMENTS_GROUP,
        items: [
          {
            type: ContentType.ATTACHMENT,
            label: 'bijlage',
          },
          {
            type: ContentType.UNSTRUCTURED_DOCUMENT,
            label: 'gedeeld document',
          },
        ],
      },
    },
    {
      component: 'namedSet',
      label: 'Onderwijsniveaus en instellingstypes',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      label: "Regio's",
      whitelist: [{ root: { $$type: NodeType.WEBPAGE2 } }],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights',
      },
      whitelist: [
        { root: { $$type: NodeType.WEBPAGE2 } },
        { root: { $$type: NodeType.PRONEWSITEM } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'webFacets',
      label: 'Facetten',
      width: '12',
      options: { source: 'parent' },
      whitelist: [
        {
          root: {
            $$type: NodeType.WEBPAGE2,
          },
        },
      ],
    },
  ],
  addAccessRightsToAudienceTab: true,
  createInModal: true,
  isCollapsible: false,
  hideChildrenInDocument: true,
  confirmDelete: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default ATTACHMENTS_GROUP;
