import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';

/**
 * Not a real node but only a hidden child of ATTACHMENTS_GROUP nodes
 * TODO investigate if this can be removed. Why do we need it?
 */
const ATTACHMENT: HiddenContentItemConfig = {
  information: {
    single: 'Bijlage',
    plural: 'Bijlagen',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  node: {
    type: ContentType.ATTACHMENT,
  },
  // edit: [],
  hideInDocument: true,
};

export default ATTACHMENT;
