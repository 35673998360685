import * as ACTION_TYPES from '../constants/actionTypes';

export const startDraggingNodeAction = (relationKey) => ({
  type: ACTION_TYPES.START_DRAG_NODE,
  payload: {
    relationKey,
  },
});

export const stopDraggingNodeAction = () => ({
  type: ACTION_TYPES.STOP_DRAG_NODE,
});

export const finishDraggingNodeAction = () => ({
  type: ACTION_TYPES.FINISH_DRAG_NODE,
});
