import { ContentType } from '@generalTypes/apiTypes';
import { HiddenContentItemConfig } from '@nodeTypeConfig/configTypes';

const LINK_GROUP: HiddenContentItemConfig = {
  information: {
    single: 'Link groep',
    plural: 'Link groepen',
  },
  node: {
    type: ContentType.LINK_GROUP,
  },
  // edit: [], // there is no real edit, this node is hidden in the document and is managed from the aside
  hideInDocument: true,
  hideChildrenInDocument: true,
};

export default LINK_GROUP;
