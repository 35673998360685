import { Content, ContentHref } from '@generalTypes/apiTypes';
import { stripHtml } from '@newStore/genericHelpers';
import {
  EditComponent,
  EditComponentProperty,
  NodeTypeConfig,
  RequiredType,
  isEditAttachmentsComponent,
} from '@nodeTypeConfig/configTypes';
import { getContentNodeId } from '@newStore/documentApi/documentApiHelpers';
import { wrapNodeTypeLabel } from '@newStore/documentUI/transformProposal/proposalHelpers';
import {
  ValidationError,
  ValidationErrorWithNode,
  ValidationInfo,
  ValidationMapIndex,
  ValidationPending,
  ValidationResult,
  ValidationStatus,
  isValidationErrorOrWarning,
} from './validationTypes';

/**
 * @returns the value of content[property], but property can be a string with dot notation (f.e. 'applicability.themes')
 * so therefore we use reduce which recursively goes deeper into the content to get the actual value.
 */
const getPropertyValue = (content: Content, property: string) => {
  const propertyPath = property.split('.');
  return propertyPath.reduce((value, pathElem) => (value ? value[pathElem] : value), content);
};

export const isContentEmpty = (
  content: Content,
  prop: EditComponentProperty,
  component: EditComponent
): boolean => {
  if (isEditAttachmentsComponent(component)) {
    return (
      !content.attachments || !content.attachments.some((a) => a.type === component.options.type)
    );
  }
  const value = getPropertyValue(content, prop);
  if (value === null || value === undefined) {
    return true;
  }
  if (Array.isArray(value) && !value.length) {
    return true;
  }
  if (typeof value === 'string' && stripHtml(value).trim().length === 0) {
    return true;
  }
  return false;
};

export const isContentTooLong = (content: Content, prop: string, maxLength: number): boolean => {
  return content[prop] && stripHtml(content[prop]).trim().length > maxLength;
};

/* We want just the first letter in lowercase unless the second letter is uppercase (eg: WWW) */
export const getNodeTypeLabel = (name: string) => {
  const nodeTypeLabel =
    name[1] === name[1].toLocaleUpperCase()
      ? name
      : name[0].toLocaleLowerCase() + name.substring(1);
  return wrapNodeTypeLabel`${nodeTypeLabel}`;
};

export const getNodeTypeLabelFromConfig = (nodeTypeConfig: NodeTypeConfig) =>
  getNodeTypeLabel(nodeTypeConfig.information.single);

export const createError = (
  code: string,
  rule: string,
  message: string,
  property?: string,
  type: RequiredType = RequiredType.ERROR
): ValidationError => {
  return {
    code,
    type,
    message,
    property,
    rule,
  };
};

export const getValidationStatus = (
  validationResults: Array<ValidationResult>,
  ignoreUnknown: boolean
): ValidationStatus => {
  if (!validationResults.length) {
    return 'VALID';
  }
  return validationResults.reduce((status: ValidationStatus, res) => {
    if (isValidationErrorOrWarning(res) && res.type === 'ERROR') {
      return 'INVALID';
    }
    if (!ignoreUnknown && res === null && status !== 'INVALID') {
      return 'UNKNOWN';
    }
    if (
      status !== 'INVALID' &&
      status !== 'UNKNOWN' &&
      isValidationErrorOrWarning(res) &&
      res.type === 'WARNING'
    ) {
      return 'VALID_WITH_WARNINGS';
    }
    return status;
  }, 'VALID' as ValidationStatus);
};

export const getHighestValidationStatus = (statuses: Array<ValidationInfo>): ValidationStatus => {
  if (statuses.some((status) => status.status === 'INVALID')) {
    return 'INVALID';
  }
  if (statuses.some((status) => status.status === 'UNKNOWN')) {
    return 'UNKNOWN';
  }
  if (statuses.some((status) => status.status === 'VALID_WITH_WARNINGS')) {
    return 'VALID_WITH_WARNINGS';
  }
  return 'VALID';
};

export const childError = createError(
  'childError',
  'childError', // these are not really real errors...
  'Er zijn foutmeldingen voor de <strong>onderliggende inhoud</strong>.'
);
export const childWarning = createError(
  'childWarning',
  'childWarning', // these are not really real errors...
  'Er zijn waarschuwingen voor de <strong>onderliggende inhoud</strong>.',
  undefined,
  RequiredType.WARNING
);

export const createChildError = (
  parentHref: ContentHref | undefined,
  childHref: ContentHref
): ValidationErrorWithNode => {
  const error: ValidationErrorWithNode = {
    ...createError(
      'childError',
      'childError',
      'Er zijn foutmeldingen voor de <strong>onderliggende inhoud</strong>.',
      undefined,
      RequiredType.ERROR
    ),
    node: {
      href: childHref,
      parentHref,
      nodeId: getContentNodeId({ childHref, parentHref }),
    },
  };
  return error;
};

export const createChildWarning = (
  parentHref: ContentHref | undefined,
  childHref: ContentHref
): ValidationErrorWithNode => {
  const warning: ValidationErrorWithNode = {
    ...createError(
      'childWarning',
      'childWarning',
      'Er zijn waarschuwingen voor de <strong>onderliggende inhoud</strong>',
      undefined,
      RequiredType.WARNING
    ),
    node: {
      href: childHref,
      parentHref,
      nodeId: getContentNodeId({ childHref, parentHref }),
    },
  };
  return warning;
};

export const VALIDATION_PENDING: ValidationPending = { pending: true };

// export const getValidationInfo = (
//   validationResultsForNode: Array<ValidationResult>,
//   valResultsForDescendants: Array<ValidationResult>
// ): ValidationInfo => {
//   const nodeValidationErrors = validationResultsForNode.filter(isValidationErrorOrWarning);
//   const descValidationErrors = valResultsForDescendants.filter(isValidationErrorOrWarning);
//   if (descValidationErrors.some((e) => e.type === 'ERROR')) {
//     nodeValidationErrors.push(childError);
//   }
//   if (descValidationErrors.some((e) => e.type === 'WARNING')) {
//     nodeValidationErrors.push(childWarning);
//   }

//   return {
//     status: getValidationStatus([...validationResultsForNode, ...valResultsForDescendants], true),
//     validationErrors: nodeValidationErrors,
//   };
// };

export const getParentChildIndex = ({
  parentHref,
  childHref,
}: {
  parentHref: string | undefined;
  childHref: string;
}): ValidationMapIndex => {
  return `${parentHref}-${childHref}` as ValidationMapIndex;
};
