import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proDocumentStandardComponents,
  proLinksAndMenuReferences,
  proDefaultBuildingBlocks,
  proTileStandardComponents,
  proThemeAudienceTab,
  proThemeTocTypes,
} from '../proGenericConfigProps';

const proTempPage = {
  information: {
    description:
      'Pro Temporary Page (webTemplate code TEMPORARY_PAGE) is most of the time a root web page (in 19 cases on prod a SECTION in a HOME). These are pages the business did not convert yet to a real structured document and the content for this page is mainly in a PDF',
    single: 'PRO.-tegel: TEMP-pagina',
    plural: "TEMP-pagina's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  webTemplateHref: '/web/templates/263342e7-58a0-42d7-94fa-8f353b46e772' as WebTemplateHref,
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig TEMPORARY_PAGE becomes a PRO_TEMPORARY_PAGE_TILE
export const PRO_TEMPORARY_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proTempPage,
  node: {
    type: ContentType.SECTION,
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents.filter((comp) => comp.component !== 'subject'),
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding TEMPORARY_PAGE WebConfig to a WEBPAGE2 it becomes a PRO_TEMPORARY_PAGE_STANDALONE
export const PRO_TEMPORARY_PAGE_STANDALONE: ExtendedRootNodeConfig = {
  ...proTempPage,
  information: {
    ...proTempPage.information,
    definiteArticle: true,
    single: 'PRO.-thema: TEMP-pagina',
  },
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [...proDocumentStandardComponents, ...proLinksAndMenuReferences],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
