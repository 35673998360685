import { RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const WEBPAGE: RootNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'Zill-Web Pagina',
    plural: "Zill-Web Pagina's",
    icon: require('../../../../img/icons/webpage.svg'),
  },
  node: {
    type: ContentType.WEBPAGE,
    tags: ['WEBPAGE'],
  },
  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION, NodeType.SECTION_NEW],
  edit: [
    titleComponent,
    { component: 'description', property: 'description', label: 'Omschrijving' },
    publishComponent,
    authorsComponent,
    { component: 'themes', label: "Thema's" },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
};

export default WEBPAGE;
