import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChanges,
  selectWebPagePerContentMap,
} from '@newStore/documentApi/documentApiSelectors';
import { selectAllNodeTypesMap } from '@newStore/documentUI/nodeTypeConfigSelectors';
import nodeTypeSelectorsMap from '@nodeTypeConfig/nodeTypeSelectorsMap';
import { createTypedSelector } from '@newStore/genericHelpers';
import { conditionalLogTime } from '@store/helpers/generalUtils';
import { ContentHref } from '@generalTypes/apiTypes';
import { noDuplicateWebPaths } from './validationRules/noDuplicateWebPaths';
import { AsyncValidationRule, isAsyncValidationRule } from './validationTypes';

/**
 * TODO: we could optimize here, because we don't even need the content, we just want all the hrefs to iterate over.
 */
export const selectAsyncValidationsPerContentNode = createTypedSelector(
  [
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectWebPagePerContentMap(state),
    (state) => selectAllNodeTypesMap(state),
  ],
  (content, webPageMap, nodeTypeMap): Record<ContentHref, AsyncValidationRule[]> => {
    const validations = Object.keys(content).reduce((acc, href) => {
      const nodeType = nodeTypeMap[href as ContentHref];
      if (!nodeType) {
        return acc;
      }

      const validationRules =
        nodeTypeSelectorsMap[nodeType].validationRules?.map((r) => r.rule) || [];
      const asyncValidationRules = validationRules.filter(isAsyncValidationRule);
      if (webPageMap[href]) {
        asyncValidationRules.push(noDuplicateWebPaths);
      }

      if (asyncValidationRules.length) {
        acc[href] = asyncValidationRules;
      }

      return acc;
    }, {});

    return validations;
  }
);

export const selectIsValidationFinishedLoading = (state: RootState) => {
  return Object.values(state.validationData).every((node) =>
    Object.values(node).every((validation) => validation.isLoading === false)
  );
};

export const selectFlatValidationRules = createTypedSelector(
  [(state) => selectAsyncValidationsPerContentNode(state)],
  (validationsPerNode) => {
    const validations = [] as Array<{
      href: ContentHref;
      validation: AsyncValidationRule;
    }>;

    for (const [href, validationRules] of Object.entries(validationsPerNode)) {
      for (const validation of validationRules as AsyncValidationRule[]) {
        validations.push({
          href,
          validation,
        } as { href: ContentHref; validation: AsyncValidationRule });
      }
    }
    return validations;
  }
);

export type SelectFlatValidationRulesReturnType = ReturnType<typeof selectFlatValidationRules>;

export const selectValidationRulesThatNeedData = (state: RootState) => {
  const endLog = conditionalLogTime('selectValidationRulesThatNeedData', 5);
  const validationsToDo = selectFlatValidationRules(state);

  const validationsThatNeedData = validationsToDo.filter((validation) =>
    validation.validation.selectNeedsData(state, validation.href)
  );
  endLog();
  return validationsThatNeedData;
};

/* export const selectContentNodeValidationErrors = createTypedSelector(
  [
    (state: RootState, href: string) => selectAsyncValidationsPerContentNode(state)[href],
    (state: RootState) => state,
    (state: RootState, href: string) => href,
  ],
  (validationsForNode: Record<string, AsyncValidationRule[]>, state, href) => {
    if (!validationsForNode) {
      return [];
    }

    const errors: {
      error: ValidationError;
      component: string;
    }[] = [];

    Object.keys(validationsForNode).forEach((componentType) => {
      const rules = validationsForNode[componentType];
      const componentErrors = rules
        .map((rule) => rule.selectValidation(state, href))
        .filter((z): z is ValidationError => z !== true && z !== null)
        .map((z) => ({ error: z, component: componentType }));
      errors.push(...componentErrors);
    });

    return errors;
  },
  {
    memoizeOptions: {
      // this is quite ugly, but i don't see a better way yet with the old reselect.
      // we need the whole state to run the validation, so this runs on any state change.
      // for now we just check if the result is equal to the previous result, and keep a max size of 100, so we can store a few validations per node
      // this is to avoid rerenders, which is also not relevant yet.

      /**
       * if this selector were to only return booleans, then we don't need to create a cached selector for this.
       * the problem is, if something is not valid, we return an object, which is not equal to the previous object.
       */
// resultEqualityCheck: isEqual,
/*     },
  }
); */
