import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proDefaultBuildingBlocks, proContactsComponent } from '../proGenericConfigProps';
import {
  identifierComponent,
  titleComponent,
  importanceComponent,
  authorsComponent,
} from '../../helpers/genericComponents';
// Extends SECTION => A SECTION without webConfig inside a Pro Theme document or a SHARED_MINI_DATABASE_ITEM item document becomes a PRO_THEME_SECTION
// A Pro Theme is one of the 3 PRO_THEME_HOME_... document types, one of the 5 PRO_..._STANDALONE document types or just WEBPAGE2
const PRO_THEME_SECTION: ExtendedBuildingBlockNodeConfig = {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  node: {
    type: ContentType.SECTION,
  },
  documentTypes: [
    NodeType.WEBPAGE2,
    NodeType.PRO_THEME_HOME_FULL,
    NodeType.PRO_THEME_HOME_PICTURE,
    NodeType.PRO_THEME_HOME_TEXT,
    NodeType.PRO_TEXT_PAGE_STANDALONE,
    NodeType.PRO_BLOG_STANDALONE,
    NodeType.PRO_DATABASE_STANDALONE,
    NodeType.PRO_DOWNLOAD_PAGE_STANDALONE,
    NodeType.PRO_TEMPORARY_PAGE_STANDALONE,
    NodeType.PRO_STATIC_PAGE,
    NodeType.SHARED_MINI_DATABASE_ITEM,
  ],
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [
    ...proDefaultBuildingBlocks.filter((bb) => bb.type !== NodeType.WORD_IMPORT),
    {
      type: NodeType.WORD_IMPORT,
      options: {
        openImportModal: true,
      },
    },
  ],
  edit: [
    {
      ...identifierComponent, // can go if we work on Aside (confirmed by Isabelle)
      width: '2',
      view: {
        hideIfEmpty: true,
        dd: 'te',
      },
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      ...titleComponent,
      width: '10',
    },
    {
      ...importanceComponent,
      width: '6',
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      ...authorsComponent,
      width: '6',
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      ...proContactsComponent,
      width: '12',
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      component: 'linkGroup',
      label: 'Links',
      width: '12',
      options: {
        type: 'group',
      },
    },
    {
      component: 'menuItemReference',
      label: 'Navigeert naar',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [NodeType.WEBPAGE2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
    },
  ],
};

export default PRO_THEME_SECTION;
