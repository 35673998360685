import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent, identifierComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { ContentType } from '@generalTypes/apiTypes';

const CURRICULUM_ZILL_GENERIC_GOAL: BuildingBlockNodeConfig = {
  information: {
    single: 'Doel',
    plural: 'Doelen',
  },
  node: {
    type: ContentType.CURRICULUM_ZILL_GENERIC_GOAL,
  },
  buildingBlocks: [
    {
      type: NodeType.CURRICULUM_ZILL_CONTENT_GROUP,
      max: 1,
    },
    {
      type: NodeType.CURRICULUM_ZILL_LEERLIJN,
      max: 1,
    },
    {
      type: NodeType.CURRICULUM_ZILL_LEERLIJN_AN,
      max: 1,
    },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'concordanties',
      label: 'Concordanties',
    },
  ],
  documentViewIdentifier: 'pi',
  isCollapsible: true,
  isCollapsedByDefault: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_GENERIC_GOAL;
