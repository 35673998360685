/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-async-promise-executor */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-useless-catch */
import pMap from 'p-map';
import uuidv4 from 'uuid/v4';
import { contentApi, proposalApi, newsletterApi, websitesApi } from '../api/apiConfig';
import { documentAsDocx, createNewsLetter } from '../../services/import';
import {
  deleteChildrenRecursiveFrom,
  getResourceKey,
  getUniquePath,
} from '../helpers/documentHelpers';
import {
  sendBatchCmd,
  putAttachmentsCmd,
  loadDocumentCmd,
  fetchTreeAsLeafCmd,
  loadHrefsCmd,
  fetchNewsletterSettingsCmd,
} from './documentCommands';
import { settings } from '../../config/settings';
import { getOldNodeType, getNodeTypeConfig } from '../../config/nodeTypeConfigurations';
import {
  getDocumentWebpagesCmd,
  sendWebsitesBatchCmd,
  getWebpagesWithFacetContainingReferenceFrame,
} from './websitesCommands';
import { documentTags } from '../constants/documentTags';
import * as apiRoutes from '../api/apiRoutes';

export const loadProposalsToReviewCmd = async (hrefs) => {
  try {
    const hasHrefs = hrefs && hrefs.length > 0;

    if (!hasHrefs) {
      return [];
    }

    const batch = hrefs.map((href) => {
      return {
        href: `/proposals?externalReferencesContains=${href}&status=SUBMITTED_FOR_REVIEW&orderBy=$$meta.modified&descending=true&limit=0`,
        verb: 'GET',
      };
    });

    const batchResult = await proposalApi.put('/proposals/batch', batch);
    return batchResult.map((result) => {
      const contentHref = hrefs.find((href) => result.href.indexOf(href) !== -1);
      return {
        contentHref,
        count: result.body.$$meta.count,
      };
    });
  } catch (e) {
    throw e;
  }
};

const getSearchResultAuthors = (expandedResult, authorsResponse) => {
  const resultAuthors = expandedResult.creators;
  const hasResultAuthors = resultAuthors && resultAuthors.length > 0;
  if (hasResultAuthors) {
    // expand authors from the `getAuthors` response
    const allAuthors = authorsResponse.results;
    const authorsPresentOnItem = allAuthors.filter((author) =>
      resultAuthors.includes(author.$$meta.permalink)
    );
    const authorsPresentOnItemNames = authorsPresentOnItem.map((author) => {
      // We have authors of different types. So, depending on the type, we
      // will get the information from one place or another:
      // - authorItem.$$meta.type === 'ORGANISATIONAL_UNIT' => We get it in `$$displayName`
      // - author.$$meta.type === 'ORGANISATION' => We get it in `$$name`
      // - authorItem.$$meta.type === 'PERSON' => We get it in `firstname` + `lastname`
      return author.$$displayName || author.$$name || `${author.lastName} ${author.firstName}`;
    });

    return authorsPresentOnItemNames.join(', ');
  }
  return '';
};

export const getAuthorsCmd = async (resultsToExpand) => {
  // This method receives a list of search results, fetches the
  // authors of those and attach them to the same results. Then
  // it returns those results

  // get the authors hrefs from the received search-results
  const creatorsHrefs = resultsToExpand
    .filter((x) => x.creators)
    .map((x) => x.creators)
    .flat();

  const authorsResponse = await loadHrefsCmd(creatorsHrefs);

  return resultsToExpand.map((result) => {
    const searchResultAuthors = getSearchResultAuthors(result, authorsResponse);
    return { ...result, expandedAuthors: searchResultAuthors };
  });
};

export const getProposalsCmd = async (resultsToExpand) => {
  const hrefsToLoadProposals = resultsToExpand
    .filter((r) => {
      const documentType = getOldNodeType(r);
      const typeConfig = getNodeTypeConfig(documentType);
      return typeConfig.allowSuggestions;
    })
    .map((r) => r.$$meta.permalink);

  const proposalsToReview = await loadProposalsToReviewCmd(hrefsToLoadProposals);
  return resultsToExpand.map((searchResult) => {
    const itemProposals = proposalsToReview.find(
      (p) => p.contentHref === searchResult.$$meta.permalink
    );
    const hasProposalsToReview = !!(itemProposals && itemProposals.count > 0);
    return { ...searchResult, hasProposalsToReview };
  });
};

export const searchCmd = async (queryStr) => {
  try {
    return await contentApi.getList(`/content?${queryStr}`, { limit: 50 });
  } catch (e) {
    throw e;
  }
};

export const loadMoreCmd = (href) => {
  try {
    return contentApi.getList(href);
  } catch (e) {
    throw e;
  }
};

export const publishCmd = async (batch) => {
  try {
    await contentApi.put('/content/batch', batch);
    return batch.map((b) => b.body);
  } catch (e) {
    throw e;
  }
};

export const publishProposalCmd = async (batch) => {
  try {
    await proposalApi.put('/proposals/batch', batch);
    return batch.map((b) => b.body);
  } catch (e) {
    throw e;
  }
};

export const sendNewsletterSettingsBatchCmd = async (batch) => {
  try {
    return newsletterApi.put('/newsletter/batch', batch);
  } catch (e) {
    throw e;
  }
};

export const deleteDocumentsCmd = async (deleteKeys) => {
  try {
    const batch = [];
    let webpagesBatch = [];
    let newsletterSettingsBatch = [];

    // TODO remove configurations (for pro website)

    await pMap(
      deleteKeys,
      async (key) => {
        const children = await loadDocumentCmd(key);
        deleteChildrenRecursiveFrom(
          children.find((c) => c.key === key),
          children,
          batch
        );

        batch.push({
          verb: 'DELETE',
          href: `/content/${key}`,
        });

        const webpages = await getDocumentWebpagesCmd(key);
        webpagesBatch = [
          ...webpagesBatch,
          ...webpages.map((wp) => ({
            verb: 'DELETE',
            href: wp.$$meta.permalink,
          })),
        ];

        const newsletterSettings = await fetchNewsletterSettingsCmd(key);
        newsletterSettingsBatch = [
          ...newsletterSettingsBatch,
          ...newsletterSettings.map((n) => ({
            verb: 'DELETE',
            href: n.$$meta.permalink,
          })),
        ];
      },
      { concurrency: 5 }
    );
    // console.log('BATCH to delete:',batch)
    await sendBatchCmd(batch);
    if (webpagesBatch.length > 0) {
      await sendWebsitesBatchCmd(webpagesBatch);
    }
    if (newsletterSettingsBatch.length > 0) {
      await sendNewsletterSettingsBatchCmd(newsletterSettingsBatch);
    }
    return true;
  } catch (e) {
    throw e;
  }
};

const getUploadJson = (contentHref, name, type) => {
  const key = uuidv4();
  const body = {
    file: name,
    attachment: {
      key,
      type,
      name,
    },
    resource: {
      href: contentHref,
    },
  };

  return body;
};

export const validateCmd = async (validationCmds) => {
  try {
    const settled = await Promise.allSettled(
      validationCmds.map((validation) => validation.cmd(...validation.params))
    );
    return new Promise(async (resolve, reject) => {
      const rejected = settled.filter((s) => s.status === 'rejected');
      if (rejected.length > 0) {
        reject(rejected.length === 1 ? rejected[0].reason : rejected.map((r) => r.reason));
      } else {
        resolve();
      }
    });
  } catch (e) {
    throw e;
  }
};

export const createNewsLetterTemplateValidationCmd = async (title, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const results = await contentApi.getList(
        `/content?tagsIn=PRONEWSLETTERTEMPLATE&title=${title}`,
        { limit: 50 }
      );
      if (results.length > 0) {
        reject({
          code: 'list.error.uniqueNewsletterTemplateTitle',
          params: {
            title,
          },
          action,
        });
      } else {
        resolve();
      }
    } catch (e) {
      throw reject(e);
    }
  });
};

export const teasersDeleteValidationCmd = async (key) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contents = await loadDocumentCmd(key);
      const teaser = contents.find((c) => c.type === 'TEASER');

      const includes = teaser.$$relationsFrom.filter(
        (r) => r.$$expanded.relationtype === 'IS_INCLUDED_IN'
      );
      if (includes.length > 0) {
        return reject({
          code: 'list.error.deleteTeaserWithNewsletter',
          params: {
            teaser: teaser.title,
          },
        });
      }

      const reference = contents.find((c) => c.type === 'REFERENCE');
      if (!reference) {
        return resolve();
      }

      const relation = reference.$$relationsFrom.find(
        (r) => r.$$expanded.relationtype === 'REFERENCES'
      );
      if (!relation || !relation.$$expanded.to.href.startsWith('/content')) {
        return resolve();
      }

      const expandedRelation = relation.$$expanded;
      const expandedRelationTo = expandedRelation && expandedRelation.to;
      const expandedRelationToKey =
        expandedRelationTo && expandedRelationTo.href.replace(/^\/content\//, '');

      const resources = await loadDocumentCmd(expandedRelationToKey);
      const resource = resources.find(
        (r) => r.type === 'STRUCTURED_DOCUMENT' && r.tags.includes(documentTags.proNewsItem)
      );
      if (resource === undefined) {
        return resolve();
      }

      return reject({
        code: 'list.error.deleteTeaserWithNewsItem',
        params: {
          teaser: teaser.title,
          newsItem: resource.title,
        },
      });
    } catch (e) {
      throw reject(e);
    }
  });
};

export const newsItemDeleteValidationCmd = async (key) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contents = await loadDocumentCmd(key);
      const newsItem = contents.find((c) => c.type === 'STRUCTURED_DOCUMENT');
      if (newsItem.$$relationsTo.length === 0) {
        return resolve();
      }
      const relations = newsItem.$$relationsTo.filter(
        (r) => r.$$expanded.relationtype === 'REFERENCES'
      );
      if (relations.length === 0) {
        return resolve();
      }
      const resources = await fetchTreeAsLeafCmd(
        relations.map((r) => {
          return {
            key: getResourceKey(r.$$expanded.from.href),
          };
        })
      );
      const teasers = resources
        .map((r) => r.$$treeAsLeaf)
        .flat()
        .filter((r) => r.type === 'TEASER');
      return reject({
        code: 'list.error.deleteNewsItemWithTeaser',
        params: {
          newsItem: newsItem.title,
          teasers: teasers.map((t) => t.title).join(', '),
        },
      });
    } catch (e) {
      throw reject(e);
    }
  });
};

export const referencesRootsCmd = async (referencesKeys) => {
  const leafs = await fetchTreeAsLeafCmd([...referencesKeys].map((k) => ({ key: k })));

  const referencedNodeRoots = new Set(
    leafs.map((leaf) => {
      const tree = leaf.$$treeAsLeaf;
      const rootTitle = tree[0].title;
      const referenceNodeTitle = tree[tree.length - 3]
        ? tree[tree.length - 3].title
        : tree[tree.length - 2]
        ? tree[tree.length - 2].title
        : tree[tree.length - 1].title;
      return `. ${rootTitle}${rootTitle !== referenceNodeTitle ? ` - ${referenceNodeTitle}` : ''}`;
    })
  );

  const referencesRootNodes = Array.from(
    new Set(
      leafs.map((leaf) => {
        const tree = leaf.$$treeAsLeaf;
        return tree[0];
      })
    )
  );

  return new Promise(async (resolve, reject) => {
    try {
      if (referencesKeys.length > 0) {
        resolve({
          code: 'edit.referenceFrame.error.deleteThemeWithReferences',
          params: {
            references: `<br>${[...referencedNodeRoots].join('<br>')}`,
            referencesRootNodes,
            leafs,
          },
        });
      } else {
        resolve();
      }
    } catch (e) {
      throw reject(e);
    }
  });
};

export const referenceFrameDeleteValidationCmd = async (key) => {
  const referenceRelationTypes = ['REQUIRES'];

  return new Promise(async (resolve, reject) => {
    try {
      const contents = await loadDocumentCmd(key);
      const referencesFromNodesKeys = new Set(
        contents.reduce((list, c) => {
          const requiresRelations = c.$$relationsFrom.filter((rel) =>
            referenceRelationTypes.includes(rel.$$expanded.relationtype)
          );
          if (c.type === 'THEME' && requiresRelations.length > 0) {
            list = [
              ...list,
              ...requiresRelations.map((rel) => getResourceKey(rel.$$expanded.to.href)),
            ];
          }
          return list;
        }, [])
      );

      const configurationsWithReferenceFrame = await getWebpagesWithFacetContainingReferenceFrame(
        key
      );

      if (referencesFromNodesKeys.size > 0) {
        const referencedNodes = await referencesRootsCmd([...referencesFromNodesKeys]);

        reject({
          ...referencedNodes,
          code: 'list.error.deleteReferenceFrameWithReferences',
        });
      } else if (configurationsWithReferenceFrame.length) {
        const result = await referencesRootsCmd(
          configurationsWithReferenceFrame.map((c) => getResourceKey(c.source.href))
        );
        // build references param joining root+leaf titles
        const references = result.params.leafs
          .map((l) => {
            const leaf = l.$$treeAsLeaf.find((n) => n.key === l.key);
            const root = l.$$treeAsLeaf.find((n) => n.type === 'STRUCTURED_DOCUMENT');
            return `${root.title} > ${leaf.title}`;
          })
          .join('<br>');

        reject({
          params: {
            references,
          },
          code: 'list.error.deleteReferenceFrameWithFacetReferences',
        });
      } else {
        resolve();
      }
    } catch (e) {
      throw reject(e);
    }
  });
};

export const globalDocumentDeleteValidationCmd = async (key) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contents = await loadDocumentCmd(key);
      const includedInContentKeys = contents[0].$$relationsFrom
        .filter((relation) => relation.$$expanded.relationtype === 'IS_INCLUDED_IN')
        .map((relation) => getResourceKey(relation.$$expanded.to.href));

      if (includedInContentKeys.length > 0) {
        const referencedNodes = await referencesRootsCmd(includedInContentKeys);

        reject({
          code: 'list.error.deleteGlobalDocumentWithReferences',
          params: {
            // includedIn: referencedNodes.params.references
            includedIn: referencedNodes.params.referencesRootNodes
              .map(
                (refRoot) => `<a href="/edit/${refRoot.key}" target="_blank">${refRoot.title}</a>`
              )
              .join('<br>'),
          },
        });
      } else {
        resolve();
      }
    } catch (e) {
      throw reject(e);
    }
  });
};

const ensureUniqueWebpagePaths = async (webpagesBatch) => {
  const pathContainsRequest = `${apiRoutes.webpages}?limit=6000&pathContains=`;
  const existingWebpagesBatch = webpagesBatch.map((wp) => ({
    verb: 'GET',
    href: pathContainsRequest + wp.body.path,
  }));
  const existingWebpagesWithRelatedPath = await websitesApi.put(
    `${apiRoutes.webpages}/batch`,
    existingWebpagesBatch
  );

  return webpagesBatch.map((wp) => {
    const pathsRelatedToWebpage = existingWebpagesWithRelatedPath
      .find((r) => r.href === pathContainsRequest + wp.body.path)
      .body.results.map((r) => r.$$expanded.path);

    wp.body.path = getUniquePath(wp.body, pathsRelatedToWebpage);
    return wp;
  });
};

export const createContentCmd = async (batch, webpagesBatch) => {
  try {
    return new Promise(async (resolve, reject) => {
      try {
        const rootDocument = batch[0].body;
        await sendBatchCmd(batch);
        if (webpagesBatch && webpagesBatch.length > 0) {
          webpagesBatch = await ensureUniqueWebpagePaths(webpagesBatch);
          await sendWebsitesBatchCmd(webpagesBatch);
        }
        resolve(rootDocument);
      } catch (e) {
        reject(e);
      }
    });
  } catch (e) {
    throw e;
  }
};

const uploadAttachment = async (href, name, type, arrayBuffer) => {
  const dataForm = new FormData();
  const body = getUploadJson(href, name, type);
  dataForm.append('body', JSON.stringify(body));
  dataForm.append('data', new Blob([arrayBuffer]), name);
  const response = await fetch(`${settings.apisAndUrls.contentApi}/content/attachments`, {
    method: 'POST',
    body: dataForm,
  });

  try {
    await response.json(); // await the upload till the end...
  } catch (e) {
    console.error('Uploading the file failed!');
  }

  if (!response.ok) {
    console.error('Uploading the file failed!');
    throw new Error('Uploading the file failed');
  }
};

export const createContentWithThumbnailCmd = async (batch, webpagesBatch, attachment) => {
  try {
    return new Promise(async (resolve, reject) => {
      try {
        const rootDocument = batch[0].body;
        await sendBatchCmd(batch);
        if (webpagesBatch && webpagesBatch.length > 0) {
          webpagesBatch = await ensureUniqueWebpagePaths(webpagesBatch);
          await sendWebsitesBatchCmd(webpagesBatch);
        }
        const attachmentFetch = await fetch(settings.apisAndUrls.contentApi + attachment.href);
        const attachmentAsBlob = await attachmentFetch.blob();
        await uploadAttachment(batch[0].href, attachment.name, 'THUMBNAIL', attachmentAsBlob);

        resolve(rootDocument);
      } catch (e) {
        reject(e);
      }
    });
  } catch (e) {
    throw e;
  }
};

export const createContentWithAttachmentCmd = async (batch, attachment) => {
  try {
    return new Promise(async (resolve, reject) => {
      try {
        const rootDocument = batch[0].body;
        await sendBatchCmd(batch);
        await uploadAttachment(batch[0].href, attachment.name, 'CONTENT', attachment.arrayBuffer);
        resolve(rootDocument);
      } catch (e) {
        reject(e);
      }
    });
  } catch (e) {
    throw e;
  }
};

export const createContentWithDocxCmd = async (batch, importDocx, webpagesBatch) => {
  try {
    return new Promise(async (resolve, reject) => {
      try {
        const rootDocument = batch[0].body;
        const result = await documentAsDocx(importDocx.arrayBuffer, structuredClone(batch));
        await sendBatchCmd(result.batch);
        await Promise.all(
          result.attachmentsToUpload.map((a) => putAttachmentsCmd(`/content/${a.resourceKey}`, a))
        );
        if (webpagesBatch && webpagesBatch.length > 0) {
          webpagesBatch = await ensureUniqueWebpagePaths(webpagesBatch);
          await sendWebsitesBatchCmd(webpagesBatch);
        }
        resolve(rootDocument);
      } catch (e) {
        reject(e);
      }
    });
  } catch (e) {
    throw e;
  }
};

const createNewsletterSettings = async (newsletter) => {
  try {
    const key = uuidv4();
    return newsletterApi.put(`/newsletter/settings/${key}`, {
      key,
      newsletter: { href: newsletter.href },
      dateToSend: newsletter.dateToSend,
      approvalDate: new Date().toISOString(),
    });
  } catch (e) {
    throw e;
  }
};

export const createNewsLetterCmd = async (batch, newsletter) => {
  try {
    return new Promise(async (resolve, reject) => {
      try {
        const rootDocument = batch[0].body;
        const template = await loadDocumentCmd(newsletter.templateKey);
        const result = await createNewsLetter(batch, rootDocument.$$meta.permalink, template);
        await sendBatchCmd(result.batch);
        await createNewsletterSettings(newsletter);
        await Promise.all(
          result.attachmentsToUpload.map((a) => putAttachmentsCmd(`/content/${a.resourceKey}`, a))
        );
        resolve(rootDocument);
      } catch (e) {
        reject(e);
      }
    });
  } catch (e) {
    throw e;
  }
};
